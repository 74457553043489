import { type AnchorHTMLAttributes, type ReactNode } from 'react'
import { Link, type To } from 'react-router-dom'
import styled from 'styled-components'

import { type MixKuiTextProps, mixKuiText } from './KuiText'

const KuiLinkRoot = styled.a<{ $textProps: MixKuiTextProps }>`
  all: unset;
  white-space: nowrap;

  text-decoration: underline;

  cursor: pointer;

  ${(p) => p.$textProps.truncate && 'display: block;'}

  ${(p) => mixKuiText(p.$textProps)}
`

export type KuiLinkProps = Pick<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'href' | 'onClick'
> &
  MixKuiTextProps &
  MaybeLinkProps & {
    children?: ReactNode
  }

export function KuiLink({
  to,
  state,
  onClick,
  children,
  ...restProps
}: KuiLinkProps) {
  const { color, size, truncate, weight, wrap, textAlign, ...rest } = restProps

  return (
    <KuiLinkRoot
      {...rest}
      {...getAsProps()}
      $textProps={{
        color,
        size,
        truncate,
        weight,
        wrap,
        textAlign,
      }}
      onClick={onClick}
    >
      {children}
    </KuiLinkRoot>
  )

  function getAsProps(): any {
    if (to) {
      return { as: Link, to, state }
    }

    if (restProps.href) {
      return {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    }

    return {
      as: 'button',
      type: 'button',
    }
  }
}

export type MaybeLinkProps = { to?: To; state?: object; href?: string }

export function getAsLinkMantineProps({
  to,
  state,
  href,
}: MaybeLinkProps): any {
  if (to) {
    return {
      component: Link,
      to,
      state,
    }
  }

  if (href) {
    return {
      component: 'a',
      href,
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  }
}
