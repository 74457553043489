import { Switch } from '@mantine/core'
import { Fragment, useState } from 'react'
import {
  type FieldValues,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form'

import {
  type RemoteObjectDumpSchema,
  type RemoteObjectTypeEnum,
  remoteObjectCreate,
} from '_autogenerated'
import { useRemoteObjectSelectProps } from 'apis/accounting'
import {
  KuiFlex,
  KuiForm,
  KuiFormSelectInput,
  KuiFormTextInput,
  KuiModal,
  KuiStack,
  KuiTooltip,
  wrapKuiApiRequest,
} from 'components/kui'
import { type RemoteObjectFormValues } from 'forms/RemoteObjectForm/types'
import { renderEnumValue } from 'utils/enums'

import { type RemoteObjectInputPropsKui } from './types'

export function RemoteObjectInputKui<TFieldValues extends FieldValues = never>({
  name,
  type,
  tooltipContent,
  ...props
}: RemoteObjectInputPropsKui<TFieldValues>) {
  const { control: outerFormControl } = useFormContext()

  const {
    field: { onChange },
  } = useController({
    control: outerFormControl,
    name,
  })

  const [opened, setOpened] = useState(false)

  async function afterSubmit(object: RemoteObjectDumpSchema) {
    onChange(object)
    close()
  }

  const remoteObjectSelectProps = useRemoteObjectSelectProps({ type })

  return (
    <Fragment>
      <CreateRemoteObjectModal
        opened={opened}
        type={type}
        onClose={() => setOpened(false)}
        afterSubmit={afterSubmit}
      />
      <KuiTooltip content={tooltipContent} fullWidth={true}>
        <KuiFlex.Item grow={1}>
          <KuiFormSelectInput<
            { remote_object: RemoteObjectDumpSchema },
            'remote_object'
          >
            {...remoteObjectSelectProps}
            name={name as any}
            parseItem={(remoteObject) => ({
              key: remoteObject.id,
              label: remoteObject.name,
            })}
            footerActions={[
              {
                key: 'create',
                iconType: 'plus',
                label: `Create new ${renderEnumValue(type)}`,
                onClick: () => setOpened(true),
              },
            ]}
            {...props}
          />
        </KuiFlex.Item>
      </KuiTooltip>
    </Fragment>
  )
}

export function CreateRemoteObjectModal({
  opened,
  type,
  onClose,
  afterSubmit,
}: {
  opened: boolean
  type: RemoteObjectTypeEnum
  onClose: () => void
  afterSubmit: (res: RemoteObjectDumpSchema) => void
}) {
  const form = useForm<RemoteObjectFormValues>({
    defaultValues: { name: '', active: true },
  })
  const close = () => {
    form.reset()
    onClose()
  }

  return (
    <KuiModal
      opened={opened}
      onClose={close}
      title={`Create new ${renderEnumValue(type)}`}
      size='md'
      actions={[
        {
          variant: 'filled',
          label: 'Save',
          onClick: form.handleSubmit(onSubmit),
          loading: form.formState.isSubmitting,
        },
      ]}
    >
      <KuiForm
        form={form}
        onSubmit={onSubmit}
        render={() => (
          <KuiStack gapSize='md'>
            <KuiFormTextInput<RemoteObjectFormValues>
              name='name'
              label='Name'
              required={true}
            />
            <Switch
              label='Active'
              checked={form.watch('active')}
              onChange={(event) =>
                form.setValue('active', event.target.checked)
              }
            />
          </KuiStack>
        )}
      />
    </KuiModal>
  )

  async function onSubmit(data: RemoteObjectFormValues) {
    wrapKuiApiRequest(async () => {
      const res = await remoteObjectCreate({ ...data, type })
      if (res) {
        afterSubmit(res)
        close()
      }
    })()
  }
}
