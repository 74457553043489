import {
  FocusTrap,
  LoadingOverlay,
  ModalBody as MantineModalBody,
  ModalCloseButton as MantineModalCloseButton,
  ModalContent as MantineModalContent,
  ModalHeader as MantineModalHeader,
  ModalOverlay as MantineModalOverlay,
  ModalRoot as MantineModalRoot,
  ModalTitle as MantineModalTitle,
  Text as MantineText,
} from '@mantine/core'
import classNames from 'classnames'
import {
  Fragment,
  useCallback,
  useState,
  type FormHTMLAttributes,
  type PropsWithChildren,
  type ReactNode,
} from 'react'
import styled from 'styled-components'

import { kuiThemeVars, type KuiThemeStackingLevel } from './_internal/theme'
import {
  KuiActionList,
  KuiActionListItem,
  type KuiAction,
} from './KuiActionList'
import { KuiFlex } from './KuiFlex'
import { KuiPad, mixKuiPad, type MixKuiPadProps } from './KuiPad'
import { useKuiMediaQuery } from './useKuiMediaQuery'

const KuiModalHeader = styled(MantineModalHeader)`
  flex-shrink: 0;

  position: unset;

  &.KuiModalHeader--full {
    background-color: var(--mantine-color-backgroundRecessed-0);
    border-bottom: 1px solid var(--mantine-color-gray-3);
  }
`

const KuiModalBody = styled(MantineModalBody)`
  position: relative;
  height: 100%;
  overflow-y: auto;
`

const KuiModalFooterCallout = styled.div`
  flex-shrink: 0;
  background-color: var(--mantine-color-backgroundRecessed-0);
  ${mixKuiPad({ horizontalSize: 'md', verticalSize: 'xs' })}

  border-top: 1px solid var(--mantine-color-gray-3);
`

const KuiModalFooter = styled.div`
  flex-shrink: 0;
  background-color: var(--mantine-color-body);
  ${mixKuiPad({ size: 'md' })}

  &.KuiModalFooter--full {
    border-top: 1px solid var(--mantine-color-gray-3);
  }
`

type KuiModalSize = 'sm' | 'md' | 'xl'

const sizeMap: Record<KuiModalSize, string> = {
  sm: '380px',
  md: '700px',
  xl: '900px',
}

type KuiModalVariant = 'simple' | 'full'

type KuiModalAction = KuiAction & {
  //** @default false */
  autoClose?: boolean
}

type KuiModalContentProps = {
  title: string
  description?: string

  /** @default 'sm' */
  size?: KuiModalSize

  /** @default false */
  fullHeight?: boolean

  /** @default 'full' */
  variant?: KuiModalVariant

  /** @default { variant: 'outline', label: 'Cancel' } */
  leftAction?: KuiModalAction | null

  actions?: KuiModalAction[]

  children?: ReactNode

  formProps?: Pick<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'>

  loading?: boolean

  footerCallout?: ReactNode

  fullScreenOnMobile?: boolean

  _removePadding?: boolean

  onClose: () => void
}

function KuiModalContent({
  title,
  description,
  size = 'sm',
  fullHeight = false,
  variant = 'full',
  leftAction = {
    variant: 'outline',
    label: 'Cancel',
  },
  actions = [],
  children,
  formProps,
  footerCallout,
  loading = false,
  fullScreenOnMobile = size !== 'sm',
  _removePadding = false,
  onClose,
}: KuiModalContentProps) {
  const hasFooter = leftAction || actions.length > 0
  const bodyPadding = getBodyPadding()
  const isMobile = useKuiMediaQuery({ maxWidth: 'sm' })
  const fullScreen = isMobile && fullScreenOnMobile

  const bodyContent = (
    <Fragment>
      {description && (
        <KuiPad bottomSize='sm'>
          <MantineText size='sm'>{description}</MantineText>
        </KuiPad>
      )}

      {children}
    </Fragment>
  )

  const content = (
    <MantineModalContent
      h={fullHeight ? '100%' : undefined}
      styles={{
        content: {
          display: 'flex',
          flexDirection: 'column',
          flex: 'unset',
          width: fullScreen ? '100%' : sizeMap[size],
        },
      }}
    >
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />

      <KuiModalHeader
        className={classNames({
          'KuiModalHeader--full': variant === 'full',
        })}
      >
        <MantineModalTitle>{title}</MantineModalTitle>

        <MantineModalCloseButton />
      </KuiModalHeader>

      <KuiModalBody p='0' style={{ overflow: loading ? 'hidden' : 'auto' }}>
        <FocusTrap.InitialFocus />

        {_removePadding ? (
          bodyContent
        ) : (
          <KuiPad {...bodyPadding}>{bodyContent}</KuiPad>
        )}
      </KuiModalBody>

      {footerCallout && (
        <KuiModalFooterCallout>{footerCallout}</KuiModalFooterCallout>
      )}

      {hasFooter && (
        <KuiModalFooter
          className={classNames({
            'KuiModalFooter--full': variant === 'full',
          })}
        >
          <KuiFlex justifyContent={leftAction ? 'spaceBetween' : 'flexEnd'}>
            {leftAction && (
              <KuiActionListItem action={makeModalAction(leftAction)} />
            )}

            <KuiActionList actions={actions.map(makeModalAction)} />
          </KuiFlex>
        </KuiModalFooter>
      )}
    </MantineModalContent>
  )

  return formProps ? <form {...formProps}>{content}</form> : content

  function getBodyPadding(): MixKuiPadProps {
    if (_removePadding) {
      return { size: 'none' }
    }

    if (variant === 'full') {
      return { size: 'md' }
    }

    return { size: 'md', bottomSize: hasFooter ? 'none' : 'md' }
  }

  function makeModalAction(action: KuiModalAction): KuiAction {
    if ('items' in action) {
      return action
    }

    if (
      action.autoClose === false ||
      action.type === 'submit' // don't auto close on submit since there may be async submission
    ) {
      return action
    }

    return {
      ...action,
      onClick: async (event) => {
        await action.onClick?.(event)

        onClose()
      },
    }
  }
}

type KuiModalRootProps = {
  opened: boolean
  onClose: () => void
  fullScreenOnMobile: boolean
  _zIndex?: KuiThemeStackingLevel
}

function KuiModalRoot({
  opened,
  onClose,
  _zIndex = 'modal',
  fullScreenOnMobile,
  children,
}: PropsWithChildren<KuiModalRootProps>) {
  const isMobile = useKuiMediaQuery({ maxWidth: 'sm' })
  const fullScreen = isMobile && fullScreenOnMobile

  return (
    <MantineModalRoot
      opened={opened}
      onClose={onClose}
      centered={true}
      zIndex={kuiThemeVars.stackingLevels[_zIndex]}
      closeOnEscape={opened}
      fullScreen={fullScreen}
      transitionProps={
        fullScreen ? { transition: 'fade', duration: 200 } : undefined
      }
    >
      <MantineModalOverlay />

      {children}
    </MantineModalRoot>
  )
}

export type KuiModalProps = Omit<KuiModalRootProps, 'fullScreenOnMobile'> &
  KuiModalContentProps & {
    fullScreenOnMobile?: boolean
  }

function KuiModalImpl({
  opened,
  onClose,
  _zIndex,
  size = 'sm',
  fullScreenOnMobile = size !== 'sm',
  ...modalContentProps
}: KuiModalProps) {
  return (
    <KuiModalRoot
      opened={opened}
      onClose={onClose}
      fullScreenOnMobile={fullScreenOnMobile}
      _zIndex={_zIndex}
    >
      <KuiModalContent
        size={size}
        fullScreenOnMobile={fullScreenOnMobile}
        {...modalContentProps}
        onClose={onClose}
      />
    </KuiModalRoot>
  )
}

const KuiModalPaddingOffset = styled.div`
  margin: 0 calc(-1 * ${kuiThemeVars.spacingSizes.md});
`

export const KuiModal = Object.assign(KuiModalImpl, {
  Root: KuiModalRoot,
  Content: KuiModalContent,
  PaddingOffset: KuiModalPaddingOffset,
})

export type UseKuiModalStateReturn<TState> = {
  opened: boolean
  state: TState | null
  open: (state: TState) => void
  close: () => void
}

export function useKuiModalState<TState>(): UseKuiModalStateReturn<TState> {
  const [modalState, setModalState] = useState<{
    state: TState | null
    opened: boolean
  }>({
    state: null,
    opened: false,
  })

  const open = useCallback(
    (state: TState) => setModalState({ state, opened: true }),
    []
  )
  const close = useCallback(
    () => setModalState((prev) => ({ ...prev, opened: false })),
    []
  )

  return { opened: modalState.opened, state: modalState.state, open, close }
}
