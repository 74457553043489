import { type CTATypeEnum, type OrderDumpSchema } from '_autogenerated'

export function getOrderCta(
  order: Pick<OrderDumpSchema, 'status' | 'signed_at' | 'has_amount_owed'>
): CTATypeEnum | undefined {
  if (order.status === 'quote') {
    return undefined
  }

  if (!order.signed_at) {
    return 'esign'
  }

  if (order.has_amount_owed) {
    return 'payment'
  }

  return 'order'
}

export function getOrderType(order: Pick<OrderDumpSchema, 'status'>) {
  if (order.status === 'quote') {
    return 'quote'
  }

  return 'order'
}
