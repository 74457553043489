import {
  CheckIcon as MantineCheckIcon,
  Combobox as MantineCombobox,
  type ComboboxOptionProps as MantineComboboxOptionProps,
  type ComboboxStore as MantineComboboxStore,
} from '@mantine/core'
import { useMemo, useState, type ReactNode } from 'react'
import styled from 'styled-components'

import { KuiButton, type KuiButtonProps } from 'components/kui/KuiButton'
import { KuiFlex } from 'components/kui/KuiFlex'
import { type KuiIconType } from 'components/kui/KuiIcon/KuiIcon'
import { mixKuiPad } from 'components/kui/KuiPad'
import { KuiTextBits, type KuiTextBitsProps } from 'components/kui/KuiTextBits'
import { KuiTooltip } from 'components/kui/KuiTooltip'

type ItemGroup<TItemSingle> = {
  label: string
  items: TItemSingle[]
}

type Item<TItemSingle> = TItemSingle | ItemGroup<TItemSingle>

export type ParsedItem = {
  key: string
  label: string
  description?: string | Pick<KuiTextBitsProps, 'bits'>

  disabled?: boolean

  tooltipContent?: string

  renderOption?: () => ReactNode
  searchTerms?: string[]

  _rightAction?: KuiButtonProps
}

type ParseItemFn<TItemSingle> = (item: TItemSingle) => ParsedItem

type KuiComboboxFooterAction = {
  key: string
  label: string
  onClick: () => void

  iconType?: KuiIconType
}

export type KuiComboboxOptionsDropdownProps<TItemSingle> = {
  items: Item<TItemSingle>[]
  parseItem: ParseItemFn<TItemSingle>

  queryString?: string

  /** @default true */
  filterOptions?: boolean

  /** @default false */
  loading?: boolean

  /** @default 'No results found' */
  nothingFoundMessage?: string

  footerActions?: KuiComboboxFooterAction[]

  _creatableProps?: {
    getLabel: (queryString: string) => string
    onCreate: (queryString: string) => void
  }

  _footerButtons?: { left: KuiButtonProps; right: KuiButtonProps }

  hiddenWhenEmpty?: boolean

  _hiddenWhenSearchEmpty?: boolean
}

type KuiComboboxOptionsDropdownPropsWithInternal<TItemSingle> =
  KuiComboboxOptionsDropdownProps<TItemSingle> & {
    combobox: MantineComboboxStore
    isItemSelected?: (item: TItemSingle) => boolean
    onItemSelect: (item: TItemSingle) => void

    onSearch?: (search: string) => void

    dropdownRef?: React.RefObject<HTMLDivElement>
  }

export function KuiComboboxOptionsDropdown<TItemSingle>({
  combobox,
  items,
  parseItem,
  queryString = '',
  onSearch,
  filterOptions = true,
  loading = false,
  nothingFoundMessage = 'No results found',
  hiddenWhenEmpty = false,
  _hiddenWhenSearchEmpty = false,
  footerActions = [],
  isItemSelected,
  onItemSelect,
  _creatableProps,
  _footerButtons,
  dropdownRef,
}: KuiComboboxOptionsDropdownPropsWithInternal<TItemSingle>) {
  const filteredItems = useMemo(() => {
    const filteredItems = filterOptions
      ? defaultComboboxItemsFilter({
          items,
          queryString,
          parseItem,
        })
      : items

    return filteredItems
  }, [filterOptions, items, parseItem, queryString])

  const hasCreateOption = _creatableProps && queryString.trim().length > 0

  const itemsWithIndex = useMemo(() => {
    let optionIndex = hasCreateOption ? 1 : 0

    return filteredItems.map((item) => {
      if (isItemGroup(item)) {
        const items = item.items.map((item) => {
          return { item, optionIndex: optionIndex++ }
        })

        return { ...item, items }
      }

      return { item, optionIndex: optionIndex++ }
    }) satisfies Item<{ item: TItemSingle; optionIndex: number }>[]
  }, [filteredItems, hasCreateOption])

  const isEmpty = filteredItems.length === 0 && !hasCreateOption

  return (
    <MantineCombobox.Dropdown
      hidden={
        (hiddenWhenEmpty && isEmpty) || (_hiddenWhenSearchEmpty && !queryString)
      }
      // stop propagation to prevent closing parent popovers
      onMouseDown={(event) => event.stopPropagation()}
      onTouchStart={(event) => event.stopPropagation()}
    >
      {onSearch && (
        <MantineCombobox.Search
          value={queryString}
          onChange={(event) => onSearch(event.currentTarget.value)}
          placeholder='Search items'
        />
      )}

      <MantineCombobox.Options
        ref={dropdownRef}
        mah={220}
        style={{ overflowY: 'auto' }}
        onPointerLeave={() => combobox.resetSelectedOption()}
      >
        {hasCreateOption && (
          <KuiComboboxOption
            parsedItem={{
              key: 'create',
              label: _creatableProps.getLabel(queryString),
            }}
            isSelected={false}
            onPointerMove={() => combobox.selectOption(0)}
            onClick={() => _creatableProps.onCreate(queryString)}
          />
        )}

        {itemsWithIndex.map((itemOrGroup) => {
          if (isItemGroup(itemOrGroup)) {
            return (
              <MantineCombobox.Group
                key={itemOrGroup.label}
                label={itemOrGroup.label}
              >
                {itemOrGroup.items.map((enrichedItem) => {
                  const { item, optionIndex } = enrichedItem
                  const parsedItem = parseItem(item)

                  return (
                    <KuiComboboxOption
                      key={parsedItem.key}
                      parsedItem={parsedItem}
                      isSelected={isItemSelected?.(item)}
                      onPointerMove={() => combobox.selectOption(optionIndex)}
                      onClick={() => onItemSelect(item)}
                    />
                  )
                })}
              </MantineCombobox.Group>
            )
          }

          const { item, optionIndex } = itemOrGroup
          const parsedItem = parseItem(item)

          return (
            <KuiComboboxOption
              key={parsedItem.key}
              parsedItem={parsedItem}
              isSelected={isItemSelected?.(item)}
              onPointerMove={() => combobox.selectOption(optionIndex)}
              onClick={() => onItemSelect(item)}
            />
          )
        })}

        {loading && isEmpty && (
          <MantineCombobox.Empty>Loading…</MantineCombobox.Empty>
        )}

        {!loading && isEmpty && nothingFoundMessage && (
          <MantineCombobox.Empty>{nothingFoundMessage}</MantineCombobox.Empty>
        )}
      </MantineCombobox.Options>

      {_footerButtons && (
        <MantineCombobox.Footer style={{ padding: '4px' }}>
          <KuiFlex justifyContent='spaceBetween' gapSize='xs'>
            <KuiButton
              size='xs'
              {..._footerButtons.left}
              onClick={(event) => {
                combobox.closeDropdown()
                _footerButtons.left.onClick?.(event)
              }}
            />

            <KuiButton
              size='xs'
              {..._footerButtons.right}
              onClick={(event) => {
                combobox.closeDropdown()
                _footerButtons.right.onClick?.(event)
              }}
            />
          </KuiFlex>
        </MantineCombobox.Footer>
      )}

      {footerActions.length > 0 && (
        <MantineCombobox.Footer style={{ padding: '4px' }}>
          {footerActions.map((action) => (
            <FooterActionRoot key={action.key}>
              <KuiButton
                fullWidth={true}
                color='accent'
                variant='transparent'
                iconType={action.iconType}
                _leftAlign={true}
                onClick={() => {
                  combobox.closeDropdown()

                  action.onClick()
                }}
              >
                {action.label}
              </KuiButton>
            </FooterActionRoot>
          ))}
        </MantineCombobox.Footer>
      )}
    </MantineCombobox.Dropdown>
  )
}

const FooterActionRoot = styled.div`
  border-radius: 4px;
  ${mixKuiPad({ horizontalSize: 'xs' })}

  &:hover {
    background-color: var(--mantine-color-gray-1) !important;
  }
`

const OptionCheckIcon = styled(MantineCheckIcon)`
  opacity: 0.4;
  width: 0.8em;
  min-width: 0.8em;
  height: 0.8em;

  :where([data-combobox-selected]) & {
    opacity: 1;
  }
`

const KuiComboboxOptionRoot = styled(MantineCombobox.Option)`
  &:hover {
    background-color: inherit;
  }

  &:where([data-combobox-selected]) {
    background-color: var(--mantine-color-gray-1) !important;
    color: unset;
  }
`

type KuiComboboxOptionProps = Partial<MantineComboboxOptionProps> & {
  parsedItem: ParsedItem
  isSelected?: boolean
  onClick: () => void
}

function KuiComboboxOption({
  parsedItem,
  isSelected = false,
  ...restProps
}: KuiComboboxOptionProps) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <KuiComboboxOptionRoot
      value={parsedItem.key}
      disabled={parsedItem.disabled}
      onMouseEnter={
        parsedItem._rightAction ? () => setIsHovered(true) : undefined
      }
      onMouseLeave={
        parsedItem._rightAction ? () => setIsHovered(false) : undefined
      }
      {...restProps}
    >
      <KuiTooltip content={parsedItem.tooltipContent} fullWidth={true}>
        <KuiFlex gapSize='xs'>
          {isSelected && <OptionCheckIcon />}

          <KuiFlex justifyContent='spaceBetween' grow={1} gapSize='xs'>
            {parsedItem.renderOption?.() ?? (
              <KuiSelectOption
                title={parsedItem.label}
                description={parsedItem.description}
              />
            )}

            {parsedItem._rightAction && (
              <div style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
                <KuiButton
                  size='xs'
                  {...parsedItem._rightAction}
                  onClick={(event) => {
                    event.stopPropagation()
                    parsedItem._rightAction?.onClick?.(event)
                  }}
                />
              </div>
            )}
          </KuiFlex>
        </KuiFlex>
      </KuiTooltip>
    </KuiComboboxOptionRoot>
  )
}

export function KuiSelectOption({
  title,
  description,
}: {
  title: ReactNode
  description?: string | Pick<KuiTextBitsProps, 'bits'>
}) {
  if (!description) {
    return title
  }

  return (
    <div>
      {title}
      <KuiTextBits
        size='xs'
        color='hushed'
        bits={
          typeof description === 'string' ? [description] : description.bits
        }
      />
    </div>
  )
}

function isItemGroup<TItemSingle>(
  item: Item<TItemSingle>
): item is ItemGroup<TItemSingle> {
  // @ts-ignore
  return !!(item?.label && item?.items)
}

export function defaultComboboxItemsFilter<TItemSingle>({
  items,
  queryString,
  parseItem,
}: {
  items: Item<TItemSingle>[]
  queryString: string
  parseItem: ParseItemFn<TItemSingle>
}): Item<TItemSingle>[] {
  return filterItems({
    items,
    predicate: (item) => {
      const parsedQueryString = queryString.trim().toLowerCase()

      const { label, searchTerms = [] } = parseItem(item)

      return (
        label.toLowerCase().includes(parsedQueryString) ||
        searchTerms.some((term) =>
          term.toLowerCase().includes(parsedQueryString)
        )
      )
    },
  })
}

export function filterItems<TItemSingle>({
  items,
  predicate,
}: {
  items: Item<TItemSingle>[]
  predicate: (item: TItemSingle) => boolean
}): Item<TItemSingle>[] {
  const result: Item<TItemSingle>[] = []

  for (const item of items) {
    if (isItemGroup(item)) {
      const items = filterItems({
        items: item.items,
        predicate,
      }) as TItemSingle[]

      if (items.length) {
        result.push({
          ...item,
          items,
        })
      }
    } else if (predicate(item)) {
      result.push(item)
    }
  }

  return result
}
