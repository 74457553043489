import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
} from 'react'

import { type KuiAction } from './KuiActionList'
import { KuiModal, type KuiModalProps } from './KuiModal'

export type KuiConfirmationModalProps = Pick<
  KuiModalProps,
  'opened' | 'title' | 'description'
> & {
  cancelProps?: Omit<KuiAction, 'onClick'>
  confirmProps: Omit<KuiAction, 'onClick'>
  onCancel?: () => void
  onConfirm: (() => unknown) | (() => Promise<unknown>)
}

type KuiConfirmationModalContextValue = {
  modalProps: KuiConfirmationModalProps | null
  setModalProps: Dispatch<SetStateAction<KuiConfirmationModalProps | null>>
}

const KuiConfirmationModalContext = createContext<
  KuiConfirmationModalContextValue | undefined
>(undefined)

export function KuiConfirmationModalProvider({ children }: PropsWithChildren) {
  const [modalProps, setModalProps] =
    useState<KuiConfirmationModalContextValue['modalProps']>(null)

  return (
    <KuiConfirmationModalContext.Provider
      value={{
        modalProps,
        setModalProps,
      }}
    >
      <KuiModal
        opened={!!modalProps?.opened}
        onClose={modalProps?.onCancel ?? (() => {})}
        title={modalProps?.title ?? ''}
        variant='simple'
        size='sm'
        description={modalProps?.description}
        leftAction={{
          variant: 'outline',
          label: 'Cancel',
          ...modalProps?.cancelProps,
          onClick: modalProps?.onCancel,
        }}
        actions={[
          {
            variant: 'filled',
            ...modalProps?.confirmProps,
            onClick: modalProps?.onConfirm,
          },
        ]}
        _zIndex='confirmationModal'
      />

      {children}
    </KuiConfirmationModalContext.Provider>
  )
}

type KuiConfirmParams = Omit<KuiConfirmationModalProps, 'opened'> & {
  shouldConfirm?: boolean
}

export function useKuiConfirmation() {
  const { setModalProps } = useContext(KuiConfirmationModalContext)!

  const confirm = useCallback(
    ({ shouldConfirm = true, ...modalProps }: KuiConfirmParams) => {
      if (!shouldConfirm) {
        return modalProps.onConfirm()
      }

      setModalProps({
        opened: true,
        ...modalProps,
        onCancel: () => {
          modalProps.onCancel?.()
          // we need to keep the previous props so the modal can animate out
          setModalProps((prev) => (prev ? { ...prev, opened: false } : null))
        },
        onConfirm: async () => {
          await modalProps.onConfirm()
          // we need to keep the previous props so the modal can animate out
          setModalProps((prev) => (prev ? { ...prev, opened: false } : null))
        },
      })
    },
    [setModalProps]
  )

  return useMemo(() => ({ confirm }), [confirm])
}
