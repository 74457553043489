import { useDebouncedState } from '@mantine/hooks'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import {
  useFormContext,
  type FieldPath,
  type FieldValues,
} from 'react-hook-form'
import SmartySDK from 'smartystreets-javascript-sdk'

import {
  type AddressCreateSchema,
  CountryEnum,
  StateEnum,
} from '_autogenerated'
import { type KuiQueryOptions } from 'apis/types'
import { KuiGrid } from 'components/kui/KuiGrid'
import { KuiStack } from 'components/kui/KuiStack'
import { renderEnumValue } from 'utils/enums'
import { assertNonNullKeys, type RequireGeneric } from 'utils/types'

import { KuiFormAutocompleteInput } from './KuiFormAutocompleteInput'
import { KuiFormSelectInput } from './KuiFormSelectInput'
import { KuiFormTextInput } from './KuiFormTextInput'

export type KuiFormAddressFieldValues = {
  address_one: string
  address_two?: string | null
  city: string
  country: CountryEnum | null
  state: StateEnum | null
  zip: string
}

const states = Object.keys(StateEnum) as StateEnum[]
const countries = Object.keys(CountryEnum) as CountryEnum[]

type KuiFormAddressFieldsProps<TFieldValues extends FieldValues> = {
  name: FieldPath<RequireGeneric<TFieldValues>>
  required?: boolean
}

export function KuiFormAddressFields<TFieldValues extends FieldValues = never>({
  name,
  required,
}: KuiFormAddressFieldsProps<TFieldValues>) {
  const formContext = useFormContext<KuiFormAddressFieldValues>()

  const [debouncedSearch, setDebouncedSearch] = useDebouncedState('', 200, {
    leading: true,
  })

  const smartyStreetsSearchQuery = useSmartyStreetsAddressSearch(
    debouncedSearch,
    {
      enabled: !!debouncedSearch,
    }
  )

  return (
    <KuiStack gapSize='xs'>
      <KuiFormAutocompleteInput<
        KuiFormAddressFieldValues,
        'address_one',
        SmartyStreetsAddress
      >
        name={`${name}.address_one` as any}
        label='Address'
        required={required}
        items={smartyStreetsSearchQuery.data?.result ?? []}
        loading={smartyStreetsSearchQuery.isLoading}
        parseItem={(item) => ({
          key: renderSmartyStreetsAddress(item),
          label: renderSmartyStreetsAddress(item),
        })}
        onChange={setDebouncedSearch}
        hiddenWhenEmpty={true}
        onSelect={handleSelectAddress}
      />
      <KuiFormTextInput<KuiFormAddressFieldValues, 'address_two'>
        name={`${name}.address_two` as any}
        placeholder='Apt #, Suite, etc.'
      />
      <KuiGrid columns={2} gapSize='md'>
        <KuiFormTextInput<KuiFormAddressFieldValues, 'city'>
          name={`${name}.city` as any}
          label='City'
          required={required}
        />

        <KuiFormSelectInput<KuiFormAddressFieldValues, 'state'>
          name={`${name}.state` as any}
          label='State'
          required={required}
          items={states}
          parseItem={(item) => ({
            key: item,
            label: renderEnumValue(item),
            searchTerms: [stateEnumToCode[item]],
          })}
        />
      </KuiGrid>
      <KuiGrid columns={2} gapSize='md'>
        <KuiFormTextInput<KuiFormAddressFieldValues, 'zip'>
          name={`${name}.zip` as any}
          label='Zip'
          required={required}
        />

        <KuiFormSelectInput<KuiFormAddressFieldValues, 'country'>
          name={`${name}.country` as any}
          label='Country'
          searchable={false}
          required={required}
          items={countries}
          parseItem={(item) => ({ key: item, label: renderEnumValue(item) })}
        />
      </KuiGrid>
    </KuiStack>
  )

  function handleSelectAddress(address: SmartyStreetsAddress) {
    formContext.setValue(
      `${name}.address_one` as 'address_one',
      address.streetLine,
      { shouldValidate: true }
    )

    if (address.secondary) {
      formContext.setValue(
        `${name}.address_two` as 'address_two',
        address.secondary,
        { shouldValidate: true }
      )
    }

    formContext.setValue(`${name}.city` as 'city', address.city, {
      shouldValidate: true,
    })
    formContext.setValue(
      `${name}.state` as 'state',
      stateCodeToEnum[address.state],
      { shouldValidate: true }
    )
    formContext.setValue(`${name}.zip` as 'zip', address.zipcode, {
      shouldValidate: true,
    })
    formContext.setValue(
      `${name}.country` as 'country',
      CountryEnum.united_states,
      { shouldValidate: true }
    )
  }
}

type SmartyStreetsAddress = SmartySDK.usAutocompletePro.Suggestion

function renderSmartyStreetsAddress(address: SmartyStreetsAddress): string {
  const secondaryPart = address.secondary ? `${address.secondary} ` : ''

  return `${address.streetLine} ${secondaryPart}${address.city}, ${address.state} ${address.zipcode}`
}

function useSmartyStreetsAddressSearch(
  searchQuery: string,
  options?: KuiQueryOptions
) {
  return useQuery({
    queryKey: ['smartyStreetsAddressSearch', searchQuery],
    queryFn: () => smartyStreetsAddressSearch(searchQuery),
    placeholderData: keepPreviousData,
    ...options,
  })
}

const SmartyCore = SmartySDK.core
const { Lookup } = SmartySDK.usAutocompletePro
const credentials = new SmartyCore.SharedCredentials('194433837971787030')
const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([
  'us-autocomplete-pro-cloud',
])
const client = clientBuilder.buildUsAutocompleteProClient()

function smartyStreetsAddressSearch(searchQuery: string) {
  const lookup = new Lookup(searchQuery)

  return client.send(lookup)
}

const stateEnumToCode: Record<StateEnum, string> = {
  [StateEnum.alabama]: 'AL',
  [StateEnum.alaska]: 'AK',
  [StateEnum.arizona]: 'AZ',
  [StateEnum.arkansas]: 'AR',
  [StateEnum.california]: 'CA',
  [StateEnum.colorado]: 'CO',
  [StateEnum.connecticut]: 'CT',
  [StateEnum.delaware]: 'DE',
  [StateEnum.florida]: 'FL',
  [StateEnum.georgia]: 'GA',
  [StateEnum.hawaii]: 'HI',
  [StateEnum.idaho]: 'ID',
  [StateEnum.illinois]: 'IL',
  [StateEnum.indiana]: 'IN',
  [StateEnum.iowa]: 'IA',
  [StateEnum.kansas]: 'KS',
  [StateEnum.kentucky]: 'KY',
  [StateEnum.louisiana]: 'LA',
  [StateEnum.maine]: 'ME',
  [StateEnum.maryland]: 'MD',
  [StateEnum.massachusetts]: 'MA',
  [StateEnum.michigan]: 'MI',
  [StateEnum.minnesota]: 'MN',
  [StateEnum.mississippi]: 'MS',
  [StateEnum.missouri]: 'MO',
  [StateEnum.montana]: 'MT',
  [StateEnum.nebraska]: 'NE',
  [StateEnum.nevada]: 'NV',
  [StateEnum.new_hampshire]: 'NH',
  [StateEnum.new_jersey]: 'NJ',
  [StateEnum.new_mexico]: 'NM',
  [StateEnum.new_york]: 'NY',
  [StateEnum.north_carolina]: 'NC',
  [StateEnum.north_dakota]: 'ND',
  [StateEnum.ohio]: 'OH',
  [StateEnum.oklahoma]: 'OK',
  [StateEnum.oregon]: 'OR',
  [StateEnum.pennsylvania]: 'PA',
  [StateEnum.rhode_island]: 'RI',
  [StateEnum.south_carolina]: 'SC',
  [StateEnum.south_dakota]: 'SD',
  [StateEnum.tennessee]: 'TN',
  [StateEnum.texas]: 'TX',
  [StateEnum.utah]: 'UT',
  [StateEnum.vermont]: 'VT',
  [StateEnum.virginia]: 'VA',
  [StateEnum.washington]: 'WA',
  [StateEnum.west_virginia]: 'WV',
  [StateEnum.wisconsin]: 'WI',
  [StateEnum.wyoming]: 'WY',
  [StateEnum.district_of_columbia]: 'DC',
  [StateEnum.american_samoa]: 'AS',
  [StateEnum.guam]: 'GU',
  [StateEnum.puerto_rico]: 'PR',
}

const stateCodeToEnum: Record<string, StateEnum> = Object.fromEntries(
  Object.entries(stateEnumToCode).map(([key, value]) => [value, key])
) as Record<string, StateEnum>

export const kuiFormAddressFieldsEmptyDefaultValues: KuiFormAddressFieldValues =
  {
    address_one: '',
    address_two: null,
    city: '',
    country: null,
    state: null,
    zip: '',
  }

export function mapKuiFormAddressFieldValuesToRequestSchema<
  TRequired extends boolean,
>({
  address,
  required,
}: {
  address: KuiFormAddressFieldValues
  required: TRequired
}): TRequired extends true ? AddressCreateSchema : AddressCreateSchema | null {
  if (required) {
    assertNonNullKeys(address, ['country', 'state'])

    return address
  }

  return (address.country && address.state ? address : null) as any
}
