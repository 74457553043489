import { notifications } from '@mantine/notifications'
import * as Sentry from '@sentry/react'
import axios, { type AxiosError } from 'axios'

export function wrapKuiApiRequest<TParams extends any[], TReturn>(
  fn: (...params: TParams) => TReturn
) {
  return async function (...params: TParams) {
    try {
      const maybePromise = fn(...params)

      if (maybePromise instanceof Promise) {
        const result = await Promise.resolve(maybePromise)

        return result
      }

      return maybePromise
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message =
          parseMessageFromApiError(error) ?? 'Something went wrong'

        notifications.show({
          message,
          color: 'red',
          autoClose: 10_000,
        })

        // temporarily logging to sentry for debugging
        Sentry.captureException(error, { level: 'debug' })

        return
      }

      notifications.show({
        message: 'Something went wrong',
        color: 'red',
        autoClose: false,
      })

      console.error(error)
      Sentry.captureException(error)
    }
  }
}

function parseMessageFromApiError(
  error: AxiosError<{ errors?: { root?: string } }>
): string | null {
  if (error.response?.status === 400 && error.response.data.errors) {
    return error.response.data.errors.root ?? null
  }

  return null
}
