import { Image, Title } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { Fragment, useEffect, useRef, type PropsWithChildren } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useRouteError } from 'react-router-dom'

import { useLogout } from 'apis/auth'
import logo from 'assets/logo.svg'

import { KuiBreak } from './components/kui/KuiBreak'
import { KuiButton } from './components/kui/KuiButton'
import { KuiPad } from './components/kui/KuiPad'
import { KuiText } from './components/kui/KuiText'
import { useKuiMediaQuery } from './components/kui/useKuiMediaQuery'

export function ApplicationErrorBoundary({ children }: PropsWithChildren) {
  return (
    <ErrorBoundary
      fallbackRender={(props) => (
        <ErrorFallback {...props} fullPage={true} inRouter={false} />
      )}
    >
      {children}
    </ErrorBoundary>
  )
}

export function KuiErrorBoundary({ children }: PropsWithChildren) {
  return (
    <ErrorBoundary
      fallbackRender={(props) => (
        <ErrorFallback {...props} fullPage={false} inRouter={true} />
      )}
    >
      {children}
    </ErrorBoundary>
  )
}

export function RouteErrorBoundary({ fullPage }: { fullPage?: boolean }) {
  const error = useRouteError()

  return (
    <ErrorFallback error={error as Error} fullPage={fullPage} inRouter={true} />
  )
}

type KuiErrorBoundaryFallbackProps = {
  error: Error
  fullPage?: boolean
  inRouter?: boolean
}

function ErrorFallback({
  error,
  fullPage = false,
  inRouter = false,
}: KuiErrorBoundaryFallbackProps) {
  const isMobile = useKuiMediaQuery({ maxWidth: 'sm' })

  const shouldLogout =
    inRouter && axios.isAxiosError(error) && error.response?.status === 401

  const logout = useLogout()
  const logoutEffectRun = useRef(false)

  useEffect(() => {
    if (shouldLogout && !logoutEffectRun.current) {
      notifications.show({
        title: 'Session expired',
        message: 'Log in again to continue',
        color: 'red',
        autoClose: 5_000,
      })

      logout()

      logoutEffectRun.current = true
    }
  }, [logout, shouldLogout])

  useEffect(() => {
    if (axios.isAxiosError(error)) {
      // temporarily logging to sentry for debugging
      Sentry.captureException(error, { level: 'debug' })

      return
    }

    Sentry.captureException(error)
  }, [error])

  if (shouldLogout) {
    return null
  }

  const { title, message } = getErrorContent()

  const mainContent = (
    <div>
      <Title order={1}>{title}</Title>

      <KuiBreak />

      <KuiText.p>{message}</KuiText.p>

      <KuiBreak />

      <KuiButton
        variant='outline'
        size='m'
        {...(inRouter
          ? { to: '/' }
          : { onClick: () => window.location.replace('/') })}
      >
        Return home
      </KuiButton>
    </div>
  )

  if (fullPage) {
    return (
      <Fragment>
        <KuiPad size={isMobile ? 'md' : 'xl'}>
          <Image
            src={logo}
            alt='logo'
            fit='contain'
            maw='100px'
            style={{ aspectRatio: '821/171' }}
          />

          <KuiBreak size='xl' />
          <KuiBreak size='xl' />

          {mainContent}
        </KuiPad>
      </Fragment>
    )
  }

  return mainContent

  function getErrorContent() {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        return {
          title: 'You need access',
          message: 'You do not have permission to view this page',
        }
      }

      if (error.response?.status === 404) {
        return {
          title: 'Object not found',
          message:
            "The specific object you're looking for doesn't exist or may have been removed",
        }
      }
    }

    return {
      title: 'Oops! Something went wrong',
      message:
        'We encountered an unexpected issue. Our team has been notified.',
    }
  }
}
