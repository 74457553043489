import { lazily } from 'react-lazily'

import { getGetDeliveryRouteQueryOptions } from '_autogenerated'
import { dispatchUrls } from 'routes/urls'
import { createRoute } from 'routing'

const { DispatchPage } = lazily(() => import('./DispatchPage/DispatchPage'))

export const dispatchRoutes = [
  createRoute({
    route: dispatchUrls,
    Component: DispatchPage,
    children: [
      createRoute({
        route: dispatchUrls.route,
        Component: EmptyComponent,
        prefetch: ({ routeId }) => [getGetDeliveryRouteQueryOptions(routeId)],
      }),
    ],
  }),
]

function EmptyComponent() {
  return null
}
