import { lazily } from 'react-lazily'

import { getCustomerGetQueryOptions } from '_autogenerated'
import { customerUrls } from 'routes/urls'
import { createRoute } from 'routing'

const {
  CustomerListPage,
  CustomerCreatePage,
  CustomerDetailPageRoot,
  CustomerEditPage,
  CustomerDetailPageDetailsTab,
  CustomerDetailPageOrdersTab,
  CustomerDetailPageInvoicesTab,
  CustomerDetailPagePaymentsTab,
} = lazily(() => import('./components'))

export const customerRoutes = [
  createRoute({
    route: customerUrls,
    Component: CustomerListPage,
  }),
  createRoute({
    route: customerUrls.create,
    Component: CustomerCreatePage,
  }),
  createRoute({
    route: customerUrls.detail,
    Component: CustomerDetailPageRoot,
    prefetch: ({ id }) => [getCustomerGetQueryOptions(id)],
    children: [
      createRoute({
        route: customerUrls.detail,
        Component: CustomerDetailPageDetailsTab,
      }),
      createRoute({
        route: customerUrls.detail.orders,
        Component: CustomerDetailPageOrdersTab,
      }),
      createRoute({
        route: customerUrls.detail.invoices,
        Component: CustomerDetailPageInvoicesTab,
      }),
      createRoute({
        route: customerUrls.detail.payments,
        Component: CustomerDetailPagePaymentsTab,
      }),
    ],
  }),
  createRoute({
    route: customerUrls.edit,
    Component: CustomerEditPage,
    prefetch: ({ id }) => [getCustomerGetQueryOptions(id)],
  }),
]
