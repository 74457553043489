import { kebabCase } from 'lodash'
import { type ReactNode } from 'react'
import flattenChildren from 'react-keyed-flatten-children'
import styled from 'styled-components'

import { kuiThemeVars, type KuiThemeSpacingSize } from './_internal/theme'
import { type MixKuiPadProps, mixKuiPad } from './KuiPad'

type KuiGridGapSize = KuiThemeSpacingSize

type KuiGridAlignItems = 'stretch' | 'start' | 'end' | 'center' | 'baseline'

type KuiGridJustifyContent =
  | 'start'
  | 'end'
  | 'center'
  | 'stretch'
  | 'spaceAround'
  | 'spaceBetween'
  | 'spaceEvenly'

type Props = {
  columns: number | string

  responsiveColumns?: number | string

  rows?: number

  /** @default `repeat(${rows}, 1fr)` */
  gridTemplateRows?: string

  /**
   * @default false
   */
  inline?: boolean

  /**
   * @default 'none'
   */
  gapSize?: KuiGridGapSize

  /**
   * @default the value of the `gapSize` prop, defaults to 'none'.
   */
  columnGapSize?: KuiGridGapSize

  /**
   * @default the value of the `gapSize` prop, defaults to 'none'.
   */
  rowGapSize?: KuiGridGapSize

  /**
   * @default 'stretch'
   */
  alignItems?: KuiGridAlignItems

  /**
   * @default 'start'
   */
  justifyContent?: KuiGridJustifyContent

  padding?: MixKuiPadProps

  _style?: React.CSSProperties

  children?: ReactNode
}

type KuiGridRootProps = {
  $inline: boolean
  $rows?: number
  $gridTemplateColumns?: string
  $responsiveGridTemplateColumns?: string
  $gridTemplateRows?: string
  $columnGapSize: KuiGridGapSize
  $rowGapSize: KuiGridGapSize
  $alignItems: KuiGridAlignItems
  $justifyContent: KuiGridJustifyContent
  $paddingProps?: MixKuiPadProps
  $maxHeight?: string
}

const KuiGridRoot = styled.div<KuiGridRootProps>`
  display: ${(p) => (p.$inline ? 'inline-grid' : 'grid')};
  grid-template-columns: ${(p) => p.$gridTemplateColumns};
  grid-template-rows: ${(p) => p.$gridTemplateRows};

  column-gap: ${(p) => kuiThemeVars.spacingSizes[p.$columnGapSize]};
  row-gap: ${(p) => kuiThemeVars.spacingSizes[p.$rowGapSize]};
  justify-content: ${(p) => kebabCase(p.$justifyContent)};
  align-items: ${(p) => kebabCase(p.$alignItems)};

  ${(p) => p.$paddingProps && mixKuiPad(p.$paddingProps)}

  @media (max-width: ${kuiThemeVars.breakpoints.md}) {
    grid-template-columns: ${(p) => p.$responsiveGridTemplateColumns};
  }
`

export function KuiGrid({
  columns,
  responsiveColumns = columns,
  rows,
  gridTemplateRows = rows ? `repeat(${rows}, 1fr)` : undefined,
  inline = false,
  gapSize = 'none',
  columnGapSize = gapSize,
  rowGapSize = gapSize,
  alignItems = 'stretch',
  justifyContent = 'start',
  padding,
  _style,
  children,
}: Props) {
  const flattenedChildren = flattenChildren(children)

  return (
    <KuiGridRoot
      $inline={inline}
      $gridTemplateColumns={
        typeof columns === 'number' ? `repeat(${columns}, 1fr)` : columns
      }
      $responsiveGridTemplateColumns={
        typeof responsiveColumns === 'number'
          ? `repeat(${responsiveColumns}, 1fr)`
          : responsiveColumns
      }
      $gridTemplateRows={gridTemplateRows}
      $columnGapSize={columnGapSize}
      $rowGapSize={rowGapSize}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
      $paddingProps={padding}
      style={_style}
    >
      {flattenedChildren}
    </KuiGridRoot>
  )
}
