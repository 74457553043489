import { lazily } from 'react-lazily'

import { getAssetGetQueryOptions } from '_autogenerated'
import { assetUrls } from 'routes/urls'
import { createRoute } from 'routing'

const {
  AssetListPageRoot,
  AssetListPageSerializedAssetTab,
  AssetListPageBulkAssetTab,
  AssetCreatePage,
  AssetDetailPageRoot,
  AssetDetailPageDetailsTab,
  AssetDetailPageOrdersTab,
  AssetDetailPageUsageTab,
  AssetEditPage,
} = lazily(() => import('./components'))

export const assetRoutes = [
  createRoute({
    route: assetUrls,
    Component: AssetListPageRoot,
    children: [
      createRoute({
        route: assetUrls,
        Component: AssetListPageSerializedAssetTab,
      }),
      createRoute({
        route: assetUrls.serialized,
        Component: AssetListPageSerializedAssetTab,
      }),
      createRoute({
        route: assetUrls.bulk,
        Component: AssetListPageBulkAssetTab,
      }),
    ],
  }),
  createRoute({
    route: assetUrls.create,
    Component: AssetCreatePage,
  }),
  createRoute({
    route: assetUrls.detail,
    Component: AssetDetailPageRoot,
    prefetch: ({ id }) => [getAssetGetQueryOptions(id)],
    children: [
      createRoute({
        route: assetUrls.detail,
        Component: AssetDetailPageDetailsTab,
      }),
      createRoute({
        route: assetUrls.detail.orders,
        Component: AssetDetailPageOrdersTab,
      }),
      createRoute({
        route: assetUrls.detail.usage,
        Component: AssetDetailPageUsageTab,
      }),
    ],
  }),
  createRoute({
    route: assetUrls.edit,
    Component: AssetEditPage,
    prefetch: ({ id }) => [getAssetGetQueryOptions(id)],
  }),
]
