import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import LogRocket from 'logrocket'
import { useCallback } from 'react'
import ReactGA from 'react-ga4'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  currentUser,
  getCurrentUserQueryKey,
  login,
  logout,
} from '_autogenerated'
import { useAuthContext } from 'components/Auth/AuthContext'
import { ActionTypes } from 'components/Auth/types'
import { isDeployed } from 'utils/helpers'

export function useLogin(): typeof login {
  const auth = useAuthContext()
  const queryClient = useQueryClient()

  return useCallback(
    async (requestBody) => {
      const response = await login(requestBody)

      const user = await currentUser() // this is a hack until the be returns the user in the login response
      queryClient.setQueryData(getCurrentUserQueryKey(), user)

      if (isDeployed) {
        ReactGA.event({
          category: 'auth',
          action: 'login-succes',
        })
        LogRocket.identify(user.id, {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
        })
        Sentry.setUser({ id: user.id, email: user.email })
      }

      auth.dispatch({
        type: ActionTypes.LOGIN,
        payload: { user },
      })

      return response
    },
    [auth, queryClient]
  )
}

export function useLogout() {
  const auth = useAuthContext()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const location = useLocation()

  return useCallback(() => {
    logout()

    auth.dispatch({ type: ActionTypes.LOGOUT, payload: {} })
    Sentry.setUser(null)
    queryClient.clear()

    navigate('/login', { state: { redirectPath: location.pathname } })
  }, [auth, location.pathname, navigate, queryClient])
}
