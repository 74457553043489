import { Group, Switch } from '@mantine/core'
import { Fragment, useState } from 'react'
import {
  type FieldValues,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form'

import {
  type RemoteAccountDumpSchema,
  remoteAccountCreate,
  remoteAccounts,
  RemoteAccountType,
  AccountingSubAccountType,
  type RemoteAccountCreateSchema,
} from '_autogenerated'
import { useRemoteAccountSelectProps } from 'apis/accounting'
import {
  KuiFlex,
  KuiForm,
  KuiFormSelectInput,
  KuiFormTextInput,
  KuiModal,
  KuiStack,
  wrapKuiApiRequest,
} from 'components/kui'
import { type RemoteAccountFormValues } from 'forms/RemoteAccountForm/types'
import { getMoabSubAccountTypeOptions } from 'utils/accounting'
import { renderEnumValue } from 'utils/enums'

import { type RemoteAccountInputPropsKui } from './types'

export function RemoteAccountInputKui<
  TFieldValues extends FieldValues = never,
>({ name, ...props }: RemoteAccountInputPropsKui<TFieldValues>) {
  const { control: outerFormControl } = useFormContext()

  const {
    field: { onChange },
  } = useController({
    control: outerFormControl,
    name,
  })

  const [opened, setOpened] = useState(false)

  async function afterSubmit(account: RemoteAccountDumpSchema) {
    onChange(account)
    close()
  }

  const remoteAccountSelectProps = useRemoteAccountSelectProps()

  return (
    <Fragment>
      <CreateRemoteAccountModal
        opened={opened}
        onClose={() => setOpened(false)}
        afterSubmit={afterSubmit}
      />
      <KuiFlex.Item grow={1}>
        <KuiFormSelectInput<
          { remote_account: RemoteAccountDumpSchema },
          'remote_account'
        >
          {...remoteAccountSelectProps}
          name={name as any}
          parseItem={(remoteAccount) => ({
            key: remoteAccount.id,
            label: remoteAccount.name,
          })}
          footerActions={[
            {
              key: 'create',
              iconType: 'plus',
              label: 'Create remote account',
              onClick: () => setOpened(true),
            },
          ]}
          {...props}
        />
      </KuiFlex.Item>
    </Fragment>
  )
}

export function CreateRemoteAccountModal({
  opened,
  onClose,
  afterSubmit,
}: {
  opened: boolean
  onClose: () => void
  afterSubmit: (res: RemoteAccountDumpSchema) => void
}) {
  const form = useForm<RemoteAccountFormValues>({
    defaultValues: { active: true },
  })
  const close = () => {
    form.reset()
    onClose()
  }

  const { watch } = form

  const accountTypeValue = watch('account_type')

  return (
    <KuiModal
      opened={opened}
      onClose={close}
      title='Create new account'
      size='md'
      actions={[
        {
          variant: 'filled',
          label: 'Save',
          // todo don't autoclose this
          onClick: form.handleSubmit(onSubmit),
          loading: form.formState.isSubmitting,
        },
      ]}
    >
      <KuiForm
        form={form}
        onSubmit={onSubmit}
        render={() => (
          <KuiStack gapSize='md'>
            <KuiFormTextInput<RemoteAccountFormValues>
              name='name'
              label='Name'
              required={true}
            />
            <Switch
              label='Active'
              checked={form.watch('active')}
              onChange={(event) =>
                form.setValue('active', event.target.checked)
              }
            />
            <KuiFormTextInput<RemoteAccountFormValues>
              name='account_number'
              label='Account number'
              required={true}
            />
            <Group grow={true}>
              <KuiFormSelectInput<RemoteAccountFormValues, 'account_type'>
                name='account_type'
                label='Account type'
                items={Object.keys(RemoteAccountType) as RemoteAccountType[]}
                parseItem={(item) => ({
                  key: item,
                  label: renderEnumValue(item),
                })}
                required={true}
              />
              <KuiFormSelectInput<RemoteAccountFormValues, 'account_sub_type'>
                name='account_sub_type'
                label='Sub-account type'
                items={
                  Object.keys(AccountingSubAccountType)
                    .filter((key) =>
                      getMoabSubAccountTypeOptions(accountTypeValue).includes(
                        key as AccountingSubAccountType
                      )
                    )
                    .map(
                      (key) => key as AccountingSubAccountType
                    ) as AccountingSubAccountType[]
                }
                parseItem={(item) => ({
                  key: item,
                  label: renderEnumValue(item),
                })}
                disabled={!accountTypeValue}
                required={true}
              />
            </Group>
            <RemoteAccountInputKui<RemoteAccountFormValues>
              name='parent_remote_account'
              label='Parent account'
            />
          </KuiStack>
        )}
      />
    </KuiModal>
  )

  async function onSubmit(data: RemoteAccountFormValues) {
    wrapKuiApiRequest(async () => {
      const res = await remoteAccountCreate(mapPayloadToRequestSchema(data))
      if (res) {
        afterSubmit(res)
        close()
      }
    })()

    function mapPayloadToRequestSchema(
      values: RemoteAccountFormValues
    ): RemoteAccountCreateSchema {
      validateValues(values)

      return {
        ...values,
        parent_remote_id: values.parent_remote_account?.id,
      }
    }

    function validateValues(values: RemoteAccountFormValues) {
      remoteAccounts({ account_number: values.account_number }).then((res) => {
        if (res.elements.length > 0) {
          throw new Error('Account number already exists')
        }
      })
      if (values.parent_remote_account) {
        if (values.parent_remote_account.id === values.id) {
          throw new Error('Parent account cannot be the same as the account')
        }
      }
    }
  }
}
