/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import type {
  SearchRequestSchema,
  SearchResponseSchema
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const searchPost = (
    searchRequestSchema: BodyType<SearchRequestSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SearchResponseSchema>(
      {url: `/search/search`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: searchRequestSchema
    },
      options);
    }


