import { type ComponentType } from 'react'

export type RequireGeneric<A extends any> = [A][A extends any ? 0 : never]

export type NonNullableKeys<
  TObject extends object,
  TNonNullKey extends keyof TObject,
> = {
  [TKey in keyof TObject]: TKey extends TNonNullKey
    ? NonNullable<TObject[TKey]>
    : TObject[TKey]
}

export function assertNonNullKeys<
  TObject extends object,
  TNonNullKey extends keyof TObject,
>(
  obj: TObject,
  keys: TNonNullKey[]
): asserts obj is NonNullableKeys<TObject, TNonNullKey> {
  for (const key of keys) {
    if (obj[key] === null) {
      throw new Error(`Key '${String(key)}' is null`)
    }
  }
}

export function assertNonNullKeysList<
  TObject extends object,
  TNonNullKey extends keyof TObject,
>(
  obj: TObject[],
  keys: TNonNullKey[]
): asserts obj is NonNullableKeys<TObject, TNonNullKey>[] {
  for (const item of obj) {
    assertNonNullKeys(item, keys)
  }
}

export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`)
}

export function removeNullishKeys<
  T extends Record<string, string | null | undefined>,
>(obj: T): Record<string, string> {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => !!value)
  ) as Record<string, string>
}

export type AsProps<AsComponentProps> =
  | {
      as?: undefined
      asProps?: never
    }
  | {
      as?: ComponentType<AsComponentProps> | undefined
      asProps: AsComponentProps
    }
