import { Image, Title } from '@mantine/core'
import { Fragment } from 'react'

import logo from 'assets/logo.svg'
import {
  useKuiMediaQuery,
  KuiPad,
  KuiBreak,
  KuiText,
  KuiButton,
} from 'components/kui'

export function FourZeroFourPage() {
  const isMobile = useKuiMediaQuery({ maxWidth: 'sm' })

  return (
    <Fragment>
      <KuiPad size={isMobile ? 'md' : 'xl'}>
        <Image
          src={logo}
          alt='logo'
          fit='contain'
          maw='100px'
          style={{ aspectRatio: '821/171' }}
        />

        <KuiBreak size='xl' />
        <KuiBreak size='xl' />

        <Title order={1}>Page not found</Title>

        <KuiBreak />

        <KuiText.p>The requested page does not exist</KuiText.p>

        <KuiBreak />

        <KuiButton variant='outline' size='m' to='/'>
          Return home
        </KuiButton>
      </KuiPad>
    </Fragment>
  )
}
