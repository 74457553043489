import { type FetchQueryOptions } from '@tanstack/react-query'
import { Suspense, type ComponentType } from 'react'
import {
  generatePath,
  Navigate,
  useParams,
  type RouteObject,
  type To,
} from 'react-router-dom'

import KyusuLoader from 'components/Kyusu/KyusuLoader/KyusuLoader'
import { RouteErrorBoundary } from 'ErrorBoundary'
import { moabQueryClient } from 'providers/MoabQueryClientProvider'

type ExtractRouteParams<T> = string extends T
  ? Record<string, string>
  : T extends `${infer _Start}:${infer Param}/${infer Rest}`
    ? { [k in Param | keyof ExtractRouteParams<Rest>]: string }
    : T extends `${infer _Start}:${infer Param}`
      ? { [k in Param]: string }
      : null

type CreateRouteParams<TPath extends string> = Pick<
  RouteObject,
  'children' | 'lazy'
> & {
  route: PartialUrlFactoryReturn<TPath>
  prefetch?: (
    params: ExtractRouteParams<TPath>
  ) => Pick<FetchQueryOptions, 'queryKey' | 'queryFn'>[]
  /** @default false */
  fullPage?: boolean
} & (
    | {
        Component: ComponentType<{}>
        redirect?: never
      }
    | {
        redirect: To
        Component?: never
      }
  )

export function createRoute<TPath extends string>(
  params: CreateRouteParams<TPath>
): RouteObject {
  const {
    route,
    prefetch,
    children,
    Component,
    redirect,
    fullPage = false,
  } = params

  return {
    path: route._absolutePath,
    loader: prefetch
      ? ({ params }) => {
          const queryOptions = prefetch(params as any)

          for (const queryOption of queryOptions) {
            moabQueryClient.prefetchQuery(queryOption)
          }

          return null
        }
      : undefined,
    element: Component ? (
      // todo fix loader positioning
      <Suspense fallback={<KyusuLoader />}>
        <Component />
      </Suspense>
    ) : (
      <Navigate to={redirect} />
    ),
    errorElement: <RouteErrorBoundary fullPage={fullPage} />,
    children,
  }
}

type UrlFactoryReturn<
  TParentPath extends string,
  TPath extends string,
  TChildUrls extends Record<string, any>,
> = (ExtractRouteParams<`${TParentPath}${TPath}`> extends {}
  ? {
      _relativePath: TPath
      _absolutePath: `${TParentPath}${TPath}`
      buildUrl: (
        params: ExtractRouteParams<`${TParentPath}${TPath}`>
      ) => `${TParentPath}${TPath}`
    }
  : {
      _relativePath: TPath
      _absolutePath: `${TParentPath}${TPath}`
      buildUrl: () => `${TParentPath}${TPath}`
    }) & {
  [K in keyof TChildUrls]: TChildUrls[K] extends UrlFactoryReturn<
    infer _ChildParentPath,
    infer ChildPath,
    infer ChildUrls
  >
    ? UrlFactoryReturn<`${TParentPath}${TPath}`, ChildPath, ChildUrls>
    : never
}

export function urlFactory<
  TPath extends string,
  TChildUrls extends Record<string, any>,
  TParentPath extends string = '',
>(
  path: TPath,
  children: TChildUrls,
  { parentPath }: { parentPath?: TParentPath } = {}
): UrlFactoryReturn<TParentPath, TPath, TChildUrls> {
  const resolvedChildren = Object.fromEntries(
    Object.entries(children ?? {}).map(([key, child]) => [
      key,
      urlFactory(child._relativePath, child._children, {
        parentPath: `${parentPath ?? ''}${path}`,
      }),
    ])
  )

  const absolutePath = `${parentPath ?? ''}${path}`

  return {
    _children: resolvedChildren,
    _relativePath: path,
    _absolutePath: absolutePath,
    buildUrl: (params?: ExtractRouteParams<`${TParentPath}${TPath}`>) => {
      return generatePath(absolutePath, params as any)
    },
    ...resolvedChildren,
  } as UrlFactoryReturn<TParentPath, TPath, TChildUrls>
}

type PartialUrlFactoryReturn<TPath extends string> = { _absolutePath: TPath }

export function useRouteParams<TPath extends string>(
  _route: PartialUrlFactoryReturn<TPath>
): { [TKey in keyof ExtractRouteParams<TPath>]: string } {
  return useParams<{
    [TKey in keyof ExtractRouteParams<TPath>]: string
  }>() as any
}
