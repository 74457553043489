import { Tooltip as MantineTooltip } from '@mantine/core'
import { type ReactNode } from 'react'

type KuiTooltipProps = {
  content: ReactNode
  fullWidth?: boolean
  children?: ReactNode
}

export function KuiTooltip({ content, fullWidth, children }: KuiTooltipProps) {
  if (!content) {
    return children
  }

  return (
    <MantineTooltip label={content}>
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          height: 'fit-content',
          width: fullWidth ? '100%' : undefined,
        }}
      >
        {children}
      </span>
    </MantineTooltip>
  )
}
