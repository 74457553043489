import {
  Loader,
  type MenuProps as MantineMenuProps,
  Menu as MantineMenu,
  type FloatingPosition,
  type MenuItemProps as MantineMenuItemProps,
} from '@mantine/core'
import { Fragment, type ReactNode, useState } from 'react'

import { KuiButton, type KuiButtonProps } from './KuiButton'
import { KuiIcon, type KuiIconType } from './KuiIcon/KuiIcon'
import { getAsLinkMantineProps, type MaybeLinkProps } from './KuiLink'
import { wrapKuiApiRequest } from './wrapKuiApiRequest'

type KuiMenuItemColor = 'default' | 'destructive'

const colorMap: Record<KuiMenuItemColor, MantineMenuItemProps['color']> = {
  default: 'dark',
  destructive: 'red',
}

export type KuiMenuItem = MaybeLinkProps & {
  iconType?: KuiIconType
  label: string

  onClick?: () => unknown | Promise<unknown>

  color?: KuiMenuItemColor
  disabled?: boolean
  dividerBefore?: boolean
}

type KuiMenuWidth = 's' | 'target'

const widthMap: Record<KuiMenuWidth, MantineMenuProps['width']> = {
  s: 220,
  target: 'target',
}

export type KuiMenuProps = {
  items: KuiMenuItem[]
  buttonProps?: Omit<KuiButtonProps, 'to' | 'onClick'>
  position?: FloatingPosition

  width?: KuiMenuWidth
  _target?: ReactNode
}

export function KuiMenu({
  items,
  buttonProps,
  position = 'bottom-start',
  width = 's',
  _target,
}: KuiMenuProps) {
  const [loadingItemKeys, setLoadingItemKeys] = useState<string[]>([])

  return (
    <MantineMenu
      shadow='md'
      width={widthMap[width]}
      position={position}
      opened={loadingItemKeys.length > 0 ? true : undefined}
    >
      <MantineMenu.Target>
        {_target ?? <KuiButton iconType='dots-vertical' {...buttonProps} />}
      </MantineMenu.Target>

      <MantineMenu.Dropdown>
        {items.map((item, index) => {
          const loading = loadingItemKeys.includes(item.label)

          return (
            <Fragment key={index}>
              {item.dividerBefore && <MantineMenu.Divider />}

              <MantineMenu.Item
                key={index}
                disabled={item.disabled || loading}
                color={colorMap[item.color ?? 'default']}
                leftSection={
                  item.iconType ? <KuiIcon type={item.iconType} /> : undefined
                }
                rightSection={
                  loading ? <Loader size={16} color='dark' /> : undefined
                }
                onClick={getWrappedOnClick(item)}
                {...getAsLinkMantineProps({ to: item.to, state: item.state })}
              >
                {item.label}
              </MantineMenu.Item>
            </Fragment>
          )
        })}
      </MantineMenu.Dropdown>
    </MantineMenu>
  )

  function getWrappedOnClick(item: KuiMenuItem) {
    const consumerOnClick = item.onClick

    if (!consumerOnClick) {
      return
    }

    return async function () {
      const maybePromise = consumerOnClick()

      if (maybePromise instanceof Promise) {
        try {
          setLoadingItemKeys((prev) => [...prev, item.label])
          await wrapKuiApiRequest(() => Promise.resolve(maybePromise))()
        } finally {
          setLoadingItemKeys((prev) => prev.filter((key) => key !== item.label))
        }
      }
    }
  }
}
