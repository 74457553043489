import { KuiActionList } from 'components/kui/KuiActionList'
import { KuiFlex } from 'components/kui/KuiFlex'
import { KuiText } from 'components/kui/KuiText'
import { isLocalDev } from 'utils/helpers'

export type KuiPaginationProps = {
  currentPage?: number
  totalRows?: number
  pageSize: number
  hasPrev: boolean
  onPrev: () => void
  hasNext: boolean
  onNext: () => void
}
export function KuiPagination({
  currentPage,
  totalRows,
  pageSize,
  hasPrev,
  onPrev,
  hasNext,
  onNext,
}: KuiPaginationProps) {
  const currentPageRange = getCurrentPageRange()

  return (
    <KuiFlex justifyContent='spaceBetween' gapSize='xs'>
      {currentPageRange ? (
        <KuiText.p size='xs' color='hushed'>
          {currentPageRange} of {totalRows}
        </KuiText.p>
      ) : (
        <div />
      )}

      <KuiActionList
        size='xs'
        gapSize='xs'
        variant='outline'
        actions={[
          {
            iconType: 'chevron-left',
            disabled: !hasPrev,
            onClick: onPrev,
          },
          {
            iconType: 'chevron-right',
            disabled: !hasNext,
            onClick: onNext,
          },
        ]}
      />
    </KuiFlex>
  )

  function getCurrentPageRange() {
    if (!isLocalDev) {
      // these don't work atm
      return null
    }

    if (
      totalRows === 0 ||
      currentPage === undefined ||
      totalRows === undefined
    ) {
      return null
    }

    const start = currentPage * pageSize + 1
    const end = Math.min((currentPage + 1) * pageSize, totalRows)

    return `${start}-${end}`
  }
}
