import { type ReactNode } from 'react'

import { KuiFlex, type KuiFlexGapSize } from './KuiFlex'

type KuiStackProps = {
  gapSize: KuiFlexGapSize
  children?: ReactNode
}

export function KuiStack({ children, ...restProps }: KuiStackProps) {
  return (
    <KuiFlex direction='column' alignItems='stretch' {...restProps}>
      {children}
    </KuiFlex>
  )
}
