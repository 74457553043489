import { lazily } from 'react-lazily'

import { getGetTermsAndConditionsAddendumQueryOptions } from '_autogenerated'
import { communicationSettingsUrls } from 'routes/Settings/settingsUrls'
import { createRoute } from 'routing'

const {
  CommunicationSettingsRoute,
  CommunicationBrandingSettingsPage,
  CommunicationEventsSettingsPage,
  DocumentTemplateEditPage,
  DocumentTemplatesSettingsPage,
  TermsAndConditionsEditPage,
  EmailTemplateCreatePage,
  EmailTemplateEditPage,
  EmailTemplatesSettingsPage,
  LegalDocumentsSettingsPage,
  TermsAndConditionsAddendumCreatePage,
  TermsAndConditionsAddendumEditPage,
} = lazily(() => import('./components'))

export const communicationSettingsRoutes = [
  createRoute({
    route: communicationSettingsUrls,
    Component: CommunicationSettingsRoute,
    children: [
      createRoute({
        route: communicationSettingsUrls,
        redirect: communicationSettingsUrls.messageTemplates.buildUrl(),
      }),
      createRoute({
        route: communicationSettingsUrls.messageTemplates,
        Component: EmailTemplatesSettingsPage,
      }),
      createRoute({
        route: communicationSettingsUrls.events,
        Component: CommunicationEventsSettingsPage,
      }),
      createRoute({
        route: communicationSettingsUrls.documentTemplates,
        Component: DocumentTemplatesSettingsPage,
      }),
      createRoute({
        route: communicationSettingsUrls.legalDocuments,
        Component: LegalDocumentsSettingsPage,
      }),
      createRoute({
        route: communicationSettingsUrls.branding,
        Component: CommunicationBrandingSettingsPage,
      }),
    ],
  }),
  createRoute({
    route: communicationSettingsUrls.messageTemplates.create,
    Component: EmailTemplateCreatePage,
  }),
  createRoute({
    route: communicationSettingsUrls.messageTemplates.edit,
    Component: EmailTemplateEditPage,
  }),
  createRoute({
    route: communicationSettingsUrls.documentTemplates,
    Component: DocumentTemplatesSettingsPage,
  }),
  createRoute({
    route: communicationSettingsUrls.documentTemplates.edit,
    Component: DocumentTemplateEditPage,
  }),
  createRoute({
    route: communicationSettingsUrls.legalDocuments.businessTermsAndConditions,
    Component: TermsAndConditionsEditPage,
  }),
  createRoute({
    route: communicationSettingsUrls.legalDocuments.addendums.create,
    Component: TermsAndConditionsAddendumCreatePage,
  }),
  createRoute({
    route: communicationSettingsUrls.legalDocuments.addendums.edit,
    Component: TermsAndConditionsAddendumEditPage,
    prefetch: ({ id }) => [getGetTermsAndConditionsAddendumQueryOptions(id)],
  }),
]
