import { lazily } from 'react-lazily'

import {
  getAccountMappingsQueryOptions,
  getGetAccountingAccessQueryOptions,
  getGetStripeActiveQueryOptions,
  getListPaymentSurchargesQueryOptions,
} from '_autogenerated'
import { integrationSettingsUrls } from 'routes/Settings/settingsUrls'
import { createRoute } from 'routing'

const {
  IntegrationSettingsRoute,
  PaymentConfigurationSettingsPage,
  AccountingSettingsRoot,
  AccountingOnboarding,
  AccountMappingsSettingsPage,
  AccountingInventoryProfilesListPage,
  AccountingInventoryProfileCreatePage,
  AccountingInventoryProfileDetailPage,
  AccountingInventoryProfileEditPage,
} = lazily(() => import('./components'))

export const integrationSettingsRoutes = [
  createRoute({
    route: integrationSettingsUrls,
    Component: IntegrationSettingsRoute,
    children: [
      createRoute({
        route: integrationSettingsUrls,
        redirect:
          integrationSettingsUrls.accounting.defaultAccountMappings.buildUrl(),
      }),
      createRoute({
        route: integrationSettingsUrls.accounting,
        Component: AccountingSettingsRoot,
        prefetch: () => [getGetAccountingAccessQueryOptions()],
        children: [
          createRoute({
            route: integrationSettingsUrls.accounting.defaultAccountMappings,
            Component: AccountMappingsSettingsPage,
            prefetch: () => [getAccountMappingsQueryOptions()],
          }),
          createRoute({
            route: integrationSettingsUrls.accounting.inventoryProfiles,
            Component: AccountingInventoryProfilesListPage,
          }),
        ],
      }),
      createRoute({
        route: integrationSettingsUrls.accounting.onboard,
        Component: AccountingOnboarding,
      }),
      createRoute({
        route: integrationSettingsUrls.accounting.inventoryProfiles.create,
        Component: AccountingInventoryProfileCreatePage,
      }),
      createRoute({
        route: integrationSettingsUrls.accounting.inventoryProfiles.detail,
        Component: AccountingInventoryProfileDetailPage,
      }),
      createRoute({
        route: integrationSettingsUrls.accounting.inventoryProfiles.edit,
        Component: AccountingInventoryProfileEditPage,
      }),
      createRoute({
        route: integrationSettingsUrls.payments,
        Component: PaymentConfigurationSettingsPage,
        prefetch: () => [
          getGetStripeActiveQueryOptions(),
          getListPaymentSurchargesQueryOptions(),
        ],
      }),
    ],
  }),
]
