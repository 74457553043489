// Adapted from https://platejs.org/docs/components/inline-combobox
import { Pill as MantinePill } from '@mantine/core'
import {
  useComboboxInput,
  useHTMLInputCursorState,
  withTriggerCombobox,
  type CancelComboboxInputCause,
  type TriggerComboboxPlugin,
} from '@udecode/plate-combobox'
import {
  PlateElement,
  createPluginFactory,
  createPointRef,
  findNodePath,
  getPointBefore,
  insertNodes,
  insertText,
  moveSelection,
  useElement,
  withRef,
  type PlateEditor,
  type TElement,
  type Value,
} from '@udecode/plate-common'
import classNames from 'classnames'
import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  type DetailedHTMLProps,
  type InputHTMLAttributes,
} from 'react'
import { type PointRef } from 'slate'
import { useFocused, useSelected } from 'slate-react'
import styled from 'styled-components'

import { type KuiComboboxOptionsDropdownProps } from 'components/kui/_internal/KuiComboboxOptionsDropdown'
import { KuiSelect, type KuiSelectProps } from 'components/kui/inputs'

import { prettifyVariableName } from './utils'

export interface SelectElement<TValue> extends TElement {
  value: TValue
}

type SelectPluginKey = 'variable' | 'mention'

type CreateSelectPluginParams<TItem, TValue> = {
  key: SelectPluginKey
  trigger: string
  inputPlaceholder: string
  parseItem: KuiComboboxOptionsDropdownProps<TItem>['parseItem']
  getItemValue: (item: TItem) => TValue
  enabled?: boolean
}

export function createKuiRichTextSelectPlugin<TItem, TValue>({
  key,
  trigger,
  inputPlaceholder,
  parseItem,
  getItemValue,
  enabled,
}: CreateSelectPluginParams<TItem, TValue>) {
  const inputKey = `${key}_input`

  const plugin = createPluginFactory<TriggerComboboxPlugin>({
    isElement: true,
    isInline: true,
    isMarkableVoid: true,
    isVoid: true,
    key,
    options: {
      createComboboxInput: (trigger) => ({
        children: [{ text: '' }],
        trigger,
        type: inputKey,
      }),
      trigger,
      triggerPreviousCharPattern: /^\s?$/,
    },
    plugins: [
      {
        isElement: true,
        isInline: true,
        isVoid: true,
        key: inputKey,
      },
    ],
    withOverrides: withTriggerCombobox,
    enabled,
  })()

  return {
    plugin,
    components: {
      [key]: createSelectedPillElement({ key }),
      [inputKey]: createSelectInputElement({
        key,
        trigger,
        inputPlaceholder,
        parseItem,
        onSelectItem,
      }),
    },
  }

  function onSelectItem({
    editor,
    item,
  }: {
    editor: PlateEditor<Value>
    item: TItem
  }) {
    insertNodes(editor, [
      {
        children: [{ text: '' }],
        type: key,
        value: getItemValue(item),
      },
      { text: ' ' },
    ])

    // move the selection after the element
    moveSelection(editor, { unit: 'offset' })
  }
}

const SelectElementRoot = styled(PlateElement)`
  display: inline-block;
  height: var(--variable-pill-height);
`

const SelectElementPill = styled(MantinePill)`
  height: var(--variable-pill-height);

  &.SelectElementPill--selected {
    outline: 2px solid var(--mantine-primary-color-filled);
  }
`

function createSelectedPillElement<TValue>({ key }: { key: SelectPluginKey }) {
  return withRef<typeof PlateElement>(({ children, ...props }, ref) => {
    const { resolvedVariables } = useContext(KuiRichTextSelectContext)!

    const element = useElement<SelectElement<TValue>>()
    const selected = useSelected()
    const focused = useFocused()

    return (
      <SelectElementRoot
        contentEditable={false}
        data-slate-value={element.value}
        ref={ref}
        {...props}
      >
        {key === 'mention' ? (
          <strong>{getPillValue()}</strong>
        ) : (
          <SelectElementPill
            className={classNames({
              'SelectElementPill--selected': selected && focused,
            })}
          >
            {getPillValue()}
          </SelectElementPill>
        )}
        {children}
      </SelectElementRoot>
    )

    function getPillValue() {
      if (key === 'variable') {
        const variable = element.value as string

        return resolvedVariables?.[variable] ?? prettifyVariableName(variable)
      }

      if (key === 'mention') {
        // @ts-ignore
        return `@${element.value.name}`
      }

      return null
    }
  })
}

export type KuiRichTextSelectComboboxProps<TItem> = Pick<
  KuiComboboxOptionsDropdownProps<TItem>,
  'items' | 'loading'
>

export type KuiRichTextSelectContextValue<TItem> = {
  variableSelectProps?: Pick<
    KuiSelectProps<TItem>,
    'items' | 'loading' | 'onSearchDebounced'
  >
  mentionSelectProps?: Pick<
    KuiSelectProps<TItem>,
    'items' | 'loading' | 'onSearchDebounced'
  >
  resolvedVariables?: Record<string, string>
}

export const KuiRichTextSelectContext = createContext<
  KuiRichTextSelectContextValue<any> | undefined
>(undefined)

type CreateSelectInputElementParams<TItem> = {
  key: string
  trigger: string
  inputPlaceholder: string
  parseItem: KuiComboboxOptionsDropdownProps<TItem>['parseItem']
  onSelectItem: (_: { editor: PlateEditor<Value>; item: TItem }) => void
}

function createSelectInputElement<TItem>({
  key,
  trigger,
  inputPlaceholder,
  parseItem,
  onSelectItem,
}: CreateSelectInputElementParams<TItem>) {
  return withRef<typeof PlateElement>((props, ref) => {
    const { variableSelectProps, mentionSelectProps } = useContext(
      KuiRichTextSelectContext
    )!

    const selectProps =
      key === 'variable' ? variableSelectProps! : mentionSelectProps!

    const { children, editor, element } = props
    const [search, setSearch] = useState('')

    const inputRef = useRef<HTMLInputElement>(null)
    const cursorState = useHTMLInputCursorState(inputRef)
    const insertPoint = useInsertPoint({ editor, element })

    const { props: inputProps, removeInput } = useComboboxInput({
      cancelInputOnBlur: true,
      cursorState,
      onCancelInput,
      ref: inputRef,
    })

    return (
      <PlateElement
        as='span'
        data-slate-value={element.value}
        ref={ref}
        {...props}
      >
        <InlineSelect
          inputProps={{
            ref: inputRef,
            placeholder: inputPlaceholder,
            ...inputProps,
          }}
          selectProps={{
            parseItem,
            ...selectProps,
          }}
          onSearch={setSearch}
          onChange={(item) => {
            removeInput(true)

            onSelectItem({ editor, item })
          }}
        />

        {children}
      </PlateElement>
    )

    function onCancelInput(cause: CancelComboboxInputCause) {
      selectProps.onSearchDebounced?.('')

      if (cause !== 'backspace' && cause !== 'blur') {
        insertText(editor, trigger + search, {
          at: insertPoint?.current ?? undefined,
        })
      }

      if (cause === 'arrowLeft' || cause === 'arrowRight') {
        moveSelection(editor, {
          distance: 1,
          reverse: cause === 'arrowLeft',
        })
      }
    }
  })
}

function useInsertPoint({
  editor,
  element,
}: {
  editor: PlateEditor<Value>
  element: TElement
}) {
  /**
   * Track the point just before the input element so we know where to
   * insertText if the combobox closes due to a selection change.
   */
  const [insertPoint, setInsertPoint] = useState<PointRef | null>(null)

  useEffect(() => {
    const path = findNodePath(editor, element)

    if (!path) {
      return
    }

    const point = getPointBefore(editor, path)

    if (!point) {
      return
    }

    const pointRef = createPointRef(editor, point)
    setInsertPoint(pointRef)

    return () => {
      pointRef.unref()
    }
  }, [editor, element])

  return insertPoint
}

const InlineInput = styled.input`
  appearance: none;
  background-color: transparent;
  min-width: 150px;
  flex: 1;
  border: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  line-height: var(--mantine-line-height);

  /** Set 16px font size on ios to prevent zoom on focus  https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom */
  @supports (-webkit-touch-callout: none) {
    font-size: 1rem;
  }

  &:focus {
    outline: none;
  }
`

type InlineSelectProps<TItemSingle> = {
  inputProps: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
  selectProps: Pick<
    KuiSelectProps<TItemSingle>,
    'items' | 'loading' | 'onSearchDebounced' | 'parseItem'
  >
  onSearch: (search: string) => void
  onChange: (item: TItemSingle) => void
}

function InlineSelect<TItemSingle>({
  inputProps,
  selectProps,
  onSearch,
  onChange,
}: InlineSelectProps<TItemSingle>) {
  return (
    <span contentEditable={false}>
      <KuiSelect
        value={null}
        searchable={false}
        _opened={true}
        {...selectProps}
        renderTarget={({ search, setSearch }) => (
          <SelectElementPill>
            <InlineInput
              {...inputProps}
              value={search ?? ''}
              onChange={(event) => {
                setSearch(event.target.value)
                onSearch(event.target.value)
              }}
            />
          </SelectElementPill>
        )}
        onChange={(item) => onChange(item)}
        _onDropdownClose={() => selectProps.onSearchDebounced?.('')}
      />
    </span>
  )
}
