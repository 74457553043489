import { lazily } from 'react-lazily'

import { getGetCategoryQueryOptions } from '_autogenerated'
import { categoryUrls } from 'routes/urls'
import { createRoute } from 'routing'

const {
  CategoryListPage,
  CategoryCreatePage,
  CategoryDetailPageRoot,
  CategoryDetailPageDetailsTab,
  CategoryDetailPageClassesTab,
  CategoryEditPage,
} = lazily(() => import('./components'))

export const categoryRoutes = [
  createRoute({
    route: categoryUrls,
    Component: CategoryListPage,
  }),
  createRoute({
    route: categoryUrls.create,
    Component: CategoryCreatePage,
  }),
  createRoute({
    route: categoryUrls.detail,
    Component: CategoryDetailPageRoot,
    prefetch: ({ id }) => [getGetCategoryQueryOptions(id)],
    children: [
      createRoute({
        route: categoryUrls.detail,
        Component: CategoryDetailPageDetailsTab,
      }),
      createRoute({
        route: categoryUrls.detail.klasses,
        Component: CategoryDetailPageClassesTab,
      }),
    ],
  }),
  createRoute({
    route: categoryUrls.edit,
    Component: CategoryEditPage,
    prefetch: ({ id }) => [getGetCategoryQueryOptions(id)],
  }),
]
