import { type ReactNode } from 'react'

import { KuiTooltip } from 'components/kui/KuiTooltip'

import { iconsMap } from './icons'

export type KuiIconType = keyof typeof iconsMap

type KuiIconSize = 's' | 'm'

export const kuiIconSizeMap: Record<KuiIconSize, number> = {
  s: 16,
  m: 20,
}

type KuiIconColor = 'inherit' | 'text' | 'hushed'

const colorMap: Record<KuiIconColor, string> = {
  inherit: 'currentColor',
  text: 'var(--mantine-color-text)',
  hushed: 'var(--mantine-color-dark-2)',
}

type KuiIconProps = {
  type: KuiIconType

  /** @default 's' */
  size?: KuiIconSize

  /** @default 'inherit' */
  color?: KuiIconColor

  tooltipContent?: ReactNode
}

export function KuiIcon({
  type,
  size = 's',
  color = 'inherit',
  tooltipContent,
}: KuiIconProps) {
  const IconComponent = iconsMap[type]

  const icon = (
    <IconComponent
      style={{ width: kuiIconSizeMap[size], height: kuiIconSizeMap[size] }}
      color={colorMap[color]}
    />
  )

  if (tooltipContent) {
    return <KuiTooltip content={tooltipContent}>{icon}</KuiTooltip>
  }

  return icon
}
