import {
  TimeInput as MantineTimeInput,
  type TimeInputProps as MantineTimeInputProps,
} from '@mantine/dates'
import { type RefCallBack } from 'react-hook-form'

export type KuiTimeInputProps = Pick<
  MantineTimeInputProps,
  | 'label'
  | 'description'
  | 'placeholder'
  | 'error'
  | 'disabled'
  | 'withAsterisk'
> & {
  value: string | null
  onChange: (value: string) => void

  inputRef?: RefCallBack

  fullWidth?: boolean
}

export function KuiTimeInput({
  inputRef,
  value,
  onChange,
  ...restProps
}: KuiTimeInputProps) {
  return (
    <MantineTimeInput
      ref={inputRef}
      value={value ?? ''}
      {...restProps}
      onChange={(event) => onChange(event.target.value)}
      w='100%'
    />
  )
}
