import { Fragment, type ReactNode } from 'react'

import { KuiTextAs, type KuiTextAsProps } from './KuiText'

export type KuiTextBitsProps = KuiTextAsProps & {
  bits: ReactNode[]

  /** @default • */
  separator?: string
}

export function KuiTextBits({
  as = 'div',
  bits,
  separator = '•',
  ...textProps
}: KuiTextBitsProps) {
  return (
    <KuiTextAs as={as} {...textProps}>
      {bits.filter(Boolean).map((bit, index) => (
        <Fragment key={index}>
          {index !== 0 && ` ${separator} `}
          {bit}
        </Fragment>
      ))}
    </KuiTextAs>
  )
}
