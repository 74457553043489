import classnames from 'classnames'
import { type Key, type ReactNode } from 'react'
import styled from 'styled-components'

import { kuiThemeVars, type KuiThemeManueverSize } from './_internal/theme'
import { KuiIcon, type KuiIconType } from './KuiIcon/KuiIcon'
import { KuiLink, type KuiLinkProps } from './KuiLink'
import { KuiText, type KuiTextProps } from './KuiText'

// Styles adapted from https://github.com/radix-ui/themes/blob/main/packages/radix-ui-themes/src/components/data-list.css

type KuiDataListGapSize = 'none' | 'xs' | 's'

const gapSizeMap: Record<KuiDataListGapSize, KuiThemeManueverSize> = {
  none: 0,
  xs: 1,
  s: 2,
}

type KuiDataListRootProps = {
  $gapSize: KuiThemeManueverSize
}

const KuiDataListRoot = styled.dl<KuiDataListRootProps>`
  &.KuiDataList--vertical {
    display: flex;
    flex-direction: column;
    gap: ${kuiThemeVars.spacingSizes.sm};
  }

  &.KuiDataList--horizontal {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: ${(p) => kuiThemeVars.manueverSizes[p.$gapSize]};
  }
`

type KuiDataListItemProps = {
  $gapSize: KuiThemeManueverSize
  $labelMinWidth: string
  $valueMinWidth?: string
  $alignItems: 'flex-start' | 'center'
}

const KuiDataListItem = styled.span<KuiDataListItemProps>`
  &.KuiDataListItem--horizontal {
    display: grid;
    grid-template-columns: inherit;
    grid-template-columns: subgrid;
    gap: ${kuiThemeVars.spacingSizes.xl};
    grid-column: span 2;
    align-items: ${(p) => p.$alignItems};
  }

  &.KuiDataListItem--vertical {
    display: flex;
    flex-direction: column;
    gap: ${(p) => kuiThemeVars.manueverSizes[p.$gapSize]};
  }

  dt {
    display: flex;
    align-items: center;
    gap: ${kuiThemeVars.spacingSizes.sm};
    min-width: ${(p) => p.$labelMinWidth};
  }

  dd {
    min-width: ${(p) => p.$valueMinWidth};
  }

  &.KuiDataListItem--dividerBefore {
    border-top: 1px solid var(--mantine-color-gray-3);
    margin-top: ${kuiThemeVars.manueverSizes[2]};
    padding-top: calc(
      ${(p) => kuiThemeVars.manueverSizes[p.$gapSize]} +
        ${kuiThemeVars.manueverSizes[2]}
    );
  }
`

export type KuiDataListItem = {
  key?: Key
  label: string
  value: ReactNode

  iconType?: KuiIconType

  textProps?: KuiTextProps
  labelTextProps?: KuiTextProps
  labelLinkProps?: Pick<KuiLinkProps, 'to' | 'onClick'>
  valueTextProps?: KuiTextProps

  /** @default true */
  visible?: boolean

  dividerBefore?: boolean

  _horizontalAlignItems?: 'flex-start' | 'center'
}

type KuiDataListOrientation = 'horizontal' | 'vertical'

type KuiDataListProps = {
  /** @default 'horizontal' */
  orientation?: KuiDataListOrientation

  /** @default 'sm' */
  fontSize?: KuiTextProps['size']

  textProps?: KuiTextProps
  labelTextProps?: KuiTextProps
  valueTextProps?: KuiTextProps

  /** @default '150px' */
  labelMinWidth?: string

  valueMinWidth?: string

  items: KuiDataListItem[]

  /** @default 's' */
  gapSize?: KuiDataListGapSize
}

export function KuiDataList({
  orientation = 'horizontal',
  textProps,
  labelTextProps,
  valueTextProps,
  items,
  gapSize = 's',
  labelMinWidth = '150px',
  valueMinWidth = undefined,
}: KuiDataListProps) {
  return (
    <KuiDataListRoot
      $gapSize={gapSizeMap[gapSize]}
      className={classnames({
        'KuiDataList--vertical': orientation === 'vertical',
        'KuiDataList--horizontal': orientation === 'horizontal',
      })}
    >
      {items
        .filter((item) => item.visible !== false)
        .map((item) => (
          <KuiDataListItem
            key={item.key ?? item.label}
            $gapSize={gapSizeMap[gapSize]}
            $labelMinWidth={labelMinWidth}
            $valueMinWidth={valueMinWidth}
            $alignItems={item._horizontalAlignItems ?? 'flex-start'}
            className={classnames({
              'KuiDataListItem--vertical': orientation === 'vertical',
              'KuiDataListItem--horizontal': orientation === 'horizontal',
              'KuiDataListItem--dividerBefore': item.dividerBefore === true,
            })}
          >
            <KuiText.dt
              size='sm'
              color='hushed'
              {...textProps}
              {...labelTextProps}
              {...item.textProps}
              {...item.labelTextProps}
            >
              {item.iconType && (
                <KuiIcon size='s' type={item.iconType} color='hushed' />
              )}

              {item.labelLinkProps ? (
                <KuiLink {...item.labelLinkProps}>{item.label}</KuiLink>
              ) : (
                item.label
              )}
            </KuiText.dt>
            <KuiText.dd
              size='sm'
              truncate={orientation === 'horizontal'}
              {...textProps}
              {...valueTextProps}
              {...item.textProps}
              {...item.valueTextProps}
            >
              {item.value || item.value === 0 ? (
                item.value
              ) : (
                <KuiText.span color='hushed'>—</KuiText.span>
              )}
            </KuiText.dd>
          </KuiDataListItem>
        ))}
    </KuiDataListRoot>
  )
}
