import { useMediaQuery } from '@mantine/hooks'

import { kuiThemeVars, type KuiThemeBreakpoint } from './_internal/theme'

export function useKuiMediaQuery({
  maxWidth,
}: {
  maxWidth: KuiThemeBreakpoint
}) {
  return useMediaQuery(`(max-width: ${kuiThemeVars.breakpoints[maxWidth]})`)
}
