import { lazily } from 'react-lazily'

import { reportingUrls } from 'routes/urls'
import { createRoute } from 'routing'

const { ReportingPage } = lazily(() => import('./ReportingPage'))

export const reportingRoutes = [
  createRoute({
    route: reportingUrls,
    Component: ReportingPage,
  }),
]
