// custom-instance.ts
import Axios, { type AxiosError, type AxiosRequestConfig } from 'axios'

const AXIOS_INSTANCE = Axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  withCredentials: true,
}) // use your own URL here or environment variable

export const kyusuAxiosMutator = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data)

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export interface ErrorType<Error> extends AxiosError<Error> {}
// export interface BodyType<BodyData> extends BodyData {}
// export type ErrorType<Error> = AxiosError<Error>;
export type BodyType<BodyData> = BodyData

// Or, in case you want to wrap the body type (optional)
// (if the custom instance is processing data before sending it, like changing the case for example)
//  export type BodyType<BodyData> = CamelCase<BodyData>;
