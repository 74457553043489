import {
  Textarea as MantineTextarea,
  type TextareaProps as MantineTextareaProps,
} from '@mantine/core'
import {
  useController,
  useFormContext,
  type ControllerRenderProps,
  type FieldPath,
  type FieldValues,
  type UseControllerProps,
} from 'react-hook-form'

import { type RequireGeneric } from 'utils/types'

import { getKuiFormFieldError } from './utils'
import { kuiRequiredValidator } from './validators'

type KuiFormTextareaProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = Pick<
  UseControllerProps<RequireGeneric<TFieldValues>>,
  'name' | 'rules' | 'disabled'
> &
  Omit<MantineTextareaProps, keyof ControllerRenderProps<TFieldValues, TName>>

export function KuiFormTextarea<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = never,
>({
  name,
  rules,
  required = false,
  label,
  placeholder = typeof label === 'string'
    ? `Enter ${label.toLowerCase()}`
    : undefined,
  ...restProps
}: KuiFormTextareaProps<TFieldValues, TName>) {
  const formContext = useFormContext<TFieldValues>()

  const {
    field: { value, onChange, ...field },
    fieldState,
  } = useController<TFieldValues>({
    name,
    rules: { ...rules, required: kuiRequiredValidator(required) },
  })

  return (
    <MantineTextarea
      {...field}
      {...restProps}
      value={value ?? ''}
      label={label}
      placeholder={placeholder}
      withAsterisk={required}
      error={getKuiFormFieldError({ fieldState })}
      autoComplete='off'
      data-1p-ignore={true}
      onChange={(event) => {
        formContext.clearErrors(name)

        onChange(event.target.value)
      }}
    />
  )
}
