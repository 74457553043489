import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { orderBy } from 'lodash-es'
import { useMemo, useState } from 'react'

import { searchPost, type SearchRequestSchema } from '_autogenerated'

import { type KuiQueryOptions } from './types'

export function useGlobalSearchSelectProps(
  params: Omit<SearchRequestSchema, 'search'>,
  {
    allowEmptySearch,
    ...options
  }: KuiQueryOptions & { allowEmptySearch: boolean }
) {
  const [search, setSearch] = useState('')

  const queryResult = useQuery({
    ...options,
    enabled:
      (search.length > 0 || allowEmptySearch) && params.objects?.length
        ? options?.enabled
        : false,
    queryKey: ['searchPost', { search, ...params }],
    queryFn: () => searchPost({ search, ...params }),
    placeholderData: keepPreviousData,
  })

  return useMemo(
    () => ({
      items:
        search.length > 0 || allowEmptySearch
          ? orderBy(
              queryResult.data?.elements ?? [],
              (item) => item.rank,
              'desc'
            )
          : [],
      loading: queryResult.isLoading,
      onSearchDebounced: setSearch,
      filterOptions: false,
    }),
    [
      allowEmptySearch,
      queryResult.data?.elements,
      queryResult.isLoading,
      search.length,
    ]
  )
}
