/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  ArcGISToken,
  DeliveryConfigurationCalculationRequestSchema,
  DeliveryConfigurationCalculationResponseSchema,
  DeliveryConfigurationCreateSchema,
  DeliveryConfigurationDumpSchema,
  DeliveryConfigurationUpdateSchema,
  DeliveryConfigurationsParams,
  DeliveryRouteCreateSchema,
  DeliveryRouteHydratedDumpSchema,
  DeliveryRouteInsertSchema,
  DeliveryRouteStopsDumpSchema,
  DeliveryRouteUpdateSchema,
  DeliveryRoutesDumpSchema,
  DeliveryRoutesUpdateStatusSchema,
  DeliveryStopsUpdateStatusSchema,
  DistanceRequestSchema,
  DistanceResponseSchema,
  ListDeliveryDriversParams,
  ListDeliveryRequestsParams,
  ListDeliveryRoutesParams,
  ListDeliveryTrucksParams,
  LoadRequestPatchSchema,
  LoadRequestsDumpSchema,
  PaginatedDeliveryConfigurationsDumpSchema,
  PaginatedDeliveryDriversDumpSchema,
  PaginatedDeliveryRequestSchema,
  PaginatedDeliveryRouteSchema,
  PaginatedDeliveryTrucksDumpSchema,
  RouteBody,
  RouteResponse,
  SuccessResponse
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const deliveryConfigurations = (
    params?: DeliveryConfigurationsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedDeliveryConfigurationsDumpSchema>(
      {url: `/dispatch/delivery_configurations`, method: 'GET',
        params, signal
    },
      options);
    }


export const getDeliveryConfigurationsQueryKey = (params?: DeliveryConfigurationsParams,) => {
    return [`/dispatch/delivery_configurations`, ...(params ? [params]: [])] as const;
    }


export const getDeliveryConfigurationsQueryOptions = <TData = Awaited<ReturnType<typeof deliveryConfigurations>>, TError = ErrorType<unknown>>(params?: DeliveryConfigurationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof deliveryConfigurations>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDeliveryConfigurationsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof deliveryConfigurations>>> = ({ signal }) => deliveryConfigurations(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof deliveryConfigurations>>, TError, TData> & { queryKey: QueryKey }
}

export type DeliveryConfigurationsQueryResult = NonNullable<Awaited<ReturnType<typeof deliveryConfigurations>>>
export type DeliveryConfigurationsQueryError = ErrorType<unknown>

export const useDeliveryConfigurations = <TData = Awaited<ReturnType<typeof deliveryConfigurations>>, TError = ErrorType<unknown>>(
 params?: DeliveryConfigurationsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof deliveryConfigurations>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDeliveryConfigurationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDeliveryConfigurationsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof deliveryConfigurations>>, TError = ErrorType<unknown>>(params?: DeliveryConfigurationsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof deliveryConfigurations>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDeliveryConfigurationsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof deliveryConfigurations>>> = ({ signal }) => deliveryConfigurations(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof deliveryConfigurations>>, TError, TData> & { queryKey: QueryKey }
}

export type DeliveryConfigurationsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof deliveryConfigurations>>>
export type DeliveryConfigurationsSuspenseQueryError = ErrorType<unknown>

export const useDeliveryConfigurationsSuspense = <TData = Awaited<ReturnType<typeof deliveryConfigurations>>, TError = ErrorType<unknown>>(
 params?: DeliveryConfigurationsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof deliveryConfigurations>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDeliveryConfigurationsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createDeliveryConfiguration = (
    deliveryConfigurationCreateSchema: BodyType<DeliveryConfigurationCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DeliveryConfigurationDumpSchema>(
      {url: `/dispatch/delivery_configurations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: deliveryConfigurationCreateSchema
    },
      options);
    }


export const deliveryConfiguration = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<DeliveryConfigurationDumpSchema>(
      {url: `/dispatch/delivery_configurations/${id}`, method: 'GET', signal
    },
      options);
    }


export const getDeliveryConfigurationQueryKey = (id: string,) => {
    return [`/dispatch/delivery_configurations/${id}`] as const;
    }


export const getDeliveryConfigurationQueryOptions = <TData = Awaited<ReturnType<typeof deliveryConfiguration>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof deliveryConfiguration>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDeliveryConfigurationQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof deliveryConfiguration>>> = ({ signal }) => deliveryConfiguration(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof deliveryConfiguration>>, TError, TData> & { queryKey: QueryKey }
}

export type DeliveryConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof deliveryConfiguration>>>
export type DeliveryConfigurationQueryError = ErrorType<unknown>

export const useDeliveryConfiguration = <TData = Awaited<ReturnType<typeof deliveryConfiguration>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof deliveryConfiguration>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDeliveryConfigurationQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDeliveryConfigurationSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof deliveryConfiguration>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof deliveryConfiguration>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDeliveryConfigurationQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof deliveryConfiguration>>> = ({ signal }) => deliveryConfiguration(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof deliveryConfiguration>>, TError, TData> & { queryKey: QueryKey }
}

export type DeliveryConfigurationSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof deliveryConfiguration>>>
export type DeliveryConfigurationSuspenseQueryError = ErrorType<unknown>

export const useDeliveryConfigurationSuspense = <TData = Awaited<ReturnType<typeof deliveryConfiguration>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof deliveryConfiguration>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDeliveryConfigurationSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateDeliveryConfiguration = (
    id: string,
    deliveryConfigurationUpdateSchema: BodyType<DeliveryConfigurationUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DeliveryConfigurationDumpSchema>(
      {url: `/dispatch/delivery_configurations/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: deliveryConfigurationUpdateSchema
    },
      options);
    }


export const deleteDeliveryConfiguration = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/dispatch/delivery_configurations/${id}`, method: 'DELETE'
    },
      options);
    }


export const getDefaultDeliveryConfiguration = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<DeliveryConfigurationDumpSchema>(
      {url: `/dispatch/delivery_configurations/default`, method: 'GET', signal
    },
      options);
    }


export const getGetDefaultDeliveryConfigurationQueryKey = () => {
    return [`/dispatch/delivery_configurations/default`] as const;
    }


export const getGetDefaultDeliveryConfigurationQueryOptions = <TData = Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDefaultDeliveryConfigurationQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>> = ({ signal }) => getDefaultDeliveryConfiguration(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDefaultDeliveryConfigurationQueryResult = NonNullable<Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>>
export type GetDefaultDeliveryConfigurationQueryError = ErrorType<unknown>

export const useGetDefaultDeliveryConfiguration = <TData = Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDefaultDeliveryConfigurationQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDefaultDeliveryConfigurationSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDefaultDeliveryConfigurationQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>> = ({ signal }) => getDefaultDeliveryConfiguration(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDefaultDeliveryConfigurationSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>>
export type GetDefaultDeliveryConfigurationSuspenseQueryError = ErrorType<unknown>

export const useGetDefaultDeliveryConfigurationSuspense = <TData = Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDefaultDeliveryConfiguration>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDefaultDeliveryConfigurationSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDistance = (
    distanceRequestSchema: BodyType<DistanceRequestSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DistanceResponseSchema>(
      {url: `/dispatch/distance`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: distanceRequestSchema
    },
      options);
    }


export const getDistanceCost = (
    deliveryConfigurationCalculationRequestSchema: BodyType<DeliveryConfigurationCalculationRequestSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DeliveryConfigurationCalculationResponseSchema>(
      {url: `/dispatch/distance-cost`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: deliveryConfigurationCalculationRequestSchema
    },
      options);
    }


export const listDeliveryRequests = (
    params?: ListDeliveryRequestsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedDeliveryRequestSchema>(
      {url: `/dispatch/delivery-requests`, method: 'GET',
        params, signal
    },
      options);
    }


export const getListDeliveryRequestsQueryKey = (params?: ListDeliveryRequestsParams,) => {
    return [`/dispatch/delivery-requests`, ...(params ? [params]: [])] as const;
    }


export const getListDeliveryRequestsQueryOptions = <TData = Awaited<ReturnType<typeof listDeliveryRequests>>, TError = ErrorType<unknown>>(params?: ListDeliveryRequestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDeliveryRequests>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDeliveryRequestsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeliveryRequests>>> = ({ signal }) => listDeliveryRequests(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDeliveryRequests>>, TError, TData> & { queryKey: QueryKey }
}

export type ListDeliveryRequestsQueryResult = NonNullable<Awaited<ReturnType<typeof listDeliveryRequests>>>
export type ListDeliveryRequestsQueryError = ErrorType<unknown>

export const useListDeliveryRequests = <TData = Awaited<ReturnType<typeof listDeliveryRequests>>, TError = ErrorType<unknown>>(
 params?: ListDeliveryRequestsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDeliveryRequests>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDeliveryRequestsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListDeliveryRequestsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listDeliveryRequests>>, TError = ErrorType<unknown>>(params?: ListDeliveryRequestsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryRequests>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDeliveryRequestsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeliveryRequests>>> = ({ signal }) => listDeliveryRequests(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryRequests>>, TError, TData> & { queryKey: QueryKey }
}

export type ListDeliveryRequestsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listDeliveryRequests>>>
export type ListDeliveryRequestsSuspenseQueryError = ErrorType<unknown>

export const useListDeliveryRequestsSuspense = <TData = Awaited<ReturnType<typeof listDeliveryRequests>>, TError = ErrorType<unknown>>(
 params?: ListDeliveryRequestsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryRequests>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDeliveryRequestsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const listDeliveryRoutes = (
    params?: ListDeliveryRoutesParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedDeliveryRouteSchema>(
      {url: `/dispatch/delivery-routes`, method: 'GET',
        params, signal
    },
      options);
    }


export const getListDeliveryRoutesQueryKey = (params?: ListDeliveryRoutesParams,) => {
    return [`/dispatch/delivery-routes`, ...(params ? [params]: [])] as const;
    }


export const getListDeliveryRoutesQueryOptions = <TData = Awaited<ReturnType<typeof listDeliveryRoutes>>, TError = ErrorType<unknown>>(params?: ListDeliveryRoutesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDeliveryRoutes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDeliveryRoutesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeliveryRoutes>>> = ({ signal }) => listDeliveryRoutes(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDeliveryRoutes>>, TError, TData> & { queryKey: QueryKey }
}

export type ListDeliveryRoutesQueryResult = NonNullable<Awaited<ReturnType<typeof listDeliveryRoutes>>>
export type ListDeliveryRoutesQueryError = ErrorType<unknown>

export const useListDeliveryRoutes = <TData = Awaited<ReturnType<typeof listDeliveryRoutes>>, TError = ErrorType<unknown>>(
 params?: ListDeliveryRoutesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDeliveryRoutes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDeliveryRoutesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListDeliveryRoutesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listDeliveryRoutes>>, TError = ErrorType<unknown>>(params?: ListDeliveryRoutesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryRoutes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDeliveryRoutesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeliveryRoutes>>> = ({ signal }) => listDeliveryRoutes(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryRoutes>>, TError, TData> & { queryKey: QueryKey }
}

export type ListDeliveryRoutesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listDeliveryRoutes>>>
export type ListDeliveryRoutesSuspenseQueryError = ErrorType<unknown>

export const useListDeliveryRoutesSuspense = <TData = Awaited<ReturnType<typeof listDeliveryRoutes>>, TError = ErrorType<unknown>>(
 params?: ListDeliveryRoutesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryRoutes>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDeliveryRoutesSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createDeliveryRoute = (
    deliveryRouteCreateSchema: BodyType<DeliveryRouteCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DeliveryRouteHydratedDumpSchema>(
      {url: `/dispatch/delivery-routes`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: deliveryRouteCreateSchema
    },
      options);
    }


export const updateDeliveryRouteStatuses = (
    deliveryRoutesUpdateStatusSchema: BodyType<DeliveryRoutesUpdateStatusSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DeliveryRoutesDumpSchema>(
      {url: `/dispatch/delivery-routes`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: deliveryRoutesUpdateStatusSchema
    },
      options);
    }


export const getDeliveryRoute = (
    deliveryRouteId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<DeliveryRouteHydratedDumpSchema>(
      {url: `/dispatch/delivery-routes/${deliveryRouteId}`, method: 'GET', signal
    },
      options);
    }


export const getGetDeliveryRouteQueryKey = (deliveryRouteId: string,) => {
    return [`/dispatch/delivery-routes/${deliveryRouteId}`] as const;
    }


export const getGetDeliveryRouteQueryOptions = <TData = Awaited<ReturnType<typeof getDeliveryRoute>>, TError = ErrorType<unknown>>(deliveryRouteId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDeliveryRoute>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDeliveryRouteQueryKey(deliveryRouteId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeliveryRoute>>> = ({ signal }) => getDeliveryRoute(deliveryRouteId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(deliveryRouteId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDeliveryRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDeliveryRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getDeliveryRoute>>>
export type GetDeliveryRouteQueryError = ErrorType<unknown>

export const useGetDeliveryRoute = <TData = Awaited<ReturnType<typeof getDeliveryRoute>>, TError = ErrorType<unknown>>(
 deliveryRouteId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDeliveryRoute>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDeliveryRouteQueryOptions(deliveryRouteId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetDeliveryRouteSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getDeliveryRoute>>, TError = ErrorType<unknown>>(deliveryRouteId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDeliveryRoute>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDeliveryRouteQueryKey(deliveryRouteId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeliveryRoute>>> = ({ signal }) => getDeliveryRoute(deliveryRouteId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(deliveryRouteId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDeliveryRoute>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDeliveryRouteSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getDeliveryRoute>>>
export type GetDeliveryRouteSuspenseQueryError = ErrorType<unknown>

export const useGetDeliveryRouteSuspense = <TData = Awaited<ReturnType<typeof getDeliveryRoute>>, TError = ErrorType<unknown>>(
 deliveryRouteId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDeliveryRoute>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDeliveryRouteSuspenseQueryOptions(deliveryRouteId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateDeliveryRoute = (
    deliveryRouteId: string,
    deliveryRouteUpdateSchema: BodyType<DeliveryRouteUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DeliveryRouteHydratedDumpSchema>(
      {url: `/dispatch/delivery-routes/${deliveryRouteId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: deliveryRouteUpdateSchema
    },
      options);
    }


export const addRequestsToDeliveryRoute = (
    deliveryRouteId: string,
    deliveryRouteInsertSchema: BodyType<DeliveryRouteInsertSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DeliveryRouteHydratedDumpSchema>(
      {url: `/dispatch/delivery-routes/${deliveryRouteId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: deliveryRouteInsertSchema
    },
      options);
    }


export const deleteDeliveryRoute = (
    deliveryRouteId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/dispatch/delivery-routes/${deliveryRouteId}`, method: 'DELETE'
    },
      options);
    }


export const patchLoadRequests = (
    loadRequestPatchSchema: BodyType<LoadRequestPatchSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<LoadRequestsDumpSchema>(
      {url: `/dispatch/load-requests`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: loadRequestPatchSchema
    },
      options);
    }


export const updateDeliveryRouteStopStatuses = (
    deliveryStopsUpdateStatusSchema: BodyType<DeliveryStopsUpdateStatusSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DeliveryRouteStopsDumpSchema>(
      {url: `/dispatch/delivery-routes/stops`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: deliveryStopsUpdateStatusSchema
    },
      options);
    }


export const listDeliveryDrivers = (
    params?: ListDeliveryDriversParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedDeliveryDriversDumpSchema>(
      {url: `/dispatch/delivery-drivers`, method: 'GET',
        params, signal
    },
      options);
    }


export const getListDeliveryDriversQueryKey = (params?: ListDeliveryDriversParams,) => {
    return [`/dispatch/delivery-drivers`, ...(params ? [params]: [])] as const;
    }


export const getListDeliveryDriversQueryOptions = <TData = Awaited<ReturnType<typeof listDeliveryDrivers>>, TError = ErrorType<unknown>>(params?: ListDeliveryDriversParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDeliveryDrivers>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDeliveryDriversQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeliveryDrivers>>> = ({ signal }) => listDeliveryDrivers(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDeliveryDrivers>>, TError, TData> & { queryKey: QueryKey }
}

export type ListDeliveryDriversQueryResult = NonNullable<Awaited<ReturnType<typeof listDeliveryDrivers>>>
export type ListDeliveryDriversQueryError = ErrorType<unknown>

export const useListDeliveryDrivers = <TData = Awaited<ReturnType<typeof listDeliveryDrivers>>, TError = ErrorType<unknown>>(
 params?: ListDeliveryDriversParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDeliveryDrivers>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDeliveryDriversQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListDeliveryDriversSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listDeliveryDrivers>>, TError = ErrorType<unknown>>(params?: ListDeliveryDriversParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryDrivers>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDeliveryDriversQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeliveryDrivers>>> = ({ signal }) => listDeliveryDrivers(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryDrivers>>, TError, TData> & { queryKey: QueryKey }
}

export type ListDeliveryDriversSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listDeliveryDrivers>>>
export type ListDeliveryDriversSuspenseQueryError = ErrorType<unknown>

export const useListDeliveryDriversSuspense = <TData = Awaited<ReturnType<typeof listDeliveryDrivers>>, TError = ErrorType<unknown>>(
 params?: ListDeliveryDriversParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryDrivers>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDeliveryDriversSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const listDeliveryTrucks = (
    params?: ListDeliveryTrucksParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedDeliveryTrucksDumpSchema>(
      {url: `/dispatch/delivery-trucks`, method: 'GET',
        params, signal
    },
      options);
    }


export const getListDeliveryTrucksQueryKey = (params?: ListDeliveryTrucksParams,) => {
    return [`/dispatch/delivery-trucks`, ...(params ? [params]: [])] as const;
    }


export const getListDeliveryTrucksQueryOptions = <TData = Awaited<ReturnType<typeof listDeliveryTrucks>>, TError = ErrorType<unknown>>(params?: ListDeliveryTrucksParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDeliveryTrucks>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDeliveryTrucksQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeliveryTrucks>>> = ({ signal }) => listDeliveryTrucks(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDeliveryTrucks>>, TError, TData> & { queryKey: QueryKey }
}

export type ListDeliveryTrucksQueryResult = NonNullable<Awaited<ReturnType<typeof listDeliveryTrucks>>>
export type ListDeliveryTrucksQueryError = ErrorType<unknown>

export const useListDeliveryTrucks = <TData = Awaited<ReturnType<typeof listDeliveryTrucks>>, TError = ErrorType<unknown>>(
 params?: ListDeliveryTrucksParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDeliveryTrucks>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDeliveryTrucksQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListDeliveryTrucksSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listDeliveryTrucks>>, TError = ErrorType<unknown>>(params?: ListDeliveryTrucksParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryTrucks>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDeliveryTrucksQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeliveryTrucks>>> = ({ signal }) => listDeliveryTrucks(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryTrucks>>, TError, TData> & { queryKey: QueryKey }
}

export type ListDeliveryTrucksSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listDeliveryTrucks>>>
export type ListDeliveryTrucksSuspenseQueryError = ErrorType<unknown>

export const useListDeliveryTrucksSuspense = <TData = Awaited<ReturnType<typeof listDeliveryTrucks>>, TError = ErrorType<unknown>>(
 params?: ListDeliveryTrucksParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listDeliveryTrucks>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDeliveryTrucksSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getArcgisToken = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<ArcGISToken>(
      {url: `/dispatch/arcgis-token`, method: 'GET', signal
    },
      options);
    }


export const getGetArcgisTokenQueryKey = () => {
    return [`/dispatch/arcgis-token`] as const;
    }


export const getGetArcgisTokenQueryOptions = <TData = Awaited<ReturnType<typeof getArcgisToken>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getArcgisToken>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetArcgisTokenQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getArcgisToken>>> = ({ signal }) => getArcgisToken(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getArcgisToken>>, TError, TData> & { queryKey: QueryKey }
}

export type GetArcgisTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getArcgisToken>>>
export type GetArcgisTokenQueryError = ErrorType<unknown>

export const useGetArcgisToken = <TData = Awaited<ReturnType<typeof getArcgisToken>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getArcgisToken>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetArcgisTokenQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetArcgisTokenSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getArcgisToken>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getArcgisToken>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetArcgisTokenQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getArcgisToken>>> = ({ signal }) => getArcgisToken(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getArcgisToken>>, TError, TData> & { queryKey: QueryKey }
}

export type GetArcgisTokenSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getArcgisToken>>>
export type GetArcgisTokenSuspenseQueryError = ErrorType<unknown>

export const useGetArcgisTokenSuspense = <TData = Awaited<ReturnType<typeof getArcgisToken>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getArcgisToken>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetArcgisTokenSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const solveRoutingRoute = (
    deliveryRouteId: string,
    routeBody: BodyType<RouteBody>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<RouteResponse>(
      {url: `/dispatch/route/${deliveryRouteId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: routeBody
    },
      options);
    }


