import {
  NumberInput as MantineNumberInput,
  type NumberInputProps as MantineNumberInputProps,
} from '@mantine/core'
import { type RefCallBack } from 'react-hook-form'

import {
  getKuiInputFieldErrorProps,
  type KuiInputFieldErrorLocation,
} from 'components/kui/_internal/KuiInputWrapper'
import { kuiThemeVars } from 'components/kui/_internal/theme'
import { KuiButton, type KuiButtonProps } from 'components/kui/KuiButton'

type KuiNumberInputType = 'int' | 'money' | 'percent' | 'decimal' | 'year'

const defaultPropsForType: Record<KuiNumberInputType, MantineNumberInputProps> =
  {
    int: {
      allowDecimal: false,
      hideControls: false,
    },
    money: {
      prefix: '$',
      step: 0.01,
      decimalScale: 2,
      fixedDecimalScale: true,
    },
    percent: {
      min: -100,
      max: 100,
      step: 0.01,
      decimalScale: 2,
      suffix: '%',
    },
    decimal: {
      step: 0.01,
      decimalScale: 2,
      allowDecimal: true,
      hideControls: true,
    },
    year: {
      allowDecimal: false,
      hideControls: true,
      thousandSeparator: false,
      allowNegative: false,
    },
  }

export type KuiNumberInputProps = Pick<
  MantineNumberInputProps,
  | 'label'
  | 'description'
  | 'placeholder'
  | 'error'
  | 'disabled'
  | 'withAsterisk'
  | 'variant'
  | 'min'
  | 'max'
  | 'allowNegative'
  | 'allowDecimal'
  | 'decimalScale'
  | 'prefix'
  | 'hideControls'
  | 'readOnly'
> & {
  type: KuiNumberInputType

  value: number | null
  onChange: (value: number | null) => void

  suffix?: string | ((number: number | null) => string)

  /** @default 'left' */
  textAlign?: 'left' | 'right'

  errorLocation?: KuiInputFieldErrorLocation

  inputRef?: RefCallBack

  rightSection?: string | { action: KuiButtonProps }
}

export function KuiNumberInput({
  inputRef,
  value,
  onChange,
  type,
  max = Number.MAX_SAFE_INTEGER,
  textAlign = 'left',
  error,
  errorLocation,
  label,
  suffix: consumerSuffix,
  placeholder = typeof label === 'string'
    ? `Enter ${label.toLowerCase()}`
    : undefined,
  rightSection,
  ...restProps
}: KuiNumberInputProps) {
  const defaultProps = type ? defaultPropsForType[type] : {}

  const suffix =
    typeof consumerSuffix === 'function'
      ? consumerSuffix(value)
      : consumerSuffix ?? defaultProps.suffix

  return (
    <MantineNumberInput
      ref={inputRef}
      value={
        typeof value === 'string' && parseFloat(value) === 0 ? 0 : value ?? ''
      }
      max={Math.min(max, Number.MAX_SAFE_INTEGER)}
      clampBehavior='strict'
      thousandSeparator={true}
      label={label}
      placeholder={placeholder}
      suffix={suffix}
      {...defaultProps}
      hideControls={
        restProps.disabled && restProps.variant === 'content'
          ? true
          : defaultProps.hideControls ?? true
      }
      {...restProps}
      onChange={(nextValue) =>
        onChange(nextValue === '' ? null : Number(nextValue))
      }
      autoComplete='off'
      data-1p-ignore={true}
      style={{ width: '100%' }}
      styles={{
        input: {
          textAlign,
          paddingLeft:
            restProps.variant === 'unstyled'
              ? kuiThemeVars.spacingSizes.xs
              : undefined,
        },
      }}
      rightSection={
        typeof rightSection === 'string' ? (
          rightSection
        ) : rightSection?.action && !restProps.disabled ? (
          <span
            style={{
              marginRight: kuiThemeVars.spacingSizes.xs,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <KuiButton {...rightSection.action} _compactIconButton={true} />
          </span>
        ) : undefined
      }
      {...getKuiInputFieldErrorProps({ error, errorLocation })}
    />
  )
}
