import { useCallback, useMemo, useState } from 'react'

import { type KuiTablePaginationProps } from './KuiTable'

type UseKuiTablePaginationParams<TRow> = {
  rows: TRow[]
  pageSize: number
  alwaysShowPagination?: boolean
}

export type UseKuiTablePaginationReturn<TRow> = {
  rows: TRow[]
  paginationProps: KuiTablePaginationProps & { resetPage: () => void }
}

export function useKuiTablePagination<TRow>({
  rows,
  pageSize,
  alwaysShowPagination = false,
}: UseKuiTablePaginationParams<TRow>): UseKuiTablePaginationReturn<TRow> {
  const [currentPage, setCurrentPage] = useState(0)

  const onPrev = useCallback(
    () => setCurrentPage((prevPage) => prevPage - 1),
    []
  )
  const onNext = useCallback(
    () => setCurrentPage((prevPage) => prevPage + 1),
    []
  )
  const resetPage = useCallback(() => setCurrentPage(0), [])

  return useMemo(() => {
    const hasPrev = currentPage > 0
    const hasNext = currentPage < Math.ceil(rows.length / pageSize) - 1

    const startIndex = currentPage * pageSize
    const endIndex = startIndex + pageSize
    const pageRows = rows.slice(startIndex, endIndex)

    return {
      rows: pageRows,
      paginationProps: {
        visible: alwaysShowPagination || rows.length > pageSize,
        currentPage,
        pageSize,
        totalRows: rows.length,
        hasPrev,
        onPrev,
        onNext,
        resetPage,
        hasNext,
      },
    }
  }, [
    alwaysShowPagination,
    currentPage,
    onNext,
    onPrev,
    pageSize,
    resetPage,
    rows,
  ])
}
