import { type UserDumpSchema } from '_autogenerated'

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export enum ActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type AuthPayload = {
  [ActionTypes.LOGIN]: {
    user: UserDumpSchema
  }
  [ActionTypes.LOGOUT]: {}
}

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>]

export type AuthContextType = {
  user?: UserDumpSchema
}
