import { type ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { kuiThemeVars, type KuiThemeSpacingSize } from './_internal/theme'

type KuiPadSize = KuiThemeSpacingSize | 'unset'

type KuiPadProps = {
  /** @default 'unset' */
  size?: KuiPadSize

  /** @default the value of the `size` prop, defaults to 'unset'. */
  horizontalSize?: KuiPadSize

  /** @default the value of the `size` prop, defaults to 'unset'. */
  verticalSize?: KuiPadSize

  /** @default the value of the `horizontalSize` prop, defaults to 'unset'. */
  leftSize?: KuiPadSize

  /** @default the value of the `horizontalSize` prop, defaults to 'unset'. */
  rightSize?: KuiPadSize

  /** @default the value of the `verticalSize` prop, defaults to 'unset'. */
  topSize?: KuiPadSize

  /** @default the value of the `verticalSize` prop, defaults to 'unset'. */
  bottomSize?: KuiPadSize

  children?: ReactNode
}

export type MixKuiPadProps = {
  size?: KuiPadSize
  horizontalSize?: KuiPadSize
  verticalSize?: KuiPadSize
  leftSize?: KuiPadSize
  rightSize?: KuiPadSize
  topSize?: KuiPadSize
  bottomSize?: KuiPadSize
}

export const mixKuiPad = ({
  size = 'unset',
  horizontalSize = size,
  leftSize = horizontalSize,
  rightSize = horizontalSize,
  verticalSize = size,
  topSize = verticalSize,
  bottomSize = verticalSize,
}: MixKuiPadProps) => css`
  ${leftSize !== 'unset' &&
  `padding-left: ${kuiThemeVars.spacingSizes[leftSize]};`}
  ${rightSize !== 'unset' &&
  `padding-right: ${kuiThemeVars.spacingSizes[rightSize]};`}
  ${topSize !== 'unset' &&
  `padding-top: ${kuiThemeVars.spacingSizes[topSize]};`}
  ${bottomSize !== 'unset' &&
  `padding-bottom: ${kuiThemeVars.spacingSizes[bottomSize]};`}
`

type KuiPadRootProps = { $paddingProps: MixKuiPadProps }

const KuiPadRoot = styled.div<KuiPadRootProps>`
  ${(p) => mixKuiPad(p.$paddingProps)}
`

export function KuiPad({
  size,
  horizontalSize,
  leftSize,
  rightSize,
  verticalSize,
  topSize,
  bottomSize,
  children,
}: KuiPadProps) {
  return (
    <KuiPadRoot
      $paddingProps={{
        size,
        horizontalSize,
        leftSize,
        rightSize,
        verticalSize,
        topSize,
        bottomSize,
      }}
    >
      {children}
    </KuiPadRoot>
  )
}
