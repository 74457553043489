export const kuiThemeVars = {
  manueverSizes: {
    0: 0,
    0.5: 'var(--kui-manuever-size-0_5)',
    1: 'var(--kui-manuever-size-1)',
    2: 'var(--kui-manuever-size-2)',
  },
  spacingSizes: {
    none: 0,
    xs: 'var(--mantine-spacing-xs)',
    sm: 'var(--mantine-spacing-sm)',
    md: 'var(--mantine-spacing-md)',
    lg: 'var(--mantine-spacing-lg)',
    xl: 'var(--mantine-spacing-xl)',
  },
  fontSizes: {
    xs: 'var(--mantine-font-size-xs)',
    sm: 'var(--mantine-font-size-sm)',
    md: 'var(--mantine-font-size-md)',
    lg: 'var(--mantine-font-size-lg)',
    xl: 'var(--mantine-font-size-xl)',
  },
  lineHeights: {
    xs: 'var(--mantine-line-height-xs)',
    sm: 'var(--mantine-line-height-sm)',
    md: 'var(--mantine-line-height-md)',
    lg: 'var(--mantine-line-height-lg)',
    xl: 'var(--mantine-line-height-xl)',
  },
  fontWeights: {
    light: 'var(--kui-font-weight-light)',
    regular: 'normal',
    semibold: 'var(--kui-font-weight-semibold)',
    heading: 'var(--mantine-heading-font-weight)',
  },
  radii: {
    square: 0,
    regular: 'var(--mantine-radius-default)',
  },
  stackingLevels: {
    timid: 1,
    sticky: 50,
    modal: 200,
    nestedModal: 400,
    confirmationModal: 500,
  },
  colors: {
    emptyShade: 'var(--kui-color-emptyShade)',
    lightShade: 'var(--kui-color-lightShade)',
    mediumShade: 'var(--kui-color-mediumShade)',
  },
  breakpoints: {
    sm: '768px',
    md: '1024px',
  },
}

type KuiThemeVars = typeof kuiThemeVars

export type KuiThemeSpacingSize = keyof KuiThemeVars['spacingSizes']
export type KuiThemeFontSize = keyof KuiThemeVars['fontSizes']
export type KuiThemeLineHeight = keyof KuiThemeVars['lineHeights']
export type KuiThemeFontWeight = keyof KuiThemeVars['fontWeights']
export type KuiThemeRadius = keyof KuiThemeVars['radii']
export type KuiThemeStackingLevel = keyof KuiThemeVars['stackingLevels']
export type KuiThemeColor = keyof KuiThemeVars['colors']
export type KuiThemeManueverSize = keyof KuiThemeVars['manueverSizes']
export type KuiThemeBreakpoint = keyof KuiThemeVars['breakpoints']
