import { Tabs } from '@mantine/core'

import { getAsLinkMantineProps, type MaybeLinkProps } from './KuiLink'

type KuiTab<TTab> = MaybeLinkProps & {
  key: TTab
  label: string
}

export type KuiTabsProps<TTab> = {
  activeTab: TTab
  tabs: KuiTab<TTab>[]
  onChange?: (nextTab: TTab | null) => void
}

export function KuiTabs<TTab extends string>({
  activeTab,
  tabs,
  onChange,
}: KuiTabsProps<TTab>) {
  return (
    <Tabs
      value={activeTab}
      color='black'
      onChange={
        onChange ? (nextTab) => onChange(nextTab as TTab | null) : undefined
      }
    >
      <Tabs.List>
        {tabs.map((tab) => (
          <Tabs.Tab
            key={tab.key}
            value={tab.key}
            {...getAsLinkMantineProps({ to: tab.to, state: tab.state })}
          >
            {tab.label}
          </Tabs.Tab>
        ))}
      </Tabs.List>
    </Tabs>
  )
}
