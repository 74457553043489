import {
  type AccountMappingsDumpSchema,
  MoabAccountType,
  InventoryProfileType,
  RemoteAccountType,
  AccountingSubAccountType,
} from '_autogenerated'
import { type AccountingMappingsSchemaType } from 'components/Inputs/AccountingMappingInput/types'

export function insertEmptiesIntoMappings(
  data?: AccountMappingsDumpSchema | null
): AccountingMappingsSchemaType {
  return {
    mappings: Object.keys(MoabAccountType).map((moabAccountType) => ({
      remote_account_id:
        data?.mappings.find((mapping) => mapping.type === moabAccountType)
          ?.remote_account_id ?? undefined,
      type: moabAccountType as MoabAccountType,
    })),
  }
}

export function getMoabAccountTypeForMapping(
  profileType: InventoryProfileType
) {
  // corresponds to INVENTORY_ACCOUNT_PROFILE_TYPE_MAP on the backend.
  switch (profileType) {
    case InventoryProfileType.rental:
      return [
        MoabAccountType.accumulated_depreciation,
        MoabAccountType.depreciation_expense,
        MoabAccountType.inventory,
      ]
    case InventoryProfileType.sales:
    case InventoryProfileType.parts:
      return [
        MoabAccountType.revenue,
        MoabAccountType.cogs,
        MoabAccountType.inventory,
      ]
    case InventoryProfileType.service:
    case InventoryProfileType.re_rentals:
      return [MoabAccountType.revenue]
    case InventoryProfileType.non_rental_assets:
      return [
        MoabAccountType.accumulated_depreciation,
        MoabAccountType.depreciation_expense,
      ]
    default:
      return []
  }
}

export function getMoabSubAccountTypeOptions(
  account_type: RemoteAccountType
): AccountingSubAccountType[] {
  switch (account_type) {
    case RemoteAccountType.bank:
      return [
        AccountingSubAccountType.cash_on_hand,
        AccountingSubAccountType.checking,
        AccountingSubAccountType.money_market,
        AccountingSubAccountType.rents_held_in_trust,
        AccountingSubAccountType.savings,
        AccountingSubAccountType.trust_accounts,
        AccountingSubAccountType.cash_and_cash_equivalents,
        AccountingSubAccountType.other_ear_marked_bank_accounts,
      ]
    case RemoteAccountType.other_current_asset:
      return [
        AccountingSubAccountType.allowance_for_bad_debts,
        AccountingSubAccountType.development_costs,
        AccountingSubAccountType.employee_cash_advances,
        AccountingSubAccountType.other_current_assets,
        AccountingSubAccountType.inventory,
        AccountingSubAccountType.investment_mortgage_real_estate_loans,
        AccountingSubAccountType.investment_other,
        AccountingSubAccountType.investment_tax_exempt_securities,
        AccountingSubAccountType.investment_us_government_obligations,
        AccountingSubAccountType.loans_to_officers,
        AccountingSubAccountType.loans_to_others,
        AccountingSubAccountType.loans_to_stockholders,
        AccountingSubAccountType.prepaid_expenses,
        AccountingSubAccountType.retainage,
        AccountingSubAccountType.undeposited_funds,
        AccountingSubAccountType.assets_available_for_sale,
        AccountingSubAccountType.bal_with_govt_authorities,
        AccountingSubAccountType.called_up_share_capital_not_paid,
        AccountingSubAccountType.expenditure_authorisations_and_letters_of_credit,
        AccountingSubAccountType.global_tax_deferred,
        AccountingSubAccountType.global_tax_refund,
        AccountingSubAccountType.internal_transfers,
        AccountingSubAccountType.other_consumables,
        AccountingSubAccountType.provisions_current_assets,
        AccountingSubAccountType.short_term_investments_in_related_parties,
        AccountingSubAccountType.short_term_loans_and_advances_to_related_parties,
        AccountingSubAccountType.trade_and_other_receivables,
      ]
    case RemoteAccountType.fixed_asset:
      return [
        AccountingSubAccountType.accumulated_depletion,
        AccountingSubAccountType.accumulated_depreciation,
        AccountingSubAccountType.depletable_assets,
        AccountingSubAccountType.fixed_asset_computers,
        AccountingSubAccountType.fixed_asset_copiers,
        AccountingSubAccountType.fixed_asset_furniture,
        AccountingSubAccountType.fixed_asset_phone,
        AccountingSubAccountType.fixed_asset_photo_video,
        AccountingSubAccountType.fixed_asset_software,
        AccountingSubAccountType.fixed_asset_other_tools_equipment,
        AccountingSubAccountType.furniture_and_fixtures,
        AccountingSubAccountType.land,
        AccountingSubAccountType.leasehold_improvements,
        AccountingSubAccountType.other_fixed_assets,
        AccountingSubAccountType.accumulated_amortization,
        AccountingSubAccountType.buildings,
        AccountingSubAccountType.intangible_assets,
        AccountingSubAccountType.machinery_and_equipment,
        AccountingSubAccountType.vehicles,
        AccountingSubAccountType.assets_in_course_of_construction,
        AccountingSubAccountType.capital_wip,
        AccountingSubAccountType.cumulative_depreciation_on_intangible_assets,
        AccountingSubAccountType.intangible_assets_under_development,
        AccountingSubAccountType.land_asset,
        AccountingSubAccountType.non_current_assets,
        AccountingSubAccountType.participating_interests,
        AccountingSubAccountType.provisions_fixed_assets,
      ]
    case RemoteAccountType.other_asset:
      return [
        AccountingSubAccountType.lease_buyout,
        AccountingSubAccountType.other_long_term_assets,
        AccountingSubAccountType.security_deposits,
        AccountingSubAccountType.accumulated_amortization_of_other_assets,
        AccountingSubAccountType.goodwill,
        AccountingSubAccountType.licenses,
        AccountingSubAccountType.organizational_costs,
        AccountingSubAccountType.assets_held_for_sale,
        AccountingSubAccountType.available_for_sale_financial_assets,
        AccountingSubAccountType.deferred_tax,
        AccountingSubAccountType.investments,
        AccountingSubAccountType.long_term_investments,
        AccountingSubAccountType.long_term_loans_and_advances_to_related_parties,
        AccountingSubAccountType.other_intangible_assets,
        AccountingSubAccountType.other_long_term_investments,
        AccountingSubAccountType.other_long_term_loans_and_advances,
        AccountingSubAccountType.prepayments_and_accrued_income,
      ]
    case RemoteAccountType.accounts_receivable:
      return [AccountingSubAccountType.accounts_receivable]
    case RemoteAccountType.equity:
      return [
        AccountingSubAccountType.opening_balance_equity,
        AccountingSubAccountType.partners_equity,
        AccountingSubAccountType.retained_earnings,
        AccountingSubAccountType.accumulated_adjustment,
        AccountingSubAccountType.owners_equity,
        AccountingSubAccountType.paid_in_capital_or_surplus,
        AccountingSubAccountType.partner_contributions,
        AccountingSubAccountType.partner_distributions,
        AccountingSubAccountType.preferred_stock,
        AccountingSubAccountType.common_stock,
        AccountingSubAccountType.treasury_stock,
        AccountingSubAccountType.estimated_taxes,
        AccountingSubAccountType.healthcare,
        AccountingSubAccountType.personal_income,
        AccountingSubAccountType.personal_expense,
        AccountingSubAccountType.accumulated_other_comprehensive_income,
        AccountingSubAccountType.called_up_share_capital,
        AccountingSubAccountType.capital_reserves,
        AccountingSubAccountType.dividend_disbursed,
        AccountingSubAccountType.equity_in_earnings_of_subsiduaries,
        AccountingSubAccountType.investment_grants,
        AccountingSubAccountType.money_received_against_share_warrants,
        AccountingSubAccountType.other_free_reserves,
        AccountingSubAccountType.share_application_money_pending_allotment,
        AccountingSubAccountType.share_capital,
        AccountingSubAccountType.funds,
      ]
    case RemoteAccountType.expense:
      return [
        AccountingSubAccountType.advertising_promotional,
        AccountingSubAccountType.bad_debts,
        AccountingSubAccountType.bank_charges,
        AccountingSubAccountType.charitable_contributions,
        AccountingSubAccountType.commissions_and_fees,
        AccountingSubAccountType.entertainment,
        AccountingSubAccountType.entertainment_meals,
        AccountingSubAccountType.equipment_rental,
        AccountingSubAccountType.finance_costs,
        AccountingSubAccountType.global_tax_expense,
        AccountingSubAccountType.insurance,
        AccountingSubAccountType.interest_paid,
        AccountingSubAccountType.legal_professional_fees,
        AccountingSubAccountType.office_expenses,
        AccountingSubAccountType.office_general_administrative_expenses,
        AccountingSubAccountType.other_business_expenses,
        AccountingSubAccountType.other_miscellaneous_service_cost,
        AccountingSubAccountType.promotional_meals,
        AccountingSubAccountType.rent_or_lease_of_buildings,
        AccountingSubAccountType.repair_maintenance,
        AccountingSubAccountType.shipping_freight_delivery,
        AccountingSubAccountType.supplies_materials,
        AccountingSubAccountType.travel,
        AccountingSubAccountType.travel_meals,
        AccountingSubAccountType.utilities,
        AccountingSubAccountType.auto,
        AccountingSubAccountType.cost_of_labor,
        AccountingSubAccountType.dues_subscriptions,
        AccountingSubAccountType.payroll_expenses,
        AccountingSubAccountType.taxes_paid,
        AccountingSubAccountType.unapplied_cash_bill_payment_expense,
        AccountingSubAccountType.amortization_expense,
        AccountingSubAccountType.appropriations_to_depreciation,
        AccountingSubAccountType.borrowing_cost,
        AccountingSubAccountType.distribution_costs,
        AccountingSubAccountType.external_services,
        AccountingSubAccountType.extraordinary_charges,
        AccountingSubAccountType.income_tax_expense,
        AccountingSubAccountType.loss_on_discontinued_operations_net_of_tax,
        AccountingSubAccountType.management_compensation,
        AccountingSubAccountType.other_current_operating_charges,
        AccountingSubAccountType.other_external_services,
        AccountingSubAccountType.other_rental_costs,
        AccountingSubAccountType.other_selling_expenses,
        AccountingSubAccountType.project_studies_surveys_assessments,
        AccountingSubAccountType.purchases_rebates,
        AccountingSubAccountType.shipping_and_delivery_expense,
        AccountingSubAccountType.staff_costs,
        AccountingSubAccountType.sundry,
        AccountingSubAccountType.travel_expenses_general_and_admin_expenses,
        AccountingSubAccountType.travel_expenses_selling_expense,
      ]
    case RemoteAccountType.other_expense:
      return [
        AccountingSubAccountType.depreciation,
        AccountingSubAccountType.exchange_gain_or_loss,
        AccountingSubAccountType.other_miscellaneous_expense,
        AccountingSubAccountType.penalties_settlements,
        AccountingSubAccountType.amortization,
        AccountingSubAccountType.gas_and_fuel,
        AccountingSubAccountType.home_office,
        AccountingSubAccountType.home_owner_rental_insurance,
        AccountingSubAccountType.other_home_office_expenses,
        AccountingSubAccountType.mortgage_interest,
        AccountingSubAccountType.rent_and_lease,
        AccountingSubAccountType.repairs_and_maintenance,
        AccountingSubAccountType.parking_and_tolls,
        AccountingSubAccountType.vehicle,
        AccountingSubAccountType.vehicle_insurance,
        AccountingSubAccountType.vehicle_lease,
        AccountingSubAccountType.vehicle_loan_interest,
        AccountingSubAccountType.vehicle_loan,
        AccountingSubAccountType.vehicle_registration,
        AccountingSubAccountType.vehicle_repairs,
        AccountingSubAccountType.other_vehicle_expenses,
        AccountingSubAccountType.wash_and_road_services,
        AccountingSubAccountType.deferred_tax_expense,
        AccountingSubAccountType.depletion,
        AccountingSubAccountType.exceptional_items,
        AccountingSubAccountType.extraordinary_items,
        AccountingSubAccountType.income_tax_other_expense,
        AccountingSubAccountType.mat_credit,
        AccountingSubAccountType.prior_period_items,
        AccountingSubAccountType.tax_roundoff_gain_or_loss,
      ]
    case RemoteAccountType.cost_of_goods_sold:
      return [
        AccountingSubAccountType.equipment_rental_cos,
        AccountingSubAccountType.other_costs_of_service_cos,
        AccountingSubAccountType.shipping_freight_delivery_cos,
        AccountingSubAccountType.supplies_materials_cogs,
        AccountingSubAccountType.cost_of_labor_cos,
        AccountingSubAccountType.cost_of_sales,
        AccountingSubAccountType.freight_and_delivery_cost,
      ]

    case RemoteAccountType.accounts_payable:
      return [
        AccountingSubAccountType.accounts_payable,
        AccountingSubAccountType.outstanding_dues_micro_small_enterprises,
        AccountingSubAccountType.outstanding_dues_other_than_micro_small_enterprises,
      ]
    case RemoteAccountType.credit_card:
      return [AccountingSubAccountType.credit_card]
    case RemoteAccountType.long_term_liability:
      return [
        AccountingSubAccountType.other_long_term_liabilities,
        AccountingSubAccountType.shareholder_notes_payable,
        AccountingSubAccountType.accruals_and_deferred_income,
        AccountingSubAccountType.accrued_long_term_liabilities,
        AccountingSubAccountType.accrued_vacation_payable,
        AccountingSubAccountType.bank_loans,
        AccountingSubAccountType.debts_related_to_participating_interests,
        AccountingSubAccountType.deferred_tax_liabilities,
        AccountingSubAccountType.government_and_other_public_authorities,
        AccountingSubAccountType.group_and_associates,
        AccountingSubAccountType.liabilities_related_to_assets_held_for_sale,
        AccountingSubAccountType.long_term_borrowings,
        AccountingSubAccountType.long_term_debit,
        AccountingSubAccountType.long_term_employee_benefit_obligations,
        AccountingSubAccountType.obligations_under_finance_leases,
        AccountingSubAccountType.other_long_term_provisions,
        AccountingSubAccountType.provision_for_liabilities,
        AccountingSubAccountType.provisions_non_current_liabilities,
        AccountingSubAccountType.staff_and_related_long_term_liability_accounts,
      ]
    case RemoteAccountType.other_current_liability:
      return [
        AccountingSubAccountType.direct_deposit_payable,
        AccountingSubAccountType.line_of_credit,
        AccountingSubAccountType.loan_payable,
        AccountingSubAccountType.global_tax_payable,
        AccountingSubAccountType.global_tax_suspense,
        AccountingSubAccountType.other_current_liabilities,
        AccountingSubAccountType.payroll_clearing,
        AccountingSubAccountType.payroll_tax_payable,
        AccountingSubAccountType.prepaid_expenses_payable,
        AccountingSubAccountType.rents_in_trust_liability,
        AccountingSubAccountType.trust_accounts_liabilities,
        AccountingSubAccountType.federal_income_tax_payable,
        AccountingSubAccountType.insurance_payable,
        AccountingSubAccountType.sales_tax_payable,
        AccountingSubAccountType.state_local_income_tax_payable,
        AccountingSubAccountType.accrued_liabilities,
        AccountingSubAccountType.current_liabilities,
        AccountingSubAccountType.current_portion_employee_benefits_obligations,
        AccountingSubAccountType.current_portion_of_obligations_under_finance_leases,
        AccountingSubAccountType.current_tax_liability,
        AccountingSubAccountType.dividends_payable,
        AccountingSubAccountType.duties_and_taxes,
        AccountingSubAccountType.interest_payables,
        AccountingSubAccountType.provision_for_warranty_obligations,
        AccountingSubAccountType.provisions_current_liabilities,
        AccountingSubAccountType.short_term_borrowings,
        AccountingSubAccountType.social_security_agencies,
        AccountingSubAccountType.staff_and_related_liability_accounts,
        AccountingSubAccountType.sundry_debtors_and_creditors,
        AccountingSubAccountType.trade_and_other_payables,
      ]
    case RemoteAccountType.income:
      return [
        AccountingSubAccountType.non_profit_income,
        AccountingSubAccountType.other_primary_income,
        AccountingSubAccountType.sales_of_product_income,
        AccountingSubAccountType.service_fee_income,
        AccountingSubAccountType.discounts_refunds_given,
        AccountingSubAccountType.unapplied_cash_payment_income,
        AccountingSubAccountType.cash_receipt_income,
        AccountingSubAccountType.operating_grants,
        AccountingSubAccountType.other_current_operating_income,
        AccountingSubAccountType.own_work_capitalized,
        AccountingSubAccountType.revenue_general,
        AccountingSubAccountType.sales_retail,
        AccountingSubAccountType.sales_wholesale,
        AccountingSubAccountType.savings_by_tax_scheme,
      ]
    case RemoteAccountType.other_income:
      return [
        AccountingSubAccountType.dividend_income,
        AccountingSubAccountType.interest_earned,
        AccountingSubAccountType.other_investment_income,
        AccountingSubAccountType.other_miscellaneous_income,
        AccountingSubAccountType.tax_exempt_interest,
        AccountingSubAccountType.gain_loss_on_sale_of_fixed_assets,
        AccountingSubAccountType.gain_loss_on_sale_of_investments,
        AccountingSubAccountType.loss_on_disposal_of_assets,
        AccountingSubAccountType.other_operating_income,
        AccountingSubAccountType.unrealised_loss_on_securities_net_of_tax,
      ]
    default:
      return []
  }
}
