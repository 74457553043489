import * as Sentry from '@sentry/react'
import axios from 'axios'
import { sortBy } from 'lodash-es'
import { useEffect, type BaseSyntheticEvent, type ReactNode } from 'react'
import {
  FormProvider,
  type FieldPath,
  type FieldValues,
  type SubmitHandler,
  type UseFormHandleSubmit,
  type UseFormReturn,
} from 'react-hook-form'

import { type KuiAction } from 'components/kui/KuiActionList'
import { KuiNavigationPrompt } from 'components/kui/KuiNavigationPrompt'
import { wrapKuiApiRequest } from 'components/kui/wrapKuiApiRequest'

type GetKuiFormSubmitActionFn = (
  action: Omit<KuiAction, 'type' | 'disabled' | 'loading'>
) => KuiAction

type KuiFormRenderProps<TFieldValues extends FieldValues> = {
  formProps: {
    onSubmit: ReturnType<UseFormHandleSubmit<TFieldValues>>
  }
  getKuiFormSubmitAction: GetKuiFormSubmitActionFn
}

export type KuiFormApiErrorsMap<TFieldValues extends FieldValues> = Record<
  string,
  FieldPath<TFieldValues>
>

export type KuiFormProps<TFieldValues extends FieldValues, TContext = any> = {
  form: UseFormReturn<TFieldValues, TContext>
  onSubmit: SubmitHandler<TFieldValues>
  render: (renderProps: KuiFormRenderProps<TFieldValues>) => ReactNode
  blockNavigationWhenDirty?: boolean

  apiErrorsMap?: KuiFormApiErrorsMap<TFieldValues>

  initialErrors?: KuiFormFieldError<TFieldValues>[]
}

export function KuiForm<TFieldValues extends FieldValues, TContext = any>({
  form,
  render,
  blockNavigationWhenDirty = true,
  onSubmit: consumerOnSubmit,
  apiErrorsMap = {},
  initialErrors,
}: KuiFormProps<TFieldValues, TContext>) {
  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = form

  useEffect(() => {
    if (initialErrors) {
      setKuiFormErrors({
        fieldErrors: initialErrors,
        setError: form.setError,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...form}>
      {blockNavigationWhenDirty && (
        <KuiNavigationPrompt
          shouldBlock={isDirty && !isSubmitting && !isSubmitSuccessful}
        />
      )}

      {render({ formProps: { onSubmit }, getKuiFormSubmitAction })}
    </FormProvider>
  )

  function onSubmit(event?: BaseSyntheticEvent) {
    event?.stopPropagation()

    const wrappedOnSubmit = wrapKuiApiRequest(async (formValues) => {
      try {
        await consumerOnSubmit(formValues)
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.response?.status === 400 &&
          error.response.data.errors?.body
        ) {
          const { fieldErrors, unmatchedApiErrors } =
            mapApiErrorsToKuiFormErrors({
              errors: error.response.data.errors.body,
              apiErrorsMap,
            })

          setKuiFormErrors({
            fieldErrors,
            setError: form.setError,
          })

          for (const unmatchedError of unmatchedApiErrors) {
            Sentry.captureMessage(
              `Unmatched pydantic error path: ${sanitizeApiErrorPath(unmatchedError.loc)}`
            )
          }

          if (fieldErrors.length > 0) {
            return
          }
        }

        throw error
      }
    })

    return handleSubmit(wrappedOnSubmit)(event)
  }

  function getKuiFormSubmitAction(
    action: Omit<KuiAction, 'type' | 'disabled' | 'loading'>
  ): KuiAction {
    return {
      type: 'submit',
      variant: 'filled',
      disabled: isSubmitting,
      loading: isSubmitting,
      ...action,
    }
  }
}

type ApiErrorSchema = {
  loc: (string | number)[]
  msg: string
}

export function setKuiFormErrors<TFieldValues extends FieldValues>({
  fieldErrors,
  setError,
}: {
  fieldErrors: KuiFormFieldError<TFieldValues>[]
  setError: UseFormReturn<TFieldValues>['setError']
}) {
  for (const [index, fieldError] of fieldErrors.entries()) {
    setError(
      fieldError.fieldPath,
      {
        type: 'custom',
        message: fieldError.message,
      },
      { shouldFocus: index === 0 }
    )
  }
}

type KuiFormFieldError<TFieldValues extends FieldValues> = {
  fieldPath: FieldPath<TFieldValues>
  message: string
  order: number
}

export function mapApiErrorsToKuiFormErrors<TFieldValues extends FieldValues>({
  errors,
  apiErrorsMap,
}: {
  errors: ApiErrorSchema[] | undefined
  apiErrorsMap: KuiFormApiErrorsMap<TFieldValues>
}) {
  if (!errors) {
    return { fieldErrors: [], unmatchedApiErrors: [] }
  }

  const fieldErrors: KuiFormFieldError<TFieldValues>[] = []
  const unmatchedApiErrors: ApiErrorSchema[] = []

  const fieldMapWithOrder = Object.fromEntries(
    Object.entries(apiErrorsMap).map(([key, fieldPath], index) => [
      key,
      { fieldPath, order: index },
    ])
  )

  for (const error of errors) {
    const apiKey = sanitizeApiErrorPath(error.loc)
    const mappedField = fieldMapWithOrder[apiKey]

    if (mappedField) {
      fieldErrors.push({
        fieldPath: mappedField.fieldPath,
        message: error.msg,
        order: mappedField.order,
      })
    } else {
      unmatchedApiErrors.push(error)
    }
  }

  return {
    fieldErrors: sortBy(fieldErrors, 'order'),
    unmatchedApiErrors,
  }
}

function sanitizeApiErrorPath(path: (string | number)[]) {
  return path
    .filter(
      (segment) =>
        typeof segment !== 'string' || !segment.startsWith('function-after[')
    )
    .join('.')
}
