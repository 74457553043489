import {
  Input as MantineInput,
  useInputWrapperContext as useMantineInputWrapperContext,
  type InputWrapperProps as MantineInputWrapperProps,
} from '@mantine/core'
import { type ReactNode } from 'react'

import { KuiIcon } from 'components/kui/KuiIcon/KuiIcon'

export type KuiInputWrapperProps = Pick<
  MantineInputWrapperProps,
  'label' | 'description' | 'error' | 'withAsterisk' | 'labelProps'
> & {
  /**
   * Mantine inputs add their own margin. For custom inputs we
   * need to add margin to the child.
   *
   * @default false
   */
  _isMantineInput?: boolean

  children?: ReactNode
}

export function KuiInputWrapper({
  children,
  _isMantineInput = false,
  ...restProps
}: KuiInputWrapperProps) {
  return (
    <MantineInput.Wrapper {...restProps}>
      {_isMantineInput ? (
        children
      ) : (
        <MantineInputWrapperMargin>{children}</MantineInputWrapperMargin>
      )}
    </MantineInput.Wrapper>
  )
}

function MantineInputWrapperMargin({ children }: { children: ReactNode }) {
  const mantineInputWrapperContext = useMantineInputWrapperContext()

  return (
    <div
      style={{
        marginTop: mantineInputWrapperContext?.offsetTop
          ? 'calc(var(--mantine-spacing-xs) / 2)'
          : undefined,
        marginBottom: mantineInputWrapperContext?.offsetBottom
          ? 'calc(var(--mantine-spacing-xs) / 2)'
          : undefined,
      }}
    >
      {children}
    </div>
  )
}

export type KuiInputFieldErrorLocation = 'caption' | 'alert-tooltip'

export function getKuiInputFieldErrorProps({
  error,
  errorLocation = 'caption',
}: {
  error?: ReactNode | string | boolean
  errorLocation?: KuiInputFieldErrorLocation
}) {
  if (!error) {
    return {}
  }

  if (errorLocation === 'alert-tooltip') {
    return {
      error: !!error,
      rightSection:
        typeof error !== 'boolean' && error !== 'Required' ? (
          <KuiIcon type='alert-circle' tooltipContent={error} />
        ) : undefined,
    }
  }

  return { error }
}
