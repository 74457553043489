import { type ReactNode } from 'react'
import { type To } from 'react-router-dom'
import styled from 'styled-components'

import { KuiButton } from 'components/kui/KuiButton'
import { KuiFlex } from 'components/kui/KuiFlex'
import { KuiText } from 'components/kui/KuiText'
import { KuiTextBits } from 'components/kui/KuiTextBits'

const KuiTableCellLinkRoot = styled.div`
  width: 100%;

  & a {
    visibility: hidden;
  }

  &:hover a {
    visibility: visible;
  }
`

export function KuiTableCellLink({
  to,
  children,
}: {
  to: To
  children: ReactNode
}) {
  return (
    <KuiTableCellLinkRoot>
      <KuiFlex gapSize='xs'>
        <KuiText.span truncate={true}>{children}</KuiText.span>

        <KuiButton
          iconType='link'
          size='xs'
          to={to}
          onClick={(event) => event.stopPropagation()}
        />
      </KuiFlex>
    </KuiTableCellLinkRoot>
  )
}

export function KuiDescriptionTableCell({
  title,
  description,
}: {
  title: string
  description?: string | { bits: ReactNode[] }
}) {
  return (
    <div style={{ minWidth: 0 }}>
      <KuiText.p truncate={true}>{title}</KuiText.p>

      {description && (
        <KuiTextBits
          size='xs'
          color='hushed'
          truncate={true}
          bits={
            typeof description === 'string' ? [description] : description.bits
          }
        />
      )}
    </div>
  )
}
