import { notifications } from '@mantine/notifications'
import { useEffect, useRef } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'

import { qboAuth } from '_autogenerated'
import KyusuLoader from 'components/Kyusu/KyusuLoader/KyusuLoader'
import { integrationSettingsUrls } from 'routes/Settings/settingsUrls'

export default function AccountingOAuthRedirect() {
  const [params] = useSearchParams()

  const code = params.get('code')
  const realmId = params.get('realmId')

  const navigate = useNavigate()

  const hasRunReq = useRef<boolean>()

  useEffect(() => {
    if (hasRunReq.current) {
      return
    }

    qboAuth({
      code: code || '',
      realm_id: realmId || '',
    }).then(() => {
      navigate(
        integrationSettingsUrls.accounting.defaultAccountMappings.buildUrl()
      )
    })
    hasRunReq.current = true
  }, [code, realmId, navigate])

  if (!code || !realmId) {
    notifications.show({
      title: 'Error',
      message: 'Missing code or realm id',
      color: 'red',
    })

    return (
      <Navigate
        to={integrationSettingsUrls.accounting.defaultAccountMappings.buildUrl()}
      />
    )
  }

  return <KyusuLoader />
}
