import { ActionIcon as MantineActionIcon } from '@mantine/core'
import classNames from 'classnames'
import styled from 'styled-components'

import { KuiButton } from 'components/kui/KuiButton'
import { type KuiIconType } from 'components/kui/KuiIcon/KuiIcon'

type Action = {
  iconType: KuiIconType

  disabled?: boolean

  /** @default false */
  active?: boolean
}

const KuiControlsGroupButton = styled(KuiButton)`
  &.KuiControlsGroupButton--active {
    color: var(--mantine-primary-color-light-color);
    background-color: var(--mantine-primary-color-light);

    &:hover {
      color: var(--mantine-primary-color-light-color);
      background-color: var(--mantine-primary-color-light-hover);
    }
  }
`

type KuiControlsGroupProps = {
  actions: Action[]
}

export function KuiControlsGroup({ actions }: KuiControlsGroupProps) {
  return (
    <MantineActionIcon.Group>
      {actions.map(({ active = false, ...action }) => (
        <KuiControlsGroupButton
          {...action}
          key={action.iconType}
          size='xs'
          variant='outline'
          className={classNames({ 'KuiControlsGroupButton--active': active })}
        />
      ))}
    </MantineActionIcon.Group>
  )
}
