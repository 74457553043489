import { css } from 'styled-components'

export const inputStyles = css`
  /* Adapted from Mantine Input.module.css: https://github.com/mantinedev/mantine/blob/master/packages/%40mantine/core/src/components/Input/Input.module.css */
  --input-disabled-bg: var(--mantine-color-gray-1);
  --input-disabled-color: var(--mantine-color-gray-6);
  --input-bd: var(--mantine-color-gray-4);
  --input-bg: var(--mantine-color-white);
  --input-bd-focus: var(--mantine-primary-color-filled);
  --input-height: 36px;
  --input-radius: var(--mantine-radius-default);
  --input-padding: calc(var(--input-height) / 3);
  --input-padding-inline-start: var(--input-padding);
  --input-padding-inline-end: var(--input-padding);
  --input-placeholder-color: var(--mantine-color-placeholder);
  --input-color: var(--mantine-color-text);

  transition: border-color 100ms ease;
  color: var(--mantine-color-text);
  border: 1px solid var(--input-bd);
  background-color: var(--input-bg);
  font-family: var(--mantine-font-family);
  font-size: var(--mantine-font-size-sm);
  min-height: var(--input-height) !important;
  border-radius: var(--input-radius);
  --input-line-height: calc(
    var(--mantine-line-height) * var(--mantine-font-size-sm) + 2px
  );
  line-height: var(--input-line-height);

  [data-error='true'] & {
    --input-bd: var(--mantine-color-error);
  }

  /** Set 16px font size on ios to prevent zoom on focus  https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom */
  @supports (-webkit-touch-callout: none) {
    font-size: 1rem;
  }

  &:disabled,
  &[data-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.6;
    background-color: var(--input-disabled-bg);
    color: var(--input-disabled-color);
  }

  /* Required to be a separate selector to work in Firefox, can be merged with &:disabled once :has is supported */
  &:has(input:disabled) {
    cursor: not-allowed;
    opacity: 0.6;
    background-color: var(--input-disabled-bg);
    color: var(--input-disabled-color);
  }

  span[data-slate-placeholder='true'] {
    color: var(--input-placeholder-color);
    opacity: 1 !important;
    top: unset !important;
  }

  --variable-pill-height: calc(
    var(--mantine-line-height) * var(--mantine-font-size-sm)
  );

  .slate-variable_input {
    display: inline-block;
    height: var(--variable-pill-height);
  }
`
