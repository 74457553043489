import { Fragment, type PropsWithChildren } from 'react'
import { createGlobalStyle, css } from 'styled-components'

import { kuiThemeVars } from './_internal/theme'
import { KuiConfirmationModalProvider } from './useKuiConfirmation'

const baseThemeStyles = css`
  :root {
    --kui-font-weight-semibold: 500;
    --kui-font-weight-light: 300;

    --kui-color-emptyShade: #ffffff;
    --kui-color-lightShade: #f9f9f9;
    --kui-color-mediumShade: #f2f3f4;

    --kui-spacing-scale: 4px;

    --kui-manuever-size-1: calc(1 * var(--kui-spacing-scale));
    --kui-manuever-size-2: calc(2 * var(--kui-spacing-scale));
  }

  dl,
  dt,
  dd {
    margin: 0;
  }
`

const KuiThemeStyles = createGlobalStyle`
  ${baseThemeStyles}

  html, body {
    background-color: ${kuiThemeVars.colors.lightShade};
  }

  .mantine-Skeleton-root:where([data-visible]){
    cursor: wait;
  }
`

export function KuiThemeProvider({ children }: PropsWithChildren) {
  return (
    <Fragment>
      <KuiThemeStyles />

      <KuiConfirmationModalProvider>{children}</KuiConfirmationModalProvider>
    </Fragment>
  )
}
