import { type TDescendant, type TElement } from '@udecode/plate-common'

import { renderEnumValue } from 'utils/enums'

export function findRichTextElementsOfType({
  value,
  type,
}: {
  value: TElement[] | TDescendant[]
  type: string
}): TElement[] {
  const result: TElement[] = []

  for (const element of value) {
    if (element.type === type) {
      result.push(element as TElement)
    }

    if (Array.isArray(element.children)) {
      result.push(
        ...findRichTextElementsOfType({ value: element.children, type })
      )
    }
  }

  return result
}

export function isEmptyRichText(richText: TElement[] | undefined): boolean {
  return (
    !richText?.length ||
    richText.every(
      (node) =>
        'type' in node &&
        node.type === 'p' &&
        node.children.every((child) => 'text' in child && child.text === '')
    )
  )
}

export function prettifyVariableName(path: string): string {
  return path
    .split('.')
    .map((part, index) =>
      index > 0 ? renderEnumValue(part).toLowerCase() : renderEnumValue(part)
    )
    .join(' ')
}
