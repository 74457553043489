import { lazily } from 'react-lazily'

import {
  getGetCustomFormResponseQueryOptions,
  getGetDeliveryRouteQueryOptions,
} from '_autogenerated'
import { driverExperienceUrls } from 'routes/urls'
import { createRoute } from 'routing'

const {
  DriverExperienceRoutePage,
  DriverExperienceRouteStopPage,
  DriverExperienceChecklistPage,
  DriverExperienceKlassChecklistsPage,
} = lazily(() => import('./components'))

export const driverExperienceFullPageRoutes = [
  createRoute({
    route: driverExperienceUrls.route,
    Component: DriverExperienceRoutePage,
    prefetch: ({ routeId }) => [getGetDeliveryRouteQueryOptions(routeId)],
    fullPage: true,
  }),
  createRoute({
    route: driverExperienceUrls.route.stop,
    Component: DriverExperienceRouteStopPage,
    prefetch: ({ routeId }) => [getGetDeliveryRouteQueryOptions(routeId)],
    fullPage: true,
  }),
  createRoute({
    route: driverExperienceUrls.route.stop.checklist,
    Component: DriverExperienceChecklistPage,
    prefetch: ({ routeId, checklistId }) => [
      getGetDeliveryRouteQueryOptions(routeId),
      getGetCustomFormResponseQueryOptions(checklistId),
    ],
    fullPage: true,
  }),
  createRoute({
    route: driverExperienceUrls.route.stop.klassChecklists,
    Component: DriverExperienceKlassChecklistsPage,
    prefetch: ({ routeId }) => [getGetDeliveryRouteQueryOptions(routeId)],
    fullPage: true,
  }),
]
