export const isStaging = import.meta.env.VITE_KYUSU_ENVIRONMENT === 'staging'

export const isDemo = import.meta.env.VITE_KYUSU_ENVIRONMENT === 'demo'

export const isProduction =
  import.meta.env.VITE_KYUSU_ENVIRONMENT === 'production'

export const isDeployed = isStaging || isDemo || isProduction

export const isLocalDev = !isDeployed
