import '@mantine/carousel/styles.css'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/dropzone/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/spotlight/styles.css'

import {
  Badge,
  Button,
  createTheme,
  defaultVariantColorsResolver,
  Image,
  Input,
  MantineProvider,
  Pill,
  type CSSVariablesResolver,
  type DefaultMantineColor,
  type MantineColorsTuple,
} from '@mantine/core'
import { DatesProvider } from '@mantine/dates'
import { Notifications } from '@mantine/notifications'
import { lazy, Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { lazily } from 'react-lazily'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'

import { KuiThemeProvider } from 'components/kui'
import AccountingOAuthRedirect from 'components/Settings/AccountingOAuthRedirect/AccountingOAuthRedirect'
import { ApplicationErrorBoundary, RouteErrorBoundary } from 'ErrorBoundary'
import { MagicLinkPageRoot } from 'pages/MagicLink/MagicLinkPageRoot'
import { MoabQueryClientProvider } from 'providers/MoabQueryClientProvider'
import { accountingRoutes } from 'routes/Accounting'
import { customerRoutes } from 'routes/Customers'
import { dispatchRoutes } from 'routes/Dispatch'
import { driverExperienceFullPageRoutes } from 'routes/DriverExperience'
import { FourZeroFourPage } from 'routes/FourZeroFourPage'
import { inventoryRoutes } from 'routes/Inventory'
import { redirectRoutes } from 'routes/redirects'
import { reportingRoutes } from 'routes/Reporting'
import { settingsRoutes } from 'routes/Settings'

import classes from './App.module.css'
import { AuthContextProvider } from './components/Auth/AuthContext'
import { DashboardRoot } from './components/DashboardRoot'
import { KyusuLoaderFull } from './components/Kyusu/KyusuLoader/KyusuLoader'
import { ForgotPassword } from './components/Login/ForgotPassword'
import RedirectIfNoOutlet from './pages/RedirectIfNoOutlet/RedirectIfNoOutlet'

type ExtendedCustomColors = 'background-recessed' | DefaultMantineColor

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, MantineColorsTuple>
  }
}

const resolver: CSSVariablesResolver = () => ({
  variables: {
    '--mantine-color-body': 'white',
  },
  dark: {
    '--mantine-color-body': 'white',
  },
  light: {
    '--mantine-color-body': 'white',
  },
})

const theme = createTheme({
  variantColorResolver: (input) => {
    const defaultResolvedColors = defaultVariantColorsResolver(input)
    if (input.variant === 'transparent') {
      return {
        ...defaultResolvedColors,
        background: 'transparent',
      }
    }

    return defaultResolvedColors
  },
  components: {
    // adding a content variant
    Input: Input.extend({ classNames: classes }),

    Badge: Badge.extend({
      classNames: { label: classes.badge },
    }),

    Button: Button.extend({ defaultProps: { fw: 400 } }),
    Pill: Pill.extend({ defaultProps: { radius: '4px' } }),
    Image: Image.extend({ defaultProps: { radius: '4px' } }),
  },
  defaultRadius: 'sm',
  activeClassName: '',
  cursorType: 'pointer',
  colors: {
    backgroundRecessed: [
      '#F2F3F4',
      '#F2F3F4',
      '#F2F3F4',
      '#F2F3F4',
      '#F2F3F4',
      '#F2F3F4',
      '#F2F3F4',
      '#F2F3F4',
      '#F2F3F4',
      '#F2F3F4',
    ],
    background: [
      '#F9F9F9',
      '#F9F9F9',
      '#F9F9F9',
      '#F9F9F9',
      '#F9F9F9',
      '#F9F9F9',
      '#F9F9F9',
      '#F9F9F9',
      '#F9F9F9',
      '#F9F9F9',
    ],
  },
})

const UserList = lazy(() => import('./pages/User/UserListPage'))
const UserDetail = lazy(() => import('./pages/User/UserDetail'))
const { UserCreatePage, UserEditPage } = lazily(
  () => import('./pages/User/UserEdit')
)
const { OrderListPage } = lazily(() => import('./pages/Orders/OrderListPage'))
const OrderCreatePage = lazy(() => import('./pages/Orders/OrderCreatePage'))

const { OrderEditPage } = lazily(() => import('./pages/Orders/OrderEditPage'))
const OrderDetailPage = lazy(() => import('./pages/Orders/OrderDetailPage'))
const { OrderCheckoutPage } = lazily(
  () => import('./pages/Orders/OrderCheckoutPage')
)
const { OrderReceivingPage } = lazily(
  () => import('./pages/Orders/OrderReceivingPage')
)

const VendorListPage = lazy(() => import('./pages/Vendor/VendorListPage'))
const VendorDetailPage = lazy(
  () => import('./pages/Vendor/VendorDetail/VendorDetail')
)
const VendorEditPage = lazy(() => import('./pages/Vendor/VendorEdit'))
const VendorCreatePage = lazy(() => import('./pages/Vendor/VendorCreate'))

const Login = lazy(() => import('./components/Login/Login'))

const { MagicLinkOrderPage } = lazily(
  () => import('./pages/MagicLink/MagicLinkOrderPage')
)

const { InvoiceListPage } = lazily(
  () => import('./pages/Invoices/InvoiceListPage')
)
const { InvoiceDetailPage } = lazily(
  () => import('./pages/Invoices/InvoiceDetailPage')
)

const { PaymentListPage } = lazily(
  () => import('./pages/Payments/PaymentListPage')
)

const { PaymentDetailPage } = lazily(
  () => import('./pages/Payments/PaymentDetailPage')
)

const { DepositListPage } = lazily(
  () => import('./pages/Deposits/DepositListPage')
)

const { DepositDetailPage } = lazily(
  () => import('./pages/Deposits/DepositDetailPage')
)

const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    errorElement: <RouteErrorBoundary fullPage={true} />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: '/magic-link/:token',
        element: <MagicLinkPageRoot />,
        errorElement: <RouteErrorBoundary fullPage={true} />,
        children: [
          {
            path: 'orders/:orderId',
            element: <MagicLinkOrderPage />,
          },
        ],
      },
      {
        path: '/',
        element: <DashboardRoot />,
        children: [
          {
            element: <RedirectIfNoOutlet path='/orders' />,
            index: true,
          },
          {
            path: 'users',
            element: <UserList />,
            children: [
              {
                path: 'create',
                element: <UserCreatePage />,
              },
              {
                path: ':id',
                element: <UserDetail />,
              },
              {
                path: ':id/edit',
                element: <UserEditPage />,
              },
            ],
          },
          {
            path: 'orders',
            element: <OrderListPage />,
            children: [
              {
                path: 'create',
                element: <OrderCreatePage />,
              },
              {
                path: ':id/edit',
                element: <OrderEditPage />,
              },
              {
                path: ':id/payment',
                element: <OrderCheckoutPage />,
              },
              {
                path: ':id/receiving',
                element: <OrderReceivingPage />,
              },
              {
                path: ':id',
                element: <OrderDetailPage />,
              },
              {
                path: ':id/:tab',
                element: <OrderDetailPage />,
              },
            ],
          },
          {
            path: 'billing',
            element: <RedirectIfNoOutlet path='/billing/invoices' />,
            children: [
              {
                path: 'invoices',
                element: <InvoiceListPage />,
              },
              {
                path: 'invoices/:id',
                element: <InvoiceDetailPage />,
              },
              {
                path: 'payments',
                element: <PaymentListPage />,
              },
              {
                path: 'payments/:id',
                element: <PaymentDetailPage />,
              },
              {
                path: 'deposits',
                element: <DepositListPage />,
              },
              {
                path: 'deposits/:id',
                element: <DepositDetailPage />,
              },
            ],
          },
          {
            path: 'vendors',
            element: <VendorListPage />,
            children: [
              {
                path: ':id',
                element: <VendorDetailPage />,
              },
              {
                path: 'create',
                element: <VendorCreatePage />,
              },
              {
                path: ':id/edit',
                element: <VendorEditPage />,
              },
            ],
          },
          {
            path: '/settings/accounting-oauth',
            element: <AccountingOAuthRedirect />,
          },
          ...dispatchRoutes,
          ...inventoryRoutes,
          ...customerRoutes,
          ...accountingRoutes,
          ...reportingRoutes,
          ...settingsRoutes,
        ],
      },
      ...driverExperienceFullPageRoutes,
      ...redirectRoutes,
      {
        path: '*',
        element: <FourZeroFourPage />,
      },
    ],
  },
])

function App() {
  return (
    <MantineProvider theme={theme} cssVariablesResolver={resolver}>
      <DatesProvider settings={{ locale: 'en' }}>
        <HelmetProvider>
          <MoabQueryClientProvider>
            <AuthContextProvider>
              <KuiThemeProvider>
                <ApplicationErrorBoundary>
                  <Suspense fallback={<KyusuLoaderFull />}>
                    <Notifications position='top-right' limit={3} />
                    <RouterProvider router={router} />
                  </Suspense>
                </ApplicationErrorBoundary>
              </KuiThemeProvider>
            </AuthContextProvider>
          </MoabQueryClientProvider>
        </HelmetProvider>
      </DatesProvider>
    </MantineProvider>
  )
}

export default App
