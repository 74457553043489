import { lazily } from 'react-lazily'

import { createRoute } from 'routing'

import { businessSettingsRoutes } from './Business'
import { communicationSettingsRoutes } from './Communications'
import { configurationSettingsRoutes } from './Configurations'
import { integrationSettingsRoutes } from './Integrations'
import { pricingSettingsRoutes } from './Pricing'
import { settingsUrls } from './settingsUrls'

const { UserSettingsPage, EditUserSettingsPage } = lazily(
  () => import('./UserSettingsPage')
)

export const settingsRoutes = [
  createRoute({
    route: settingsUrls.user,
    Component: UserSettingsPage,
  }),
  createRoute({
    route: settingsUrls.user.edit,
    Component: EditUserSettingsPage,
  }),
  ...businessSettingsRoutes,
  ...configurationSettingsRoutes,
  ...pricingSettingsRoutes,
  ...communicationSettingsRoutes,
  ...integrationSettingsRoutes,
]
