import './logrocketSetup'
import './googleAnalyticsSetup'
import * as Sentry from '@sentry/react'
import { StrictMode, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { isDeployed } from './utils/helpers'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

Sentry.init({
  dsn: 'https://365a0cc09f2b0ad5d3401f743a6ac96c@o4506424267177984.ingest.us.sentry.io/4506855716159488',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: ['api.trymoab.com'],
      networkDetailDenyUrls: ['/auth/login', /\/profile\/users\/.*?\/password/],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.01,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_API_URL],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,

  enabled: isDeployed,

  environment: import.meta.env.VITE_KYUSU_ENVIRONMENT,
  release: import.meta.env.VITE_KYUSU_BUILD,
})

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
