import { Progress, RemoveScroll } from '@mantine/core'
import { type ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import { kuiThemeVars } from './_internal/theme'
import {
  KuiActionList,
  KuiActionListItem,
  type KuiAction,
} from './KuiActionList'
import { KuiFlex } from './KuiFlex'
import { mixKuiPad } from './KuiPad'
import { KuiText } from './KuiText'

const KuiFullPageRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  max-height: auto;
  flex: 0 0 100%;
  width: 100%;
`

const KuiFullPageHeader = styled.div`
  background-color: ${kuiThemeVars.colors.mediumShade};
  border-bottom: 1px solid var(--mantine-color-gray-3);

  flex-shrink: 0;
`

const KuiFullPageBody = styled.section`
  height: 100%;
  overflow-y: auto;
  ${mixKuiPad({ size: 'md' })}
`

const KuiFullPageFooter = styled.div`
  border-top: 2px solid var(--mantine-color-gray-3);
  flex-shrink: 0;

  ${mixKuiPad({ size: 'md' })}
`

export type KuiFullPageProps = {
  title: string
  leftAction?: KuiAction
  actions?: KuiAction[]

  footerActions?: KuiAction[]

  progress?: number

  children: ReactNode
}

export function KuiFullPage({
  title,
  leftAction,
  actions = [],
  footerActions = [],
  progress,
  children,
}: KuiFullPageProps) {
  return (
    <RemoveScroll>
      <KuiFullPageRoot>
        <Helmet>
          <title>{title} — Moab</title>
        </Helmet>

        <KuiFullPageHeader>
          <KuiFlex
            justifyContent='spaceBetween'
            gapSize='xs'
            padding={{
              size: 'xs',
            }}
          >
            <KuiFlex gapSize='xs'>
              {leftAction && (
                <KuiActionListItem size='xs' action={leftAction} />
              )}

              <KuiText.p size='md'>{title}</KuiText.p>
            </KuiFlex>

            {actions.length > 0 && <KuiActionList size='s' actions={actions} />}
          </KuiFlex>

          {typeof progress === 'number' && (
            <Progress value={progress} radius={0} color='blue' />
          )}
        </KuiFullPageHeader>

        <KuiFullPageBody>{children}</KuiFullPageBody>

        {footerActions.length > 0 && (
          <KuiFullPageFooter>
            <KuiActionList
              size='m'
              variant='outline'
              actions={footerActions}
              fullWidth={true}
              wrap={false}
            />
          </KuiFullPageFooter>
        )}
      </KuiFullPageRoot>
    </RemoveScroll>
  )
}
