/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  AccountMappingsCreateSchema,
  AccountMappingsDumpSchema,
  AccountMappingsParams,
  AccountingAccessDumpSchemaWrapper,
  BaseLedgerAccountSchema,
  DepreciationSettingCreateSchema,
  DepreciationSettingDumpSchema,
  DepreciationSettingUpdateSchema,
  DepreciationSettingsParams,
  InventoryProfileCreateSchema,
  InventoryProfileDumpSchema,
  InventoryProfileListParams,
  JournalEntriesSyncSchema,
  JournalEntryHydratedDumpSchema,
  JournalEntryListParams,
  JournalEntryUpdateSchema,
  LedgerAccountDumpSchema,
  LedgerAccountsParams,
  PaginatedDepreciationSettingResponseSchema,
  PaginatedInventoryProfileSchema,
  PaginatedJournalEntrySchema,
  PaginatedLedgerAccountSchema,
  PaginatedRemoteAccountSchema,
  PaginatedRemoteObjectSchema,
  QBOAuth,
  QBOAuthUrl,
  RemoteAccountCreateSchema,
  RemoteAccountDumpSchema,
  RemoteAccountsParams,
  RemoteObjectBaseSchema,
  RemoteObjectDumpSchema,
  RemoteObjectsParams,
  SuccessResponse
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const qboSetup = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<QBOAuthUrl>(
      {url: `/accounting/qbo/setup`, method: 'GET', signal
    },
      options);
    }


export const getQboSetupQueryKey = () => {
    return [`/accounting/qbo/setup`] as const;
    }


export const getQboSetupQueryOptions = <TData = Awaited<ReturnType<typeof qboSetup>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qboSetup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQboSetupQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof qboSetup>>> = ({ signal }) => qboSetup(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof qboSetup>>, TError, TData> & { queryKey: QueryKey }
}

export type QboSetupQueryResult = NonNullable<Awaited<ReturnType<typeof qboSetup>>>
export type QboSetupQueryError = ErrorType<unknown>

export const useQboSetup = <TData = Awaited<ReturnType<typeof qboSetup>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof qboSetup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQboSetupQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getQboSetupSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof qboSetup>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof qboSetup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQboSetupQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof qboSetup>>> = ({ signal }) => qboSetup(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof qboSetup>>, TError, TData> & { queryKey: QueryKey }
}

export type QboSetupSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof qboSetup>>>
export type QboSetupSuspenseQueryError = ErrorType<unknown>

export const useQboSetupSuspense = <TData = Awaited<ReturnType<typeof qboSetup>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof qboSetup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQboSetupSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const qboAuth = (
    qBOAuth: BodyType<QBOAuth>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/accounting/qbo/auth`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: qBOAuth
    },
      options);
    }


export const getAccountingAccess = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<AccountingAccessDumpSchemaWrapper>(
      {url: `/accounting/access`, method: 'GET', signal
    },
      options);
    }


export const getGetAccountingAccessQueryKey = () => {
    return [`/accounting/access`] as const;
    }


export const getGetAccountingAccessQueryOptions = <TData = Awaited<ReturnType<typeof getAccountingAccess>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountingAccess>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAccountingAccessQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountingAccess>>> = ({ signal }) => getAccountingAccess(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAccountingAccess>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAccountingAccessQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountingAccess>>>
export type GetAccountingAccessQueryError = ErrorType<unknown>

export const useGetAccountingAccess = <TData = Awaited<ReturnType<typeof getAccountingAccess>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountingAccess>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAccountingAccessQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAccountingAccessSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getAccountingAccess>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAccountingAccess>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAccountingAccessQueryKey();



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountingAccess>>> = ({ signal }) => getAccountingAccess(requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAccountingAccess>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAccountingAccessSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountingAccess>>>
export type GetAccountingAccessSuspenseQueryError = ErrorType<unknown>

export const useGetAccountingAccessSuspense = <TData = Awaited<ReturnType<typeof getAccountingAccess>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAccountingAccess>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAccountingAccessSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const disconnectAccountingAccess = (

 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/accounting/access`, method: 'DELETE'
    },
      options);
    }


export const depreciationSettings = (
    params?: DepreciationSettingsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedDepreciationSettingResponseSchema>(
      {url: `/accounting/depreciation_settings`, method: 'GET',
        params, signal
    },
      options);
    }


export const getDepreciationSettingsQueryKey = (params?: DepreciationSettingsParams,) => {
    return [`/accounting/depreciation_settings`, ...(params ? [params]: [])] as const;
    }


export const getDepreciationSettingsQueryOptions = <TData = Awaited<ReturnType<typeof depreciationSettings>>, TError = ErrorType<unknown>>(params?: DepreciationSettingsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof depreciationSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDepreciationSettingsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof depreciationSettings>>> = ({ signal }) => depreciationSettings(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof depreciationSettings>>, TError, TData> & { queryKey: QueryKey }
}

export type DepreciationSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof depreciationSettings>>>
export type DepreciationSettingsQueryError = ErrorType<unknown>

export const useDepreciationSettings = <TData = Awaited<ReturnType<typeof depreciationSettings>>, TError = ErrorType<unknown>>(
 params?: DepreciationSettingsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof depreciationSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDepreciationSettingsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDepreciationSettingsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof depreciationSettings>>, TError = ErrorType<unknown>>(params?: DepreciationSettingsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof depreciationSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDepreciationSettingsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof depreciationSettings>>> = ({ signal }) => depreciationSettings(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof depreciationSettings>>, TError, TData> & { queryKey: QueryKey }
}

export type DepreciationSettingsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof depreciationSettings>>>
export type DepreciationSettingsSuspenseQueryError = ErrorType<unknown>

export const useDepreciationSettingsSuspense = <TData = Awaited<ReturnType<typeof depreciationSettings>>, TError = ErrorType<unknown>>(
 params?: DepreciationSettingsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof depreciationSettings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDepreciationSettingsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createDepreciationSetting = (
    depreciationSettingCreateSchema: BodyType<DepreciationSettingCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DepreciationSettingDumpSchema>(
      {url: `/accounting/depreciation_settings`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: depreciationSettingCreateSchema
    },
      options);
    }


export const updateDepreciationSetting = (
    id: string,
    depreciationSettingUpdateSchema: BodyType<DepreciationSettingUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DepreciationSettingDumpSchema>(
      {url: `/accounting/depreciation_settings/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: depreciationSettingUpdateSchema
    },
      options);
    }


export const depreciationSetting = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<DepreciationSettingDumpSchema>(
      {url: `/accounting/depreciation_settings/${id}`, method: 'GET', signal
    },
      options);
    }


export const getDepreciationSettingQueryKey = (id: string,) => {
    return [`/accounting/depreciation_settings/${id}`] as const;
    }


export const getDepreciationSettingQueryOptions = <TData = Awaited<ReturnType<typeof depreciationSetting>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof depreciationSetting>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDepreciationSettingQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof depreciationSetting>>> = ({ signal }) => depreciationSetting(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof depreciationSetting>>, TError, TData> & { queryKey: QueryKey }
}

export type DepreciationSettingQueryResult = NonNullable<Awaited<ReturnType<typeof depreciationSetting>>>
export type DepreciationSettingQueryError = ErrorType<unknown>

export const useDepreciationSetting = <TData = Awaited<ReturnType<typeof depreciationSetting>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof depreciationSetting>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDepreciationSettingQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getDepreciationSettingSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof depreciationSetting>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof depreciationSetting>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDepreciationSettingQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof depreciationSetting>>> = ({ signal }) => depreciationSetting(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof depreciationSetting>>, TError, TData> & { queryKey: QueryKey }
}

export type DepreciationSettingSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof depreciationSetting>>>
export type DepreciationSettingSuspenseQueryError = ErrorType<unknown>

export const useDepreciationSettingSuspense = <TData = Awaited<ReturnType<typeof depreciationSetting>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof depreciationSetting>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDepreciationSettingSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const deleteDepreciationSetting = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/accounting/depreciation_settings/${id}`, method: 'DELETE'
    },
      options);
    }


export const accountMappings = (
    params?: AccountMappingsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<AccountMappingsDumpSchema>(
      {url: `/accounting/mappings`, method: 'GET',
        params, signal
    },
      options);
    }


export const getAccountMappingsQueryKey = (params?: AccountMappingsParams,) => {
    return [`/accounting/mappings`, ...(params ? [params]: [])] as const;
    }


export const getAccountMappingsQueryOptions = <TData = Awaited<ReturnType<typeof accountMappings>>, TError = ErrorType<unknown>>(params?: AccountMappingsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof accountMappings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccountMappingsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof accountMappings>>> = ({ signal }) => accountMappings(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof accountMappings>>, TError, TData> & { queryKey: QueryKey }
}

export type AccountMappingsQueryResult = NonNullable<Awaited<ReturnType<typeof accountMappings>>>
export type AccountMappingsQueryError = ErrorType<unknown>

export const useAccountMappings = <TData = Awaited<ReturnType<typeof accountMappings>>, TError = ErrorType<unknown>>(
 params?: AccountMappingsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof accountMappings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAccountMappingsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAccountMappingsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof accountMappings>>, TError = ErrorType<unknown>>(params?: AccountMappingsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof accountMappings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAccountMappingsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof accountMappings>>> = ({ signal }) => accountMappings(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof accountMappings>>, TError, TData> & { queryKey: QueryKey }
}

export type AccountMappingsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof accountMappings>>>
export type AccountMappingsSuspenseQueryError = ErrorType<unknown>

export const useAccountMappingsSuspense = <TData = Awaited<ReturnType<typeof accountMappings>>, TError = ErrorType<unknown>>(
 params?: AccountMappingsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof accountMappings>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAccountMappingsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createAccountMappings = (
    accountMappingsCreateSchema: BodyType<AccountMappingsCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<AccountMappingsDumpSchema>(
      {url: `/accounting/mappings`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: accountMappingsCreateSchema
    },
      options);
    }


export const updateAccountMappings = (
    accountMappingsCreateSchema: BodyType<AccountMappingsCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<AccountMappingsDumpSchema>(
      {url: `/accounting/mappings`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: accountMappingsCreateSchema
    },
      options);
    }


export const inventoryProfileList = (
    params?: InventoryProfileListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedInventoryProfileSchema>(
      {url: `/accounting/inventory-profiles`, method: 'GET',
        params, signal
    },
      options);
    }


export const getInventoryProfileListQueryKey = (params?: InventoryProfileListParams,) => {
    return [`/accounting/inventory-profiles`, ...(params ? [params]: [])] as const;
    }


export const getInventoryProfileListQueryOptions = <TData = Awaited<ReturnType<typeof inventoryProfileList>>, TError = ErrorType<unknown>>(params?: InventoryProfileListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof inventoryProfileList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getInventoryProfileListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof inventoryProfileList>>> = ({ signal }) => inventoryProfileList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof inventoryProfileList>>, TError, TData> & { queryKey: QueryKey }
}

export type InventoryProfileListQueryResult = NonNullable<Awaited<ReturnType<typeof inventoryProfileList>>>
export type InventoryProfileListQueryError = ErrorType<unknown>

export const useInventoryProfileList = <TData = Awaited<ReturnType<typeof inventoryProfileList>>, TError = ErrorType<unknown>>(
 params?: InventoryProfileListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof inventoryProfileList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getInventoryProfileListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getInventoryProfileListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof inventoryProfileList>>, TError = ErrorType<unknown>>(params?: InventoryProfileListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof inventoryProfileList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getInventoryProfileListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof inventoryProfileList>>> = ({ signal }) => inventoryProfileList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof inventoryProfileList>>, TError, TData> & { queryKey: QueryKey }
}

export type InventoryProfileListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof inventoryProfileList>>>
export type InventoryProfileListSuspenseQueryError = ErrorType<unknown>

export const useInventoryProfileListSuspense = <TData = Awaited<ReturnType<typeof inventoryProfileList>>, TError = ErrorType<unknown>>(
 params?: InventoryProfileListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof inventoryProfileList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getInventoryProfileListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createInventoryProfile = (
    inventoryProfileCreateSchema: BodyType<InventoryProfileCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<PaginatedInventoryProfileSchema>(
      {url: `/accounting/inventory-profiles`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: inventoryProfileCreateSchema
    },
      options);
    }


export const getInventoryProfile = (
    inventoryProfileId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<InventoryProfileDumpSchema>(
      {url: `/accounting/inventory-profiles/${inventoryProfileId}`, method: 'GET', signal
    },
      options);
    }


export const getGetInventoryProfileQueryKey = (inventoryProfileId: string,) => {
    return [`/accounting/inventory-profiles/${inventoryProfileId}`] as const;
    }


export const getGetInventoryProfileQueryOptions = <TData = Awaited<ReturnType<typeof getInventoryProfile>>, TError = ErrorType<unknown>>(inventoryProfileId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInventoryProfile>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInventoryProfileQueryKey(inventoryProfileId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInventoryProfile>>> = ({ signal }) => getInventoryProfile(inventoryProfileId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(inventoryProfileId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInventoryProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInventoryProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getInventoryProfile>>>
export type GetInventoryProfileQueryError = ErrorType<unknown>

export const useGetInventoryProfile = <TData = Awaited<ReturnType<typeof getInventoryProfile>>, TError = ErrorType<unknown>>(
 inventoryProfileId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInventoryProfile>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInventoryProfileQueryOptions(inventoryProfileId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetInventoryProfileSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getInventoryProfile>>, TError = ErrorType<unknown>>(inventoryProfileId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInventoryProfile>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInventoryProfileQueryKey(inventoryProfileId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInventoryProfile>>> = ({ signal }) => getInventoryProfile(inventoryProfileId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(inventoryProfileId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInventoryProfile>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInventoryProfileSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getInventoryProfile>>>
export type GetInventoryProfileSuspenseQueryError = ErrorType<unknown>

export const useGetInventoryProfileSuspense = <TData = Awaited<ReturnType<typeof getInventoryProfile>>, TError = ErrorType<unknown>>(
 inventoryProfileId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInventoryProfile>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInventoryProfileSuspenseQueryOptions(inventoryProfileId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateInventoryProfile = (
    inventoryProfileId: string,
    inventoryProfileCreateSchema: BodyType<InventoryProfileCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<InventoryProfileDumpSchema>(
      {url: `/accounting/inventory-profiles/${inventoryProfileId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: inventoryProfileCreateSchema
    },
      options);
    }


export const deleteInventoryProfile = (
    inventoryProfileId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/accounting/inventory-profiles/${inventoryProfileId}`, method: 'DELETE'
    },
      options);
    }


export const ledgerAccounts = (
    params?: LedgerAccountsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedLedgerAccountSchema>(
      {url: `/accounting/ledger/accounts`, method: 'GET',
        params, signal
    },
      options);
    }


export const getLedgerAccountsQueryKey = (params?: LedgerAccountsParams,) => {
    return [`/accounting/ledger/accounts`, ...(params ? [params]: [])] as const;
    }


export const getLedgerAccountsQueryOptions = <TData = Awaited<ReturnType<typeof ledgerAccounts>>, TError = ErrorType<unknown>>(params?: LedgerAccountsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ledgerAccounts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLedgerAccountsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof ledgerAccounts>>> = ({ signal }) => ledgerAccounts(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ledgerAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type LedgerAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof ledgerAccounts>>>
export type LedgerAccountsQueryError = ErrorType<unknown>

export const useLedgerAccounts = <TData = Awaited<ReturnType<typeof ledgerAccounts>>, TError = ErrorType<unknown>>(
 params?: LedgerAccountsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ledgerAccounts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLedgerAccountsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getLedgerAccountsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof ledgerAccounts>>, TError = ErrorType<unknown>>(params?: LedgerAccountsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ledgerAccounts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLedgerAccountsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof ledgerAccounts>>> = ({ signal }) => ledgerAccounts(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof ledgerAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type LedgerAccountsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof ledgerAccounts>>>
export type LedgerAccountsSuspenseQueryError = ErrorType<unknown>

export const useLedgerAccountsSuspense = <TData = Awaited<ReturnType<typeof ledgerAccounts>>, TError = ErrorType<unknown>>(
 params?: LedgerAccountsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ledgerAccounts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLedgerAccountsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const ledgerAccountCreate = (
    baseLedgerAccountSchema: BodyType<BaseLedgerAccountSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<LedgerAccountDumpSchema>(
      {url: `/accounting/ledger/accounts`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: baseLedgerAccountSchema
    },
      options);
    }


export const ledgerAccount = (
    accountId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<LedgerAccountDumpSchema>(
      {url: `/accounting/ledger/accounts/${accountId}`, method: 'GET', signal
    },
      options);
    }


export const getLedgerAccountQueryKey = (accountId: string,) => {
    return [`/accounting/ledger/accounts/${accountId}`] as const;
    }


export const getLedgerAccountQueryOptions = <TData = Awaited<ReturnType<typeof ledgerAccount>>, TError = ErrorType<unknown>>(accountId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ledgerAccount>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLedgerAccountQueryKey(accountId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof ledgerAccount>>> = ({ signal }) => ledgerAccount(accountId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ledgerAccount>>, TError, TData> & { queryKey: QueryKey }
}

export type LedgerAccountQueryResult = NonNullable<Awaited<ReturnType<typeof ledgerAccount>>>
export type LedgerAccountQueryError = ErrorType<unknown>

export const useLedgerAccount = <TData = Awaited<ReturnType<typeof ledgerAccount>>, TError = ErrorType<unknown>>(
 accountId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ledgerAccount>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLedgerAccountQueryOptions(accountId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getLedgerAccountSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof ledgerAccount>>, TError = ErrorType<unknown>>(accountId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ledgerAccount>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLedgerAccountQueryKey(accountId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof ledgerAccount>>> = ({ signal }) => ledgerAccount(accountId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof ledgerAccount>>, TError, TData> & { queryKey: QueryKey }
}

export type LedgerAccountSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof ledgerAccount>>>
export type LedgerAccountSuspenseQueryError = ErrorType<unknown>

export const useLedgerAccountSuspense = <TData = Awaited<ReturnType<typeof ledgerAccount>>, TError = ErrorType<unknown>>(
 accountId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof ledgerAccount>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getLedgerAccountSuspenseQueryOptions(accountId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const ledgerAccountUpdate = (
    accountId: string,
    baseLedgerAccountSchema: BodyType<BaseLedgerAccountSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<LedgerAccountDumpSchema>(
      {url: `/accounting/ledger/accounts/${accountId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: baseLedgerAccountSchema
    },
      options);
    }


export const ledgerAccountDelete = (
    accountId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/accounting/ledger/accounts/${accountId}`, method: 'DELETE'
    },
      options);
    }


export const remoteAccounts = (
    params?: RemoteAccountsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedRemoteAccountSchema>(
      {url: `/accounting/remote/accounts`, method: 'GET',
        params, signal
    },
      options);
    }


export const getRemoteAccountsQueryKey = (params?: RemoteAccountsParams,) => {
    return [`/accounting/remote/accounts`, ...(params ? [params]: [])] as const;
    }


export const getRemoteAccountsQueryOptions = <TData = Awaited<ReturnType<typeof remoteAccounts>>, TError = ErrorType<unknown>>(params?: RemoteAccountsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof remoteAccounts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemoteAccountsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof remoteAccounts>>> = ({ signal }) => remoteAccounts(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof remoteAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type RemoteAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof remoteAccounts>>>
export type RemoteAccountsQueryError = ErrorType<unknown>

export const useRemoteAccounts = <TData = Awaited<ReturnType<typeof remoteAccounts>>, TError = ErrorType<unknown>>(
 params?: RemoteAccountsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof remoteAccounts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemoteAccountsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRemoteAccountsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof remoteAccounts>>, TError = ErrorType<unknown>>(params?: RemoteAccountsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteAccounts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemoteAccountsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof remoteAccounts>>> = ({ signal }) => remoteAccounts(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteAccounts>>, TError, TData> & { queryKey: QueryKey }
}

export type RemoteAccountsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof remoteAccounts>>>
export type RemoteAccountsSuspenseQueryError = ErrorType<unknown>

export const useRemoteAccountsSuspense = <TData = Awaited<ReturnType<typeof remoteAccounts>>, TError = ErrorType<unknown>>(
 params?: RemoteAccountsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteAccounts>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemoteAccountsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const remoteAccountCreate = (
    remoteAccountCreateSchema: BodyType<RemoteAccountCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<RemoteAccountDumpSchema>(
      {url: `/accounting/remote/accounts`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: remoteAccountCreateSchema
    },
      options);
    }


export const remoteAccount = (
    accountId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<RemoteAccountDumpSchema>(
      {url: `/accounting/remote/accounts/${accountId}`, method: 'GET', signal
    },
      options);
    }


export const getRemoteAccountQueryKey = (accountId: string,) => {
    return [`/accounting/remote/accounts/${accountId}`] as const;
    }


export const getRemoteAccountQueryOptions = <TData = Awaited<ReturnType<typeof remoteAccount>>, TError = ErrorType<unknown>>(accountId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof remoteAccount>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemoteAccountQueryKey(accountId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof remoteAccount>>> = ({ signal }) => remoteAccount(accountId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof remoteAccount>>, TError, TData> & { queryKey: QueryKey }
}

export type RemoteAccountQueryResult = NonNullable<Awaited<ReturnType<typeof remoteAccount>>>
export type RemoteAccountQueryError = ErrorType<unknown>

export const useRemoteAccount = <TData = Awaited<ReturnType<typeof remoteAccount>>, TError = ErrorType<unknown>>(
 accountId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof remoteAccount>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemoteAccountQueryOptions(accountId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRemoteAccountSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof remoteAccount>>, TError = ErrorType<unknown>>(accountId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteAccount>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemoteAccountQueryKey(accountId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof remoteAccount>>> = ({ signal }) => remoteAccount(accountId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(accountId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteAccount>>, TError, TData> & { queryKey: QueryKey }
}

export type RemoteAccountSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof remoteAccount>>>
export type RemoteAccountSuspenseQueryError = ErrorType<unknown>

export const useRemoteAccountSuspense = <TData = Awaited<ReturnType<typeof remoteAccount>>, TError = ErrorType<unknown>>(
 accountId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteAccount>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemoteAccountSuspenseQueryOptions(accountId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const remoteObjects = (
    params: RemoteObjectsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedRemoteObjectSchema>(
      {url: `/accounting/remote/objects`, method: 'GET',
        params, signal
    },
      options);
    }


export const getRemoteObjectsQueryKey = (params: RemoteObjectsParams,) => {
    return [`/accounting/remote/objects`, ...(params ? [params]: [])] as const;
    }


export const getRemoteObjectsQueryOptions = <TData = Awaited<ReturnType<typeof remoteObjects>>, TError = ErrorType<unknown>>(params: RemoteObjectsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof remoteObjects>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemoteObjectsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof remoteObjects>>> = ({ signal }) => remoteObjects(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof remoteObjects>>, TError, TData> & { queryKey: QueryKey }
}

export type RemoteObjectsQueryResult = NonNullable<Awaited<ReturnType<typeof remoteObjects>>>
export type RemoteObjectsQueryError = ErrorType<unknown>

export const useRemoteObjects = <TData = Awaited<ReturnType<typeof remoteObjects>>, TError = ErrorType<unknown>>(
 params: RemoteObjectsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof remoteObjects>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemoteObjectsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRemoteObjectsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof remoteObjects>>, TError = ErrorType<unknown>>(params: RemoteObjectsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteObjects>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemoteObjectsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof remoteObjects>>> = ({ signal }) => remoteObjects(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteObjects>>, TError, TData> & { queryKey: QueryKey }
}

export type RemoteObjectsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof remoteObjects>>>
export type RemoteObjectsSuspenseQueryError = ErrorType<unknown>

export const useRemoteObjectsSuspense = <TData = Awaited<ReturnType<typeof remoteObjects>>, TError = ErrorType<unknown>>(
 params: RemoteObjectsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteObjects>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemoteObjectsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const remoteObjectCreate = (
    remoteObjectBaseSchema: BodyType<RemoteObjectBaseSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<RemoteObjectDumpSchema>(
      {url: `/accounting/remote/objects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: remoteObjectBaseSchema
    },
      options);
    }


export const remoteObject = (
    objectId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<RemoteObjectDumpSchema>(
      {url: `/accounting/remote/objects/${objectId}`, method: 'GET', signal
    },
      options);
    }


export const getRemoteObjectQueryKey = (objectId: string,) => {
    return [`/accounting/remote/objects/${objectId}`] as const;
    }


export const getRemoteObjectQueryOptions = <TData = Awaited<ReturnType<typeof remoteObject>>, TError = ErrorType<unknown>>(objectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof remoteObject>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemoteObjectQueryKey(objectId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof remoteObject>>> = ({ signal }) => remoteObject(objectId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(objectId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof remoteObject>>, TError, TData> & { queryKey: QueryKey }
}

export type RemoteObjectQueryResult = NonNullable<Awaited<ReturnType<typeof remoteObject>>>
export type RemoteObjectQueryError = ErrorType<unknown>

export const useRemoteObject = <TData = Awaited<ReturnType<typeof remoteObject>>, TError = ErrorType<unknown>>(
 objectId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof remoteObject>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemoteObjectQueryOptions(objectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getRemoteObjectSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof remoteObject>>, TError = ErrorType<unknown>>(objectId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteObject>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRemoteObjectQueryKey(objectId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof remoteObject>>> = ({ signal }) => remoteObject(objectId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(objectId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteObject>>, TError, TData> & { queryKey: QueryKey }
}

export type RemoteObjectSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof remoteObject>>>
export type RemoteObjectSuspenseQueryError = ErrorType<unknown>

export const useRemoteObjectSuspense = <TData = Awaited<ReturnType<typeof remoteObject>>, TError = ErrorType<unknown>>(
 objectId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof remoteObject>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRemoteObjectSuspenseQueryOptions(objectId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const journalEntryList = (
    params?: JournalEntryListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedJournalEntrySchema>(
      {url: `/accounting/journal-entries`, method: 'GET',
        params, signal
    },
      options);
    }


export const getJournalEntryListQueryKey = (params?: JournalEntryListParams,) => {
    return [`/accounting/journal-entries`, ...(params ? [params]: [])] as const;
    }


export const getJournalEntryListQueryOptions = <TData = Awaited<ReturnType<typeof journalEntryList>>, TError = ErrorType<unknown>>(params?: JournalEntryListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof journalEntryList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJournalEntryListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof journalEntryList>>> = ({ signal }) => journalEntryList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof journalEntryList>>, TError, TData> & { queryKey: QueryKey }
}

export type JournalEntryListQueryResult = NonNullable<Awaited<ReturnType<typeof journalEntryList>>>
export type JournalEntryListQueryError = ErrorType<unknown>

export const useJournalEntryList = <TData = Awaited<ReturnType<typeof journalEntryList>>, TError = ErrorType<unknown>>(
 params?: JournalEntryListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof journalEntryList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getJournalEntryListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getJournalEntryListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof journalEntryList>>, TError = ErrorType<unknown>>(params?: JournalEntryListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof journalEntryList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJournalEntryListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof journalEntryList>>> = ({ signal }) => journalEntryList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof journalEntryList>>, TError, TData> & { queryKey: QueryKey }
}

export type JournalEntryListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof journalEntryList>>>
export type JournalEntryListSuspenseQueryError = ErrorType<unknown>

export const useJournalEntryListSuspense = <TData = Awaited<ReturnType<typeof journalEntryList>>, TError = ErrorType<unknown>>(
 params?: JournalEntryListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof journalEntryList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getJournalEntryListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getJournalEntry = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<JournalEntryHydratedDumpSchema>(
      {url: `/accounting/journal-entries/${id}`, method: 'GET', signal
    },
      options);
    }


export const getGetJournalEntryQueryKey = (id: string,) => {
    return [`/accounting/journal-entries/${id}`] as const;
    }


export const getGetJournalEntryQueryOptions = <TData = Awaited<ReturnType<typeof getJournalEntry>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getJournalEntry>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetJournalEntryQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getJournalEntry>>> = ({ signal }) => getJournalEntry(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getJournalEntry>>, TError, TData> & { queryKey: QueryKey }
}

export type GetJournalEntryQueryResult = NonNullable<Awaited<ReturnType<typeof getJournalEntry>>>
export type GetJournalEntryQueryError = ErrorType<unknown>

export const useGetJournalEntry = <TData = Awaited<ReturnType<typeof getJournalEntry>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getJournalEntry>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetJournalEntryQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetJournalEntrySuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getJournalEntry>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJournalEntry>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetJournalEntryQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getJournalEntry>>> = ({ signal }) => getJournalEntry(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJournalEntry>>, TError, TData> & { queryKey: QueryKey }
}

export type GetJournalEntrySuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getJournalEntry>>>
export type GetJournalEntrySuspenseQueryError = ErrorType<unknown>

export const useGetJournalEntrySuspense = <TData = Awaited<ReturnType<typeof getJournalEntry>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJournalEntry>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetJournalEntrySuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateJournalEntry = (
    id: string,
    journalEntryUpdateSchema: BodyType<JournalEntryUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<JournalEntryHydratedDumpSchema>(
      {url: `/accounting/journal-entries/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: journalEntryUpdateSchema
    },
      options);
    }


export const syncJournalEntries = (
    journalEntriesSyncSchema: BodyType<JournalEntriesSyncSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/accounting/journal-entries/sync`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: journalEntriesSyncSchema
    },
      options);
    }


