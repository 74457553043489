export function renderMoney(money: string | number | null | undefined): string {
  if (money === null || money === undefined || money === '') {
    return '-'
  }

  const moneyString = money.toString()

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(parseFloat(moneyString))
}

export function renderPhoneNumber(phoneNumber: string | null | undefined) {
  if (!phoneNumber) {
    return
  }

  // Remove all non-digit characters except '+'
  const cleaned = phoneNumber.replace(/[^\d+]/g, '')

  // Check if the input starts with +1 and has 11 digits, or just has 10 digits
  const match = cleaned.match(/^(\+1|1)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    const countryCode = match[1] ? '+1 ' : ''

    return `${countryCode}(${match[2]}) ${match[3]}-${match[4]}`
  }

  return phoneNumber // Return original input if it doesn't match expected format
}
