import classnames from 'classnames'
import { kebabCase } from 'lodash'
import { forwardRef, type ForwardedRef, type ReactNode } from 'react'
import flattenChildren from 'react-keyed-flatten-children'
import styled, { css } from 'styled-components'

import { kuiThemeVars, type KuiThemeSpacingSize } from './_internal/theme'
import { mixKuiPad, type MixKuiPadProps } from './KuiPad'

type KuiFlexAlignItems =
  | 'stretch'
  | 'flexStart'
  | 'flexEnd'
  | 'center'
  | 'baseline'
type KuiFlexDirection = 'row' | 'rowReverse' | 'column' | 'columnReverse'
export type KuiFlexJustifyContent =
  | 'flexStart'
  | 'flexEnd'
  | 'center'
  | 'spaceBetween'
  | 'spaceAround'
  | 'spaceEvenly'

export type KuiFlexGapSize = KuiThemeSpacingSize

export type KuiFlexProps = MixKuiFlexProps & {
  /** @default 'none' */
  gapSize?: KuiFlexGapSize

  /** @default the value of the `gapSize` prop, defaults to 'none'. */
  gapSizeVertical?: KuiFlexGapSize

  /** @default the value of the `gapSize` prop, defaults to 'none'. */
  gapSizeHorizontal?: KuiFlexGapSize

  /** @default false */
  wrap?: boolean

  grow?: number

  shrink?: number

  padding?: MixKuiPadProps

  fullHeight?: boolean

  children?: ReactNode
}

type KuiFlexRootProps = {
  $mixKuiFlexProps: MixKuiFlexProps
  $gapSizeVertical: KuiFlexGapSize
  $gapSizeHorizontal: KuiFlexGapSize
  $grow?: number
  $shrink?: number
  $paddingProps?: MixKuiPadProps
}

export type MixKuiFlexProps = {
  /** @default false */
  inline?: boolean
  /** @default `direction === 'row' ? 'center' : 'stretch'` */
  alignItems?: KuiFlexAlignItems
  /** @default 'flexStart'*/
  justifyContent?: KuiFlexJustifyContent
  /** @default 'row' */
  direction?: KuiFlexDirection
}

export const mixKuiFlex = ({
  inline = false,
  direction = 'row',
  alignItems = direction === 'row' ? 'center' : 'stretch',
  justifyContent = 'flexStart',
}: MixKuiFlexProps) => css`
  display: ${inline ? 'inline-flex' : 'flex'};
  flex-direction: ${direction};
  align-items: ${kebabCase(alignItems)};
  justify-content: ${kebabCase(justifyContent)};
`

const KuiFlexRoot = styled.div<KuiFlexRootProps>`
  ${(p) => mixKuiFlex(p.$mixKuiFlexProps)}

  gap: ${(p) =>
    `${kuiThemeVars.spacingSizes[p.$gapSizeVertical]} ${kuiThemeVars.spacingSizes[p.$gapSizeHorizontal]}`};

  flex-grow: ${(p) => p.$grow};
  flex-shrink: ${(p) => p.$shrink};
  flex-basis: ${(p) => (p.$grow ? '0%' : null)};
  min-width: 0;
  max-width: 100%;

  &.KuiFlexRoot--fullHeight {
    height: 100%;
  }

  ${(p) => p.$paddingProps && mixKuiPad(p.$paddingProps)}

  &.KuiFlexRoot--wrap {
    flex-wrap: wrap;
  }
`

const KuiFlexImpl = forwardRef<HTMLDivElement, KuiFlexProps>(
  (
    {
      inline,
      alignItems,
      direction,
      justifyContent,
      gapSize = 'none',
      gapSizeVertical = gapSize,
      gapSizeHorizontal = gapSize,
      wrap = false,
      fullHeight,
      grow,
      shrink,
      padding,
      children,
    }: KuiFlexProps,
    forwardRef: ForwardedRef<HTMLDivElement>
  ) => {
    const flattenedChildren = flattenChildren(children)

    return (
      <KuiFlexRoot
        ref={forwardRef}
        $gapSizeVertical={gapSizeVertical}
        $gapSizeHorizontal={gapSizeHorizontal}
        $grow={grow}
        $shrink={shrink}
        $paddingProps={padding}
        $mixKuiFlexProps={{
          inline,
          alignItems,
          direction,
          justifyContent,
        }}
        className={classnames({
          'KuiFlexRoot--wrap': wrap === true,
          'KuiFlexRoot--fullHeight': fullHeight === true,
        })}
      >
        {flattenedChildren}
      </KuiFlexRoot>
    )
  }
)

type KuiFlexItemRootProps = { $grow?: number; $shrink?: number }

const KuiFlexItemRoot = styled.div<KuiFlexItemRootProps>`
  flex-grow: ${(p) => p.$grow};
  flex-shrink: ${(p) => p.$shrink};
  flex-basis: ${(p) => (p.$grow ? '0%' : null)};
  min-width: 0;

  max-width: 100%;

  &:empty {
    display: none;
  }
`

type KuiFlexItemProps = {
  grow?: number
  shrink?: number
  children?: ReactNode
}

function KuiFlexItem({ grow, shrink, children }: KuiFlexItemProps) {
  return (
    <KuiFlexItemRoot $grow={grow} $shrink={shrink}>
      {children}
    </KuiFlexItemRoot>
  )
}

export const KuiFlex = Object.assign(KuiFlexImpl, { Item: KuiFlexItem })
