import { MARK_BOLD, MARK_ITALIC } from '@udecode/plate-basic-marks'
import {
  getPluginType,
  someNode,
  toggleNodeType,
  useEditorRef,
  useEditorSelector,
  useMarkToolbarButton,
  useMarkToolbarButtonState,
} from '@udecode/plate-common'
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading'
import {
  ELEMENT_OL,
  ELEMENT_UL,
  toggleList,
  useListToolbarButton,
  useListToolbarButtonState,
} from '@udecode/plate-list'
import { omit } from 'lodash-es'

import { KuiFlex } from 'components/kui/KuiFlex'

import { KuiControlsGroup } from './KuiControlsGroup'

export type KuiRichTextEnabledTypes = {
  marks?: boolean
  headings?: boolean
  lists?: boolean
}

type KuiRichTextToolbarProps = {
  enabledTypes: KuiRichTextEnabledTypes
  disabled?: boolean
}

export function KuiRichTextToolbar({
  enabledTypes,
  disabled,
}: KuiRichTextToolbarProps) {
  const boldMarkButtonState = useMarkToolbarButtonState({ nodeType: MARK_BOLD })
  const { props: boldMarkButtonProps } =
    useMarkToolbarButton(boldMarkButtonState)

  const italicMarkButtonState = useMarkToolbarButtonState({
    nodeType: MARK_ITALIC,
  })
  const { props: italicMarkButtonProps } = useMarkToolbarButton(
    italicMarkButtonState
  )

  const unorderedListButtonState = useListToolbarButtonState({
    nodeType: ELEMENT_UL,
  })
  const { props: unorderedListButtonProps } = useListToolbarButton(
    unorderedListButtonState
  )

  const orderedListButtonState = useListToolbarButtonState({
    nodeType: ELEMENT_OL,
  })
  const { props: orderedListButtonProps } = useListToolbarButton(
    orderedListButtonState
  )

  const h1ToolbarButtonProps = useHeadingToolbarButton({ nodeType: ELEMENT_H1 })
  const h2ToolbarButtonProps = useHeadingToolbarButton({ nodeType: ELEMENT_H2 })
  const h3ToolbarButtonProps = useHeadingToolbarButton({ nodeType: ELEMENT_H3 })

  return (
    <KuiFlex gapSize='sm'>
      {enabledTypes.marks && (
        <KuiControlsGroup
          actions={[
            {
              iconType: 'bold',
              active: boldMarkButtonProps.pressed,
              ...omit(boldMarkButtonProps, 'pressed'),
              disabled,
            },
            {
              iconType: 'italic',
              active: italicMarkButtonProps.pressed,
              ...omit(italicMarkButtonProps, 'pressed'),
              disabled,
            },
          ]}
        />
      )}

      {enabledTypes.headings && (
        <KuiControlsGroup
          actions={[
            {
              iconType: 'h1',
              ...h1ToolbarButtonProps,
              disabled,
            },
            {
              iconType: 'h2',
              ...h2ToolbarButtonProps,
              disabled,
            },
            {
              iconType: 'h3',
              ...h3ToolbarButtonProps,
              disabled,
            },
          ]}
        />
      )}

      {enabledTypes.lists && (
        <KuiControlsGroup
          actions={[
            {
              iconType: 'list',
              active: unorderedListButtonProps.pressed,
              ...omit(unorderedListButtonProps, 'pressed'),
              disabled,
            },
            {
              iconType: 'list-numbers',
              active: orderedListButtonProps.pressed,
              ...omit(orderedListButtonProps, 'pressed'),
              disabled,
            },
          ]}
        />
      )}
    </KuiFlex>
  )
}

function useHeadingToolbarButton({ nodeType }: { nodeType: string }) {
  const editor = useEditorRef()
  const active = useEditorSelector(
    (editor) =>
      !!editor.selection &&
      someNode(editor, { match: { type: getPluginType(editor, nodeType) } }),
    [nodeType]
  )
  const unorderedListActive = useEditorSelector(
    (editor) =>
      !!editor.selection &&
      someNode(editor, {
        match: { type: getPluginType(editor, ELEMENT_UL) },
      }),
    []
  )
  const orderedListActive = useEditorSelector(
    (editor) =>
      !!editor.selection &&
      someNode(editor, {
        match: { type: getPluginType(editor, ELEMENT_OL) },
      }),
    []
  )

  return {
    onClick: () => {
      if (unorderedListActive || orderedListActive) {
        toggleList(editor, {
          type: orderedListActive ? ELEMENT_OL : ELEMENT_UL,
        })
      }

      toggleNodeType(editor, { activeType: nodeType })
    },
    onMouseDown: (event: MouseEvent) => event.preventDefault(),
    active,
  }
}
