import { format, parse, set } from 'date-fns'

export const isoToLocaleString = (isoString?: string | null) => {
  if (!isoString) {
    return ''
  }

  return format(isoString, 'MM/dd/yyyy h:mm a')
}

export const isoToLocaleStringDateOnly = (isoString?: string | null) => {
  if (!isoString) {
    return ''
  }

  return format(new Date(isoString), 'MM/dd/yyyy')
}

export function enrichDateWithTime(date: Date, timeString: string): Date {
  const [hours, minutes] = timeString.split(':')

  return set(date, {
    hours: parseInt(hours),
    minutes: parseInt(minutes),
  })
}

export function formatTime(time: string) {
  return format(parse(time, 'HH:mm:ss', new Date()), 'h:mma')
}

export function dateToApiDate<TDate extends Date | null | undefined>(
  date: TDate
): TDate extends Date ? string : null {
  if (!date) {
    return null as any
  }

  return date.toISOString().split('T')[0] as any
}

export function dateToApiDatetime<TDate extends Date | null | undefined>(
  date: TDate
): TDate extends Date ? string : null {
  if (!date) {
    return null as any
  }

  return date.toISOString() as any
}
