import { lazily } from 'react-lazily'

import { businessSettingsUrls } from 'routes/Settings/settingsUrls'
import { createRoute } from 'routing'

const { BusinessSettingsPage } = lazily(() => import('./BusinessSettingsPage'))

const {
  LocationSettingsPage,
  LocationCreatePage,
  LocationDetailPage,
  LocationEditPage,
} = lazily(() => import('./LocationSettingsPage'))

export const businessSettingsRoutes = [
  createRoute({
    route: businessSettingsUrls,
    Component: BusinessSettingsPage,
    children: [
      createRoute({
        route: businessSettingsUrls.locations,
        Component: LocationSettingsPage,
      }),
    ],
  }),
  createRoute({
    route: businessSettingsUrls.locations.create,
    Component: LocationCreatePage,
  }),
  createRoute({
    route: businessSettingsUrls.locations.detail,
    Component: LocationDetailPage,
  }),
  createRoute({
    route: businessSettingsUrls.locations.edit,
    Component: LocationEditPage,
  }),
]
