import { useFormState } from 'react-hook-form'

import { KuiButton, type KuiButtonProps } from 'components/kui/KuiButton'

type KuiFormSubmitButtonProps = Omit<
  KuiButtonProps,
  'type' | 'disabled' | 'loading'
>

export function KuiFormSubmitButton({
  variant = 'filled',
  ...restProps
}: KuiFormSubmitButtonProps) {
  const { isSubmitting } = useFormState()

  return (
    <KuiButton
      type='submit'
      variant={variant}
      disabled={isSubmitting}
      loading={isSubmitting}
      {...restProps}
    />
  )
}
