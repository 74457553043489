import {
  useController,
  useFormContext,
  type ControllerRenderProps,
  type FieldPath,
  type FieldValues,
  type UseControllerProps,
} from 'react-hook-form'

import { KuiAutocompleteInput } from 'components/kui/inputs'
import { type KuiAutocompleteInputProps } from 'components/kui/inputs/KuiAutocompleteInput'
import { useIsReadonlyKyusuForm } from 'hooks/useKyusuFormContext'
import { type RequireGeneric } from 'utils/types'

import { getKuiFormFieldError, getMaybeReadonlyFormFieldProps } from './utils'
import { kuiRequiredValidator } from './validators'

type KuiFormTextInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TItemSingle,
> = Pick<
  UseControllerProps<RequireGeneric<TFieldValues>>,
  'name' | 'rules' | 'disabled'
> &
  Omit<
    KuiAutocompleteInputProps<TItemSingle>,
    keyof ControllerRenderProps<TFieldValues, TName>
  > & {
    required?: boolean
    onChange?: (nextValue: string) => void
  }

export function KuiFormAutocompleteInput<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = never,
  TItemSingle = never,
>({
  name,
  rules,
  required = false,
  onChange: consumerOnChange,
  ...restProps
}: KuiFormTextInputProps<TFieldValues, TName, TItemSingle>) {
  const readOnly = useIsReadonlyKyusuForm()
  const formContext = useFormContext<TFieldValues>()

  const {
    field: { ref, value, onChange, ...field },
    fieldState,
  } = useController<TFieldValues>({
    name,
    rules: { ...rules, required: kuiRequiredValidator(required) },
  })

  return (
    <KuiAutocompleteInput
      inputRef={ref}
      {...field}
      {...restProps}
      value={value ?? ''}
      {...getMaybeReadonlyFormFieldProps({
        readOnly,
        ...restProps,
        withAsterisk: required,
      })}
      error={getKuiFormFieldError({ fieldState })}
      onChange={(nextValue) => {
        formContext.clearErrors(name)

        onChange(nextValue)
        consumerOnChange?.(nextValue)
      }}
    />
  )
}
