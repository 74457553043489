import {
  createUser,
  getCurrentUserQueryOptions,
  getGetUserQueryOptions,
  getUsersQueryKey,
  updateUser,
  updateUserLocation,
  updateUserPassword,
  users,
} from '_autogenerated'

import {
  makeCreateOrUpdateQueryHook,
  makePaginatedQueryHook,
  makeSearchableSelectPropsHook,
} from './utils'

export const useCreateUser = makeCreateOrUpdateQueryHook({
  fn: createUser,
  detailQueryOptions: getGetUserQueryOptions,
  invalidate: [getUsersQueryKey()],
})

export const useUpdateUser = makeCreateOrUpdateQueryHook({
  fn: updateUser,
  detailQueryOptions: getGetUserQueryOptions,
  invalidate: [],
})

export const useUpdateCurrentUserLocation = makeCreateOrUpdateQueryHook({
  fn: updateUserLocation,
  detailQueryOptions: getCurrentUserQueryOptions,
  invalidate: [],
})

export const useUpdateUserPassword = makeCreateOrUpdateQueryHook({
  fn: updateUserPassword,
  detailQueryOptions: null,
  invalidate: [],
})

export const useUserSelectProps = makeSearchableSelectPropsHook({
  queryKey: getUsersQueryKey,
  queryFn: users,
})

export const usePaginatedUsers = makePaginatedQueryHook({
  queryKey: getUsersQueryKey,
  queryFn: users,
})
