import { createContext, useContext } from 'react'
import {
  type FieldValues,
  type FormProviderProps,
  type UseFormReturn,
} from 'react-hook-form'

type KyusuHookFormContextType<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TransformedValues extends FieldValues | undefined = undefined,
> = {
  readOnly: boolean
  mode: 'create' | 'edit' | 'view'
} & UseFormReturn<TFieldValues, TContext, TransformedValues>

const KyusuHookFormContext = createContext<KyusuHookFormContextType | null>(
  null
)

export const useKyusuFormContext = <
  TFieldValues extends FieldValues,
  TContext = any,
  TransformedValues extends FieldValues | undefined = undefined,
>(): KyusuHookFormContextType<TFieldValues, TContext, TransformedValues> => {
  // @ts-ignore
  const context = useContext(KyusuHookFormContext) as KyusuHookFormContextType<
    TFieldValues,
    TContext,
    TransformedValues
  >

  if (!context) {
    throw new Error(
      'useKyusuFormContext must be used within a KyusuFormProvider'
    )
  }

  return context
}

export function useIsReadonlyKyusuForm() {
  const context = useContext(KyusuHookFormContext)

  return !!context?.readOnly
}

export const KyusuFormProvider = <
  TFieldValues extends FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props: FormProviderProps<TFieldValues, TContext, TTransformedValues> & {
    mode: 'create' | 'edit' | 'view'
  }
) => {
  const { children, ...data } = props

  const contextData = {
    readOnly: data.mode === 'view',
    ...data,
  }

  if (Object.keys(data.formState.errors).length > 0) {
    console.error(data.formState.errors)
  }

  return (
    <KyusuHookFormContext.Provider
      value={contextData as unknown as KyusuHookFormContextType}
    >
      {children}
    </KyusuHookFormContext.Provider>
  )
}
