import { Title as MantineTitle } from '@mantine/core'
import { Helmet } from 'react-helmet-async'
import { type To } from 'react-router-dom'

import { KuiActionList, type KuiAction } from './KuiActionList'
import { KuiBadge, type KuiBadgeProps } from './KuiBadge'
import { KuiButton } from './KuiButton'
import { KuiFlex } from './KuiFlex'
import { type KuiMenuItem } from './KuiMenu'
import { KuiPad } from './KuiPad'
import { KuiStack } from './KuiStack'
import { KuiTabs, type KuiTabsProps } from './KuiTabs'
import { KuiTextBits, type KuiTextBitsProps } from './KuiTextBits'

export type KuiPageHeaderProps<TTab extends string = any> = {
  title: string
  badgeProps?: KuiBadgeProps

  description?: string | Pick<KuiTextBitsProps, 'bits'>

  tabs?: KuiTabsProps<TTab>

  backButton?: {
    label: string
    to: To
  }

  menuItems?: KuiMenuItem[]
  secondaryActions?: KuiAction[]
  primaryAction?: KuiAction
}

export function KuiPageHeader<TTab extends string>({
  title,
  badgeProps,
  description,
  tabs,
  backButton,
  menuItems = [],
  secondaryActions = [],
  primaryAction,
}: KuiPageHeaderProps<TTab>) {
  return (
    <KuiPad bottomSize='xl'>
      <Helmet>
        <title>{title} — Moab</title>
      </Helmet>

      <KuiStack gapSize='md'>
        <div>
          {backButton && (
            <KuiButton
              to={backButton.to}
              size='xs'
              iconType='arrow-left'
              variant='transparent'
            >
              {backButton.label}
            </KuiButton>
          )}

          <KuiFlex justifyContent='spaceBetween' gapSize='md' wrap={true}>
            <div>
              <KuiFlex gapSize='xs'>
                <MantineTitle order={1}>{title}</MantineTitle>

                {badgeProps && (
                  <KuiFlex shrink={0}>
                    <KuiBadge {...badgeProps} />
                  </KuiFlex>
                )}
              </KuiFlex>

              {description && (
                <KuiTextBits
                  size='sm'
                  color='hushed'
                  bits={
                    typeof description === 'string'
                      ? [description]
                      : description.bits
                  }
                />
              )}
            </div>

            <KuiActionList
              size='m'
              variant='outline'
              menuItems={menuItems}
              actions={[
                ...secondaryActions,
                ...(primaryAction
                  ? [
                      {
                        variant: 'filled' as const,
                        ...primaryAction,
                        buttonProps:
                          'buttonProps' in primaryAction
                            ? {
                                variant: 'filled' as const,
                                ...primaryAction.buttonProps,
                              }
                            : undefined,
                      },
                    ]
                  : []),
              ]}
            />
          </KuiFlex>
        </div>

        {tabs && <KuiTabs {...tabs} />}
      </KuiStack>
    </KuiPad>
  )
}
