import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
  type Dispatch,
} from 'react'

import { ActionTypes, type AuthActions, type AuthContextType } from './types'

const authInitialState: AuthContextType = {}

const initializer = (initialValue: AuthContextType = authInitialState) => {
  const parsedState = JSON.parse(localStorage.getItem('user') || '{}')

  return parsedState || initialValue
}

const authReducer = (state: AuthContextType, action: AuthActions) => {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return { ...state, user: action.payload.user }
    case ActionTypes.LOGOUT:
      return { ...state, user: undefined }
    default:
      return state
  }
}

const AuthContext = createContext<{
  state: AuthContextType
  dispatch: Dispatch<AuthActions>
}>({
  state: authInitialState,
  dispatch: () => null,
})

export const AuthContextProvider = (props: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(
    authReducer,
    authInitialState,
    initializer
  )

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(state))
  }, [state])

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)
