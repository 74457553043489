import { Title } from '@mantine/core'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { Fragment, useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

import { login } from '_autogenerated'
import { KuiFlex, kuiThemeVars } from 'components/kui'
import { KyusuLoaderFull } from 'components/Kyusu/KyusuLoader/KyusuLoader'

const MagicLinkPageBackgroundGlobalStyles = createGlobalStyle`
  html, body {
    background-color: ${kuiThemeVars.colors.lightShade};
  }
`

export function MagicLinkPageRoot() {
  const [loggedIn, setLoggedIn] = useState(false)

  return (
    <Fragment>
      <MagicLinkPageBackgroundGlobalStyles />

      {loggedIn ? (
        <Outlet />
      ) : (
        <MagicLinkLogin onLoginSuccess={() => setLoggedIn(true)} />
      )}
    </Fragment>
  )
}

function MagicLinkLogin({ onLoginSuccess }: { onLoginSuccess: () => void }) {
  const { token } = useParams<{ token: string }>()

  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    handleLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return (
      <KuiFlex direction='column' alignItems='center'>
        <Title order={1}>{error}</Title>
      </KuiFlex>
    )
  }

  return <KyusuLoaderFull />

  async function handleLogin() {
    try {
      await login({ body: { token } })

      onLoginSuccess()
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          return setError('Link expired')
        }

        return setError('Invalid link')
      }

      Sentry.captureException(error)

      return setError('Invalid link')
    }
  }
}
