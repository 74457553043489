import { omit } from 'lodash-es'
import { useMemo } from 'react'
import {
  useFieldArray,
  type ArrayPath,
  type ControllerFieldState,
  type FieldArrayPathValue,
  type FieldValues,
  type UseFieldArrayProps,
  type UseFieldArrayReturn,
} from 'react-hook-form'

import { type KuiTextInputProps } from 'components/kui/inputs/KuiTextInput'

type KuiFieldArrayTableRowsParams<
  TFieldValues extends FieldValues,
  TName extends ArrayPath<TFieldValues>,
> = Pick<UseFieldArrayProps<TFieldValues, TName>, 'control' | 'name'>

export type KuiFieldArrayTableRow<
  TFieldValues extends FieldValues,
  TName extends ArrayPath<TFieldValues>,
  TItem extends FieldArrayPathValue<
    TFieldValues,
    TName
  >[number] = FieldArrayPathValue<TFieldValues, TName>[number],
> = {
  key: string
  defaultValue: TItem
  index: number
}

export type UseKuiFormFieldArrayReturn<
  TFieldValues extends FieldValues,
  TName extends ArrayPath<TFieldValues>,
  TItem extends FieldArrayPathValue<
    TFieldValues,
    TName
  >[number] = FieldArrayPathValue<TFieldValues, TName>[number],
> = Omit<UseFieldArrayReturn<TFieldValues, TName>, 'fields'> & {
  rows: KuiFieldArrayTableRow<TFieldValues, TName, TItem>[]
}

export function useKuiFormFieldArray<
  TFieldValues extends FieldValues = never,
  TName extends ArrayPath<TFieldValues> = never,
  TItem extends FieldArrayPathValue<
    TFieldValues,
    TName
  >[number] = FieldArrayPathValue<TFieldValues, TName>[number],
>(
  params: KuiFieldArrayTableRowsParams<TFieldValues, TName>
): UseKuiFormFieldArrayReturn<TFieldValues, TName, TItem> {
  const { fields, ...restFieldArray } = useFieldArray<
    TFieldValues,
    TName,
    '__rhf_field_key'
  >({
    ...params,
    keyName: '__rhf_field_key',
  })

  const rows: KuiFieldArrayTableRow<TFieldValues, TName, TItem>[] =
    useMemo(() => {
      return fields.map((field, index) => ({
        key: field.__rhf_field_key,
        defaultValue: omit(field, '__rhf_field_key') as TItem,
        index,
      }))
    }, [fields])

  return { rows, ...restFieldArray }
}

export function getKuiFormFieldError({
  fieldState,
}: {
  fieldState: ControllerFieldState
}): string | boolean {
  return fieldState.error?.message
    ? fieldState.error?.message
    : !!fieldState.error
}

export function getMaybeReadonlyFormFieldProps({
  readOnly,
  ...rest
}: Pick<
  KuiTextInputProps,
  'disabled' | 'withAsterisk' | 'variant' | 'placeholder'
> & {
  readOnly: boolean
}) {
  if (readOnly) {
    return {
      disabled: true,
      variant: 'content',
      placeholder: '',
    }
  }

  return { ...rest, withAsterisk: rest.disabled ? false : rest.withAsterisk }
}
