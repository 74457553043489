/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  CommissionMatrixCreateSchema,
  CommissionMatrixDumpSchema,
  CommissionMatrixUpdateSchema,
  CustomerCommissionGroupCreateSchema,
  CustomerCommissionGroupDumpSchema,
  CustomerCommissionGroupUpdateSchema,
  CustomerCommissionListDumpSchema,
  InventoryCommissionGroupCreateSchema,
  InventoryCommissionGroupDumpSchema,
  InventoryCommissionGroupUpdateSchema,
  InventoryCommissionListDumpSchema,
  ListCommissionMatrixsParams,
  ListCustomerCommissionGroupsParams,
  ListInventoryCommissionGroupsParams,
  PaginatedCommissionMatrixDumpSchema,
  SuccessResponse
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const listCustomerCommissionGroups = (
    params?: ListCustomerCommissionGroupsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<CustomerCommissionListDumpSchema>(
      {url: `/sales/customer-commission-groups`, method: 'GET',
        params, signal
    },
      options);
    }


export const getListCustomerCommissionGroupsQueryKey = (params?: ListCustomerCommissionGroupsParams,) => {
    return [`/sales/customer-commission-groups`, ...(params ? [params]: [])] as const;
    }


export const getListCustomerCommissionGroupsQueryOptions = <TData = Awaited<ReturnType<typeof listCustomerCommissionGroups>>, TError = ErrorType<unknown>>(params?: ListCustomerCommissionGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCustomerCommissionGroups>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCustomerCommissionGroupsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCustomerCommissionGroups>>> = ({ signal }) => listCustomerCommissionGroups(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listCustomerCommissionGroups>>, TError, TData> & { queryKey: QueryKey }
}

export type ListCustomerCommissionGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof listCustomerCommissionGroups>>>
export type ListCustomerCommissionGroupsQueryError = ErrorType<unknown>

export const useListCustomerCommissionGroups = <TData = Awaited<ReturnType<typeof listCustomerCommissionGroups>>, TError = ErrorType<unknown>>(
 params?: ListCustomerCommissionGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCustomerCommissionGroups>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCustomerCommissionGroupsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListCustomerCommissionGroupsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listCustomerCommissionGroups>>, TError = ErrorType<unknown>>(params?: ListCustomerCommissionGroupsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCustomerCommissionGroups>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCustomerCommissionGroupsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCustomerCommissionGroups>>> = ({ signal }) => listCustomerCommissionGroups(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCustomerCommissionGroups>>, TError, TData> & { queryKey: QueryKey }
}

export type ListCustomerCommissionGroupsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listCustomerCommissionGroups>>>
export type ListCustomerCommissionGroupsSuspenseQueryError = ErrorType<unknown>

export const useListCustomerCommissionGroupsSuspense = <TData = Awaited<ReturnType<typeof listCustomerCommissionGroups>>, TError = ErrorType<unknown>>(
 params?: ListCustomerCommissionGroupsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCustomerCommissionGroups>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCustomerCommissionGroupsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createCustomerCommissionGroup = (
    customerCommissionGroupCreateSchema: BodyType<CustomerCommissionGroupCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CustomerCommissionGroupDumpSchema>(
      {url: `/sales/customer-commission-groups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: customerCommissionGroupCreateSchema
    },
      options);
    }


export const getCustomerCommissionGroup = (
    customerCommissionGroupId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<CustomerCommissionGroupDumpSchema>(
      {url: `/sales/customer-commission-groups/${customerCommissionGroupId}`, method: 'GET', signal
    },
      options);
    }


export const getGetCustomerCommissionGroupQueryKey = (customerCommissionGroupId: string,) => {
    return [`/sales/customer-commission-groups/${customerCommissionGroupId}`] as const;
    }


export const getGetCustomerCommissionGroupQueryOptions = <TData = Awaited<ReturnType<typeof getCustomerCommissionGroup>>, TError = ErrorType<unknown>>(customerCommissionGroupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomerCommissionGroup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomerCommissionGroupQueryKey(customerCommissionGroupId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerCommissionGroup>>> = ({ signal }) => getCustomerCommissionGroup(customerCommissionGroupId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(customerCommissionGroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCustomerCommissionGroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomerCommissionGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomerCommissionGroup>>>
export type GetCustomerCommissionGroupQueryError = ErrorType<unknown>

export const useGetCustomerCommissionGroup = <TData = Awaited<ReturnType<typeof getCustomerCommissionGroup>>, TError = ErrorType<unknown>>(
 customerCommissionGroupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustomerCommissionGroup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCustomerCommissionGroupQueryOptions(customerCommissionGroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCustomerCommissionGroupSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCustomerCommissionGroup>>, TError = ErrorType<unknown>>(customerCommissionGroupId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerCommissionGroup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomerCommissionGroupQueryKey(customerCommissionGroupId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerCommissionGroup>>> = ({ signal }) => getCustomerCommissionGroup(customerCommissionGroupId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(customerCommissionGroupId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerCommissionGroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustomerCommissionGroupSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomerCommissionGroup>>>
export type GetCustomerCommissionGroupSuspenseQueryError = ErrorType<unknown>

export const useGetCustomerCommissionGroupSuspense = <TData = Awaited<ReturnType<typeof getCustomerCommissionGroup>>, TError = ErrorType<unknown>>(
 customerCommissionGroupId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCustomerCommissionGroup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCustomerCommissionGroupSuspenseQueryOptions(customerCommissionGroupId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateCustomerCommissionGroup = (
    customerCommissionGroupId: string,
    customerCommissionGroupUpdateSchema: BodyType<CustomerCommissionGroupUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CustomerCommissionGroupDumpSchema>(
      {url: `/sales/customer-commission-groups/${customerCommissionGroupId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: customerCommissionGroupUpdateSchema
    },
      options);
    }


export const deleteCustomerCommissionGroup = (
    customerCommissionGroupId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/sales/customer-commission-groups/${customerCommissionGroupId}`, method: 'DELETE'
    },
      options);
    }


export const listInventoryCommissionGroups = (
    params?: ListInventoryCommissionGroupsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<InventoryCommissionListDumpSchema>(
      {url: `/sales/inventory-commission-groups`, method: 'GET',
        params, signal
    },
      options);
    }


export const getListInventoryCommissionGroupsQueryKey = (params?: ListInventoryCommissionGroupsParams,) => {
    return [`/sales/inventory-commission-groups`, ...(params ? [params]: [])] as const;
    }


export const getListInventoryCommissionGroupsQueryOptions = <TData = Awaited<ReturnType<typeof listInventoryCommissionGroups>>, TError = ErrorType<unknown>>(params?: ListInventoryCommissionGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listInventoryCommissionGroups>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListInventoryCommissionGroupsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listInventoryCommissionGroups>>> = ({ signal }) => listInventoryCommissionGroups(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listInventoryCommissionGroups>>, TError, TData> & { queryKey: QueryKey }
}

export type ListInventoryCommissionGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof listInventoryCommissionGroups>>>
export type ListInventoryCommissionGroupsQueryError = ErrorType<unknown>

export const useListInventoryCommissionGroups = <TData = Awaited<ReturnType<typeof listInventoryCommissionGroups>>, TError = ErrorType<unknown>>(
 params?: ListInventoryCommissionGroupsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listInventoryCommissionGroups>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListInventoryCommissionGroupsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListInventoryCommissionGroupsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listInventoryCommissionGroups>>, TError = ErrorType<unknown>>(params?: ListInventoryCommissionGroupsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listInventoryCommissionGroups>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListInventoryCommissionGroupsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listInventoryCommissionGroups>>> = ({ signal }) => listInventoryCommissionGroups(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listInventoryCommissionGroups>>, TError, TData> & { queryKey: QueryKey }
}

export type ListInventoryCommissionGroupsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listInventoryCommissionGroups>>>
export type ListInventoryCommissionGroupsSuspenseQueryError = ErrorType<unknown>

export const useListInventoryCommissionGroupsSuspense = <TData = Awaited<ReturnType<typeof listInventoryCommissionGroups>>, TError = ErrorType<unknown>>(
 params?: ListInventoryCommissionGroupsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listInventoryCommissionGroups>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListInventoryCommissionGroupsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createInventoryCommissionGroup = (
    inventoryCommissionGroupCreateSchema: BodyType<InventoryCommissionGroupCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<InventoryCommissionGroupDumpSchema>(
      {url: `/sales/inventory-commission-groups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: inventoryCommissionGroupCreateSchema
    },
      options);
    }


export const getInventoryCommissionGroup = (
    inventoryCommissionGroupId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<InventoryCommissionGroupDumpSchema>(
      {url: `/sales/inventory-commission-groups/${inventoryCommissionGroupId}`, method: 'GET', signal
    },
      options);
    }


export const getGetInventoryCommissionGroupQueryKey = (inventoryCommissionGroupId: string,) => {
    return [`/sales/inventory-commission-groups/${inventoryCommissionGroupId}`] as const;
    }


export const getGetInventoryCommissionGroupQueryOptions = <TData = Awaited<ReturnType<typeof getInventoryCommissionGroup>>, TError = ErrorType<unknown>>(inventoryCommissionGroupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInventoryCommissionGroup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInventoryCommissionGroupQueryKey(inventoryCommissionGroupId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInventoryCommissionGroup>>> = ({ signal }) => getInventoryCommissionGroup(inventoryCommissionGroupId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(inventoryCommissionGroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getInventoryCommissionGroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInventoryCommissionGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getInventoryCommissionGroup>>>
export type GetInventoryCommissionGroupQueryError = ErrorType<unknown>

export const useGetInventoryCommissionGroup = <TData = Awaited<ReturnType<typeof getInventoryCommissionGroup>>, TError = ErrorType<unknown>>(
 inventoryCommissionGroupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getInventoryCommissionGroup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInventoryCommissionGroupQueryOptions(inventoryCommissionGroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetInventoryCommissionGroupSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getInventoryCommissionGroup>>, TError = ErrorType<unknown>>(inventoryCommissionGroupId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInventoryCommissionGroup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetInventoryCommissionGroupQueryKey(inventoryCommissionGroupId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getInventoryCommissionGroup>>> = ({ signal }) => getInventoryCommissionGroup(inventoryCommissionGroupId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(inventoryCommissionGroupId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInventoryCommissionGroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetInventoryCommissionGroupSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getInventoryCommissionGroup>>>
export type GetInventoryCommissionGroupSuspenseQueryError = ErrorType<unknown>

export const useGetInventoryCommissionGroupSuspense = <TData = Awaited<ReturnType<typeof getInventoryCommissionGroup>>, TError = ErrorType<unknown>>(
 inventoryCommissionGroupId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getInventoryCommissionGroup>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetInventoryCommissionGroupSuspenseQueryOptions(inventoryCommissionGroupId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateInventoryCommissionGroup = (
    inventoryCommissionGroupId: string,
    inventoryCommissionGroupUpdateSchema: BodyType<InventoryCommissionGroupUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<InventoryCommissionGroupDumpSchema>(
      {url: `/sales/inventory-commission-groups/${inventoryCommissionGroupId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: inventoryCommissionGroupUpdateSchema
    },
      options);
    }


export const deleteInventoryCommissionGroup = (
    inventoryCommissionGroupId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/sales/inventory-commission-groups/${inventoryCommissionGroupId}`, method: 'DELETE'
    },
      options);
    }


export const listCommissionMatrixs = (
    params?: ListCommissionMatrixsParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedCommissionMatrixDumpSchema>(
      {url: `/sales/commission-matrices`, method: 'GET',
        params, signal
    },
      options);
    }


export const getListCommissionMatrixsQueryKey = (params?: ListCommissionMatrixsParams,) => {
    return [`/sales/commission-matrices`, ...(params ? [params]: [])] as const;
    }


export const getListCommissionMatrixsQueryOptions = <TData = Awaited<ReturnType<typeof listCommissionMatrixs>>, TError = ErrorType<unknown>>(params?: ListCommissionMatrixsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCommissionMatrixs>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCommissionMatrixsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCommissionMatrixs>>> = ({ signal }) => listCommissionMatrixs(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listCommissionMatrixs>>, TError, TData> & { queryKey: QueryKey }
}

export type ListCommissionMatrixsQueryResult = NonNullable<Awaited<ReturnType<typeof listCommissionMatrixs>>>
export type ListCommissionMatrixsQueryError = ErrorType<unknown>

export const useListCommissionMatrixs = <TData = Awaited<ReturnType<typeof listCommissionMatrixs>>, TError = ErrorType<unknown>>(
 params?: ListCommissionMatrixsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCommissionMatrixs>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCommissionMatrixsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getListCommissionMatrixsSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof listCommissionMatrixs>>, TError = ErrorType<unknown>>(params?: ListCommissionMatrixsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCommissionMatrixs>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCommissionMatrixsQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCommissionMatrixs>>> = ({ signal }) => listCommissionMatrixs(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCommissionMatrixs>>, TError, TData> & { queryKey: QueryKey }
}

export type ListCommissionMatrixsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof listCommissionMatrixs>>>
export type ListCommissionMatrixsSuspenseQueryError = ErrorType<unknown>

export const useListCommissionMatrixsSuspense = <TData = Awaited<ReturnType<typeof listCommissionMatrixs>>, TError = ErrorType<unknown>>(
 params?: ListCommissionMatrixsParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof listCommissionMatrixs>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCommissionMatrixsSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const createCommissionMatrix = (
    commissionMatrixCreateSchema: BodyType<CommissionMatrixCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CommissionMatrixDumpSchema>(
      {url: `/sales/commission-matrices`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: commissionMatrixCreateSchema
    },
      options);
    }


export const getCommissionMatrix = (
    commissionMatrixId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<CommissionMatrixDumpSchema>(
      {url: `/sales/commission-matrices/${commissionMatrixId}`, method: 'GET', signal
    },
      options);
    }


export const getGetCommissionMatrixQueryKey = (commissionMatrixId: string,) => {
    return [`/sales/commission-matrices/${commissionMatrixId}`] as const;
    }


export const getGetCommissionMatrixQueryOptions = <TData = Awaited<ReturnType<typeof getCommissionMatrix>>, TError = ErrorType<unknown>>(commissionMatrixId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCommissionMatrix>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCommissionMatrixQueryKey(commissionMatrixId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommissionMatrix>>> = ({ signal }) => getCommissionMatrix(commissionMatrixId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(commissionMatrixId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCommissionMatrix>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCommissionMatrixQueryResult = NonNullable<Awaited<ReturnType<typeof getCommissionMatrix>>>
export type GetCommissionMatrixQueryError = ErrorType<unknown>

export const useGetCommissionMatrix = <TData = Awaited<ReturnType<typeof getCommissionMatrix>>, TError = ErrorType<unknown>>(
 commissionMatrixId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCommissionMatrix>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCommissionMatrixQueryOptions(commissionMatrixId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCommissionMatrixSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getCommissionMatrix>>, TError = ErrorType<unknown>>(commissionMatrixId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCommissionMatrix>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCommissionMatrixQueryKey(commissionMatrixId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommissionMatrix>>> = ({ signal }) => getCommissionMatrix(commissionMatrixId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(commissionMatrixId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCommissionMatrix>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCommissionMatrixSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getCommissionMatrix>>>
export type GetCommissionMatrixSuspenseQueryError = ErrorType<unknown>

export const useGetCommissionMatrixSuspense = <TData = Awaited<ReturnType<typeof getCommissionMatrix>>, TError = ErrorType<unknown>>(
 commissionMatrixId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getCommissionMatrix>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCommissionMatrixSuspenseQueryOptions(commissionMatrixId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateCommissionMatrix = (
    commissionMatrixId: string,
    commissionMatrixUpdateSchema: BodyType<CommissionMatrixUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CommissionMatrixDumpSchema>(
      {url: `/sales/commission-matrices/${commissionMatrixId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: commissionMatrixUpdateSchema
    },
      options);
    }


export const deleteCommissionMatrix = (
    commissionMatrixId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/sales/commission-matrices/${commissionMatrixId}`, method: 'DELETE'
    },
      options);
    }


