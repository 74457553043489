import {
  getLocationGetQueryOptions,
  getLocationListQueryKey,
  locationCreate,
  locationDelete,
  locationList,
  updateLocation,
} from '_autogenerated'

import {
  makeCreateOrUpdateQueryHook,
  makeDeleteQueryHook,
  makePaginatedQueryHook,
  makeSearchableSelectPropsHook,
} from './utils'

export const useLocationSelectProps = makeSearchableSelectPropsHook({
  queryKey: getLocationListQueryKey,
  queryFn: locationList,
})

export const useCreateLocation = makeCreateOrUpdateQueryHook({
  fn: locationCreate,
  detailQueryOptions: getLocationGetQueryOptions,
  invalidate: [getLocationListQueryKey()],
})

export const useUpdateLocation = makeCreateOrUpdateQueryHook({
  fn: updateLocation,
  detailQueryOptions: getLocationGetQueryOptions,
  invalidate: [getLocationListQueryKey()],
})

export const useDeleteLocation = makeDeleteQueryHook({
  fn: locationDelete,
  invalidate: [getLocationListQueryKey()],
})

export const usePaginatedLocations = makePaginatedQueryHook({
  queryKey: getLocationListQueryKey,
  queryFn: locationList,
})
