import { Loader, Flex } from '@mantine/core'

export default function KyusuLoader() {
  return (
    <Flex
      justify='center'
      align='center'
      style={{ height: '100%', width: '100%' }}
    >
      <Loader size={60} />
    </Flex>
  )
}

export function KyusuLoaderFull() {
  return (
    <Flex justify='center' align='center' style={{ height: '100vh' }}>
      <KyusuLoader />
    </Flex>
  )
}
