import { type PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuthContext } from './AuthContext'

export default function RequireAuth(props: PropsWithChildren<{}>) {
  const auth = useAuthContext()
  const location = useLocation()

  return auth.state.user ? (
    <>{props.children}</>
  ) : (
    <Navigate to='/login' state={{ redirectPath: location.pathname }} />
  )
}
