import {
  useController,
  useFormContext,
  type ControllerRenderProps,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type UseControllerProps,
} from 'react-hook-form'

import {
  KuiSelectInput,
  type KuiSelectInputProps,
} from 'components/kui/inputs/KuiSelectInput'
import { useIsReadonlyKyusuForm } from 'hooks/useKyusuFormContext'
import { type RequireGeneric } from 'utils/types'

import { getKuiFormFieldError, getMaybeReadonlyFormFieldProps } from './utils'
import { kuiRequiredValidator } from './validators'

type KuiFormSelectInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TItem extends FieldPathValue<TFieldValues, TName>,
> = Pick<
  UseControllerProps<RequireGeneric<TFieldValues>, TName>,
  'name' | 'rules' | 'disabled'
> &
  Omit<
    KuiSelectInputProps<TItem>,
    keyof ControllerRenderProps<TFieldValues>
  > & {
    required?: boolean
    onChange?: (nextValue: TItem | null) => void
    preventDefaultOnChange?: boolean
    readOnly?: boolean
  }

export function KuiFormSelectInput<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = never,
  TItem extends FieldPathValue<TFieldValues, TName> = NonNullable<
    FieldPathValue<TFieldValues, TName>
  >,
>({
  name,
  rules,
  required = false,
  clearable = !required,
  onChange: consumerOnChange,
  preventDefaultOnChange = false,
  readOnly: consumerReadOnly = false,
  ...restProps
}: KuiFormSelectInputProps<TFieldValues, TName, TItem>) {
  const readOnly = useIsReadonlyKyusuForm() || consumerReadOnly

  const formContext = useFormContext<TFieldValues>()

  const {
    field: { ref: inputRef, onChange, ...field },
    fieldState,
  } = useController<TFieldValues, TName>({
    name,
    rules: { required: kuiRequiredValidator(required), ...rules },
  })

  return (
    <KuiSelectInput
      {...field}
      {...restProps}
      inputRef={inputRef}
      {...getMaybeReadonlyFormFieldProps({
        readOnly,
        ...restProps,
        withAsterisk: required,
      })}
      clearable={clearable}
      error={getKuiFormFieldError({ fieldState })}
      onChange={(nextItem) => {
        formContext.clearErrors(name)

        if (!preventDefaultOnChange) {
          onChange(nextItem)
        }

        return consumerOnChange?.(nextItem)
      }}
    />
  )
}
