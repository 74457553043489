import { lazily } from 'react-lazily'

import { pricingSettingsUrls } from 'routes/Settings/settingsUrls'
import { createRoute } from 'routing'

const {
  PricingSettingsRoute,
  RentalHoursSettingsPage,
  ExpenseSettingsPage,
  ExpenseCreatePage,
  ExpenseDetailPage,
  ExpenseEditPage,
  RateProfileSettingsPage,
  RateProfileCreatePage,
  RateProfileDetailPage,
  RateProfileEditPage,
  RateTemplateSettingsPage,
  RateTemplateCreatePage,
  RateTemplateDetailPage,
  RateTemplateEditPage,
  DeliverySettingsPage,
  DeliverySettingsCreatePage,
  DeliverySettingsDetailPage,
  DeliverySettingsEditPage,
} = lazily(() => import('./components'))

export const pricingSettingsRoutes = [
  createRoute({
    route: pricingSettingsUrls,
    Component: PricingSettingsRoute,
    children: [
      createRoute({
        route: pricingSettingsUrls,
        redirect: pricingSettingsUrls.hours.buildUrl(),
      }),
      createRoute({
        route: pricingSettingsUrls.hours,
        Component: RentalHoursSettingsPage,
      }),
      createRoute({
        route: pricingSettingsUrls.rateTemplates,
        Component: RateTemplateSettingsPage,
      }),
      createRoute({
        route: pricingSettingsUrls.rateProfiles,
        Component: RateProfileSettingsPage,
      }),
      createRoute({
        route: pricingSettingsUrls.expenseClasses,
        Component: ExpenseSettingsPage,
      }),
      createRoute({
        route: pricingSettingsUrls.delivery,
        Component: DeliverySettingsPage,
      }),
    ],
  }),
  createRoute({
    route: pricingSettingsUrls.hours,
    Component: RentalHoursSettingsPage,
  }),
  createRoute({
    route: pricingSettingsUrls.rateTemplates.create,
    Component: RateTemplateCreatePage,
  }),
  createRoute({
    route: pricingSettingsUrls.rateTemplates.detail,
    Component: RateTemplateDetailPage,
  }),
  createRoute({
    route: pricingSettingsUrls.rateTemplates.edit,
    Component: RateTemplateEditPage,
  }),
  createRoute({
    route: pricingSettingsUrls.rateProfiles.create,
    Component: RateProfileCreatePage,
  }),
  createRoute({
    route: pricingSettingsUrls.rateProfiles.detail,
    Component: RateProfileDetailPage,
  }),
  createRoute({
    route: pricingSettingsUrls.rateProfiles.edit,
    Component: RateProfileEditPage,
  }),
  createRoute({
    route: pricingSettingsUrls.expenseClasses.create,
    Component: ExpenseCreatePage,
  }),
  createRoute({
    route: pricingSettingsUrls.expenseClasses.detail,
    Component: ExpenseDetailPage,
  }),
  createRoute({
    route: pricingSettingsUrls.expenseClasses.edit,
    Component: ExpenseEditPage,
  }),
  createRoute({
    route: pricingSettingsUrls.delivery.create,
    Component: DeliverySettingsCreatePage,
  }),
  createRoute({
    route: pricingSettingsUrls.delivery.detail,
    Component: DeliverySettingsDetailPage,
  }),
  createRoute({
    route: pricingSettingsUrls.delivery.edit,
    Component: DeliverySettingsEditPage,
  }),
]
