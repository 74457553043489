import { urlFactory } from 'routing'

export const businessSettingsUrls = urlFactory(
  '/business',
  {
    settings: urlFactory('/edit', {}),
    locations: urlFactory('/locations', {
      create: urlFactory('/create', {}),
      detail: urlFactory('/:id', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
  },
  { parentPath: '/settings' }
)

export const communicationSettingsUrls = urlFactory(
  '/communications',
  {
    messageTemplates: urlFactory('/message-templates', {
      create: urlFactory('/create', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
    events: urlFactory('/events', {}),
    documentTemplates: urlFactory('/document-templates', {
      edit: urlFactory('/:documentType/edit', {}),
    }),
    legalDocuments: urlFactory('/legal-documents', {
      businessTermsAndConditions: urlFactory('/terms-and-conditions', {}),
      addendums: urlFactory('/addendums', {
        create: urlFactory('/create', {}),
        edit: urlFactory('/:id/edit', {}),
      }),
    }),
    branding: urlFactory('/branding', {}),
  },
  {
    parentPath: '/settings',
  }
)

export const configurationSettingsUrls = urlFactory(
  '/configuration',
  {
    billing: urlFactory('/billing', {
      create: urlFactory('/create', {}),
      detail: urlFactory('/:id', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
    taxes: urlFactory('/taxes', {
      create: urlFactory('/create', {}),
      detail: urlFactory('/:id', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
    depreciation: urlFactory('/depreciation', {
      create: urlFactory('/create', {}),
      detail: urlFactory('/:id', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
    meters: urlFactory('/meters', {
      create: urlFactory('/create', {}),
      detail: urlFactory('/:id', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
    commissionMatrix: urlFactory('/commission-matrix', {
      create: urlFactory('/create', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
    customForms: urlFactory('/forms', {
      create: urlFactory('/create', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
  },
  { parentPath: '/settings' }
)

export const integrationSettingsUrls = urlFactory(
  '/integrations',
  {
    accounting: urlFactory('/accounting', {
      onboard: urlFactory('/onboard', {}),
      defaultAccountMappings: urlFactory('/default-account-mappings', {}),
      inventoryProfiles: urlFactory('/inventory-profiles', {
        create: urlFactory('/create', {}),
        detail: urlFactory('/:id', {}),
        edit: urlFactory('/:id/edit', {}),
      }),
    }),
    payments: urlFactory('/payments', {}),
  },
  { parentPath: '/settings' }
)

export const pricingSettingsUrls = urlFactory(
  '/pricing',
  {
    hours: urlFactory('/hours', {}),
    rateTemplates: urlFactory('/rates', {
      create: urlFactory('/create', {}),
      detail: urlFactory('/:id', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
    rateProfiles: urlFactory('/shifts-and-overtime', {
      create: urlFactory('/create', {}),
      detail: urlFactory('/:id', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
    expenseClasses: urlFactory('/fees-and-discounts', {
      create: urlFactory('/create', {}),
      detail: urlFactory('/:id', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
    delivery: urlFactory('/delivery', {
      create: urlFactory('/create', {}),
      detail: urlFactory('/:id', {}),
      edit: urlFactory('/:id/edit', {}),
    }),
  },
  { parentPath: '/settings' }
)

export const settingsUrls = urlFactory('/settings', {
  user: urlFactory('/user', {
    edit: urlFactory('/edit', {}),
  }),
  business: businessSettingsUrls,
  configurations: configurationSettingsUrls,
  pricing: pricingSettingsUrls,
  communications: communicationSettingsUrls,
  integrations: integrationSettingsUrls,
})
