import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  type QueryKey,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'

import {
  type KuiTablePaginationProps,
  type KuiTableProps,
} from 'components/kui'

import { type KuiQueryOptions } from './types'

type PaginatedQueryResponse<TElements> = {
  next?: number | null
  total_count?: number
  elements: TElements[]
}

type UsePaginatedQueryParams<TElements> = {
  queryKey: readonly unknown[]
  queryFn: (paginationParams: {
    start: number | undefined
    page_size?: number
  }) => Promise<PaginatedQueryResponse<TElements>>
  /** @default 50 */
  pageSize?: number
  options?: KuiQueryOptions
}

export type UsePaginatedQueryReturn<TElements> = {
  queryResult: UseQueryResult<PaginatedQueryResponse<TElements>>

  tableProps: Pick<KuiTableProps<TElements>, 'rows' | 'loading' | 'pagination'>

  paginationProps: KuiTablePaginationProps
}

export function usePaginatedQuery<TElements = never>({
  queryKey,
  queryFn,
  pageSize = 50,
  ...options
}: UsePaginatedQueryParams<TElements>): UsePaginatedQueryReturn<TElements> {
  const [{ start, currentPage, prev }, setPaginationState] = useState<{
    start: number | null
    currentPage: number
    prev: (number | null)[]
  }>({ start: null, currentPage: 0, prev: [] })

  const queryResult = useQuery({
    queryKey: [...queryKey, { start, pageSize }],
    queryFn: () => queryFn({ start: start ?? undefined, page_size: pageSize }),
    ...options,
  })

  const onPrev = useCallback(
    () =>
      setPaginationState(({ prev, currentPage }) => ({
        start: prev.length ? prev[prev.length - 1] : null,
        currentPage: currentPage - 1,
        prev: prev.slice(0, prev.length - 1),
      })),
    []
  )
  const onNext = useCallback(
    () =>
      setPaginationState(({ prev, start }) => ({
        start: queryResult.data?.next ?? null,
        currentPage: currentPage + 1,
        prev: [...prev, start],
      })),
    [currentPage, queryResult.data?.next]
  )

  const paginationProps: KuiTablePaginationProps = useMemo(() => {
    const hasPrev = prev.length > 0
    const hasNext = !!queryResult.data?.next

    return {
      visible: true,
      pageSize,
      currentPage,
      totalRows: queryResult.data?.total_count,
      hasPrev,
      onPrev,
      onNext,
      hasNext,
    }
  }, [
    currentPage,
    onNext,
    onPrev,
    pageSize,
    prev.length,
    queryResult.data?.next,
    queryResult.data?.total_count,
  ])

  return {
    queryResult,
    tableProps: {
      rows: queryResult.data?.elements ?? [],
      loading: queryResult.isLoading,
      pagination: paginationProps,
    },
    paginationProps,
  }
}

type UseSearchableSelectPropsParams<TItem> = {
  queryKey: (searchParams: { search: string }) => readonly unknown[]
  queryFn: (searchParams: { search: string }) => Promise<{ elements: TItem[] }>
  options?: KuiQueryOptions
}

type UseSearchableSelectPropsReturn<TItem> = {
  items: TItem[]
  loading: boolean
  onSearchDebounced: (nextSearch: string) => void
  filterOptions: false
}

export function useSearchableSelectProps<TItem>({
  queryKey,
  queryFn,
  options,
}: UseSearchableSelectPropsParams<TItem>): UseSearchableSelectPropsReturn<TItem> {
  const [search, setSearch] = useState('')

  const queryResult = useQuery({
    ...options,
    queryKey: queryKey({ search }),
    queryFn: () => queryFn({ search }),
    placeholderData: keepPreviousData,
  })

  return useMemo(
    () => ({
      items: queryResult.data?.elements ?? [],
      loading: queryResult.isLoading,
      onSearchDebounced: setSearch,
      filterOptions: false,
    }),
    [queryResult.data?.elements, queryResult.isLoading]
  )
}

type MakeSearchableSelectPropsHookReturn<TParams, TItem> =
  undefined extends TParams
    ? (
        params?: TParams,
        options?: KuiQueryOptions | undefined
      ) => UseSearchableSelectPropsReturn<TItem>
    : (
        params: TParams,
        options?: KuiQueryOptions | undefined
      ) => UseSearchableSelectPropsReturn<TItem>

export function makeSearchableSelectPropsHook<TParams, TItem>({
  queryKey,
  queryFn,
  options: defaultOptions,
}: {
  queryKey: (params: TParams) => readonly any[]
  queryFn: (paginationParams: TParams) => Promise<{ elements: TItem[] }>
  options?: KuiQueryOptions
}): MakeSearchableSelectPropsHookReturn<TParams, TItem> {
  // @ts-ignore
  return function useSearchableSelectPropsHook(
    params: TParams,
    options?: KuiQueryOptions
  ) {
    return useSearchableSelectProps({
      queryKey: ({ search }) => queryKey({ search, ...params }),
      queryFn: ({ search }) => queryFn({ search, ...params }),
      options: { ...defaultOptions, ...options },
    })
  }
}

export function makePaginatedQueryHook<TParams, TElements>({
  queryKey,
  queryFn,
}: {
  queryKey: (params: TParams) => readonly any[]
  queryFn: (
    paginationParams: TParams & {
      start: number | undefined
      page_size?: number | undefined
    }
  ) => Promise<PaginatedQueryResponse<TElements>>
}) {
  return function usePaginatedQueryHook(
    params: TParams,
    options?: KuiQueryOptions
  ) {
    return usePaginatedQuery({
      queryKey: queryKey(params),
      queryFn: (paginationParams) =>
        queryFn({ ...paginationParams, ...params }),
      ...options,
    })
  }
}

type QueryOptionsFn<TData> = (id: any) => {
  queryKey: QueryKey
  queryFn?: UseQueryOptions<TData>['queryFn']
}

export function makeCreateOrUpdateQueryHook<
  TReturn extends object,
  TCreateOrUpdateFn extends (...args: any[]) => Promise<TReturn>,
>({
  fn,
  invalidate,
  detailQueryOptions,
}: {
  fn: TCreateOrUpdateFn
  invalidate:
    | QueryKey[]
    | ((_: {
        args: Parameters<TCreateOrUpdateFn>
        response: TReturn
      }) => QueryKey[])
  detailQueryOptions: QueryOptionsFn<TReturn> | null
}) {
  return function useCreateOrUpdateQueryHook(): TCreateOrUpdateFn {
    const queryClient = useQueryClient()

    // @ts-ignore
    return useCallback(
      async (...args: Parameters<TCreateOrUpdateFn>) => {
        const response = await fn(...args)

        const toInvalidate =
          typeof invalidate === 'function'
            ? invalidate({ args, response })
            : invalidate

        for (const queryKey of toInvalidate) {
          queryClient.invalidateQueries({ queryKey })
        }

        if (detailQueryOptions) {
          queryClient.setQueryData(
            detailQueryOptions('id' in response ? response.id : undefined)
              .queryKey,
            response
          )
        }

        return response
      },
      [queryClient]
    )
  }
}

export function makeDeleteQueryHook<
  TCreateOrUpdateFn extends (...args: any[]) => any,
>({ fn, invalidate }: { fn: TCreateOrUpdateFn; invalidate: QueryKey[] }) {
  return function useDeleteQueryHook(): TCreateOrUpdateFn {
    const queryClient = useQueryClient()

    // @ts-ignore
    return useCallback(
      async (...args: Parameters<TCreateOrUpdateFn>) => {
        const response = await fn(...args)

        for (const queryKey of invalidate) {
          queryClient.invalidateQueries({ queryKey })
        }

        return response
      },
      [queryClient]
    )
  }
}
