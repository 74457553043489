import { createSpotlight } from '@mantine/spotlight'
import { Suspense, type ReactNode } from 'react'
import { Outlet, ScrollRestoration, type To } from 'react-router-dom'

import {
  type SearchObjects,
  type SearchResponseSchemaElementsItem,
} from '_autogenerated'
import { useLogout } from 'apis/auth'
import { useGlobalSearchSelectProps } from 'apis/search'
import RequireAuth from 'components/Auth/RequireAuth'
import {
  KuiAppShell,
  type KuiAppShellProps,
  type KuiNavItem,
} from 'components/kui'
import { KyusuLoaderFull } from 'components/Kyusu/KyusuLoader/KyusuLoader'
import {
  assetUrls,
  categoryUrls,
  customerUrls,
  dispatchUrls,
  klassUrls,
  reportingUrls,
  settingsUrls,
} from 'routes/urls'
import { isoToLocaleStringDateOnly } from 'utils/datetime'
import { renderEnumValue } from 'utils/enums'
import { isDemo, isProduction } from 'utils/helpers'
import { renderMoney } from 'utils/number'
import { getOrderType } from 'utils/order'

import { useAuthContext } from './Auth/AuthContext'
import { KuiSpotlight } from './kui/KuiSpotlight'

const primaryNavItems: KuiNavItem[] = [
  {
    label: 'Orders',
    iconType: 'receipt',
    to: '/orders',
  },
  {
    label: 'Billing',
    iconType: 'file-invoice',
    children: [
      { label: 'Invoices', to: '/billing/invoices' },
      { label: 'Payments', to: '/billing/payments' },
      { label: 'Deposits', to: '/billing/deposits' },
    ],
  },
  {
    label: 'Inventory',
    iconType: 'forklift',
    children: [
      { label: 'Categories', to: categoryUrls.buildUrl() },
      { label: 'Classes', to: klassUrls.buildUrl() },
      { label: 'Assets', to: assetUrls.buildUrl() },
    ],
  },
  ...(!isProduction
    ? [
        {
          label: 'Dispatch',
          iconType: 'truck-delivery' as const,
          to: dispatchUrls.buildUrl(),
        },
      ]
    : []),
  {
    label: 'Customers',
    iconType: 'address-book',
    to: customerUrls.buildUrl(),
  },
  {
    label: 'Vendors',
    iconType: 'shopping-bag',
    to: '/vendors',
  },
  {
    label: 'Users',
    iconType: 'users',
    to: '/users',
  },
  {
    label: 'Accounting',
    iconType: 'report-money',
    to: '/accounting',
  },
  {
    label: 'Reporting',
    iconType: 'graph',
    to: reportingUrls.buildUrl(),
  },
] as const

const footerNavItems: KuiNavItem[] = [
  {
    label: 'Settings',
    iconType: 'settings',
    children: [
      { label: 'Business', to: settingsUrls.business.buildUrl() },
      {
        label: 'Configurations',
        to: settingsUrls.configurations.buildUrl(),
      },
      { label: 'Pricing', to: settingsUrls.pricing.buildUrl() },
      {
        label: 'Communications',
        to: settingsUrls.communications.buildUrl(),
      },
      {
        label: 'Integrations',
        to: settingsUrls.integrations.buildUrl(),
      },
    ],
  },
] as const

export function DashboardRoot() {
  const auth = useAuthContext()
  const logout = useLogout()

  return (
    <RequireAuth>
      <ScrollRestoration />

      <CommandKSpotlight />

      <KuiAppShell
        callout={
          !isProduction && !isDemo
            ? `This is a development environment (${window.location.hostname})`
            : undefined
        }
        navbarProps={{
          items: primaryNavItems,
          footerItems: footerNavItems,
          footerMenu: getNavFooterMenuItems(),
          onSearchClick: commandKSpotlight.open,
        }}
      >
        <Suspense fallback={<KyusuLoaderFull />}>
          <Outlet />
        </Suspense>
      </KuiAppShell>
    </RequireAuth>
  )

  function getNavFooterMenuItems(): KuiAppShellProps['navbarProps']['footerMenu'] {
    return {
      iconType: 'user',
      label: auth.state.user?.first_name ?? 'Profile',
      items: [
        {
          iconType: 'settings',
          label: 'Personal settings',
          to: settingsUrls.user.buildUrl(),
        },
        {
          iconType: 'logout',
          label: 'Logout',
          onClick: logout,
        },
      ],
    }
  }
}

const [commandKSpotlightStore, commandKSpotlight] = createSpotlight()

const searchObjectTypes = [
  'order',
  'vendor',
  'customer',
  'klass',
  'category',
] as const satisfies SearchObjects[]

function CommandKSpotlight() {
  const selectProps = useGlobalSearchSelectProps(
    {
      objects: searchObjectTypes,
    },
    { allowEmptySearch: false }
  )

  return (
    <KuiSpotlight
      {...selectProps}
      store={commandKSpotlightStore}
      parseItem={(item) => ({
        key: `${item.type}-${item.id}`,
        ...parseGlobalSearchElementItem(item),
      })}
    />
  )
}

function parseGlobalSearchElementItem(item: SearchResponseSchemaElementsItem): {
  label: string
  description: { bits: ReactNode[] }
  to: To
} {
  if (item.type === 'order') {
    return {
      label: `${renderEnumValue(getOrderType(item.obj))} #${item.obj.autogenerated_identifier}`,
      description: {
        bits: [
          renderEnumValue(getOrderType(item.obj)),
          item.obj.customer?.name,
          renderMoney(item.obj.amount),
          item.obj.contract_ends_at
            ? `${isoToLocaleStringDateOnly(item.obj.contract_starts_at)} - ${isoToLocaleStringDateOnly(item.obj.contract_ends_at)}`
            : isoToLocaleStringDateOnly(item.obj.contract_starts_at),
        ],
      },
      to: `/orders/${item.obj.id}`,
    }
  }

  if (item.type === 'vendor') {
    return {
      label: item.obj.name,
      description: { bits: [renderEnumValue(item.type)] },
      to: `/vendors/${item.obj.id}`,
    }
  }

  if (item.type === 'customer') {
    return {
      label: item.obj.name,
      description: { bits: [renderEnumValue(item.type)] },
      to: customerUrls.detail.buildUrl({ id: item.obj.id }),
    }
  }

  if (item.type === 'klass') {
    return {
      label: item.obj.name,
      description: {
        bits: [
          renderEnumValue(item.type),
          renderEnumValue(item.obj.type),
          item.obj.category.name,
          item.obj.identifier,
        ],
      },
      to: klassUrls.detail.buildUrl({ id: item.obj.id }),
    }
  }

  if (item.type === 'category') {
    return {
      label: item.obj.name,
      description: {
        bits: [
          renderEnumValue(item.type),
          renderEnumValue(item.obj.type),
          item.obj.identifier,
        ],
      },
      to: categoryUrls.detail.buildUrl({ id: item.obj.id }),
    }
  }

  assertTypeNotUsed(item.type)
}

function assertTypeNotUsed(
  type: Exclude<SearchObjects, (typeof searchObjectTypes)[number]>
): never {
  throw new Error(`Unexpected object: ${type}`)
}
