import {
  useController,
  useFormContext,
  type ControllerRenderProps,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type UseControllerProps,
} from 'react-hook-form'

import {
  KuiNumberInput,
  type KuiNumberInputProps,
} from 'components/kui/inputs/KuiNumberInput'
import { useIsReadonlyKyusuForm } from 'hooks/useKyusuFormContext'
import { type RequireGeneric } from 'utils/types'

import { getKuiFormFieldError, getMaybeReadonlyFormFieldProps } from './utils'
import { kuiRequiredValidator } from './validators'

type KuiFormNumberInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TValue extends string | number,
> = Pick<
  UseControllerProps<RequireGeneric<TFieldValues>>,
  'name' | 'rules' | 'disabled'
> &
  Omit<
    KuiNumberInputProps,
    keyof ControllerRenderProps<TFieldValues, TName>
  > & {
    required?: boolean
    type: TValue extends number
      ? 'int' | 'year'
      : Exclude<KuiNumberInputProps['type'], 'int' | 'year'>

    onChange?: (nextValue: TValue | null) => void
  }

export function KuiFormNumberInput<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = never,
  TValue extends FieldPathValue<TFieldValues, TName> = NonNullable<
    FieldPathValue<TFieldValues, TName>
  >,
>({
  name,
  rules,
  required = false,
  onChange: consumerOnChange,
  ...restProps
}: KuiFormNumberInputProps<TFieldValues, TName, TValue>) {
  const readOnly = useIsReadonlyKyusuForm()

  const formContext = useFormContext<TFieldValues>()

  const {
    field: { ref: inputRef, value, onChange, ...field },
    fieldState,
  } = useController<TFieldValues>({
    name,
    rules: { ...rules, required: kuiRequiredValidator(required) },
  })

  return (
    <KuiNumberInput
      inputRef={inputRef}
      {...field}
      {...getMaybeReadonlyFormFieldProps({
        readOnly,
        ...restProps,
        withAsterisk: required,
      })}
      {...restProps}
      value={value}
      error={getKuiFormFieldError({ fieldState })}
      onChange={(nextValue) => {
        formContext.clearErrors(name)

        const castedValue =
          restProps.type === 'int' || restProps.type === 'year'
            ? nextValue
            : castNumberToString(nextValue)

        onChange(castedValue)

        consumerOnChange?.(castedValue as any)
      }}
    />
  )

  function castNumberToString(value: number | null): string | null {
    return Number.isNaN(value) || value === null ? null : String(value)
  }
}
