import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  type JournalEntryHydratedDumpSchema,
  type JournalEntryLineItemDumpSchema,
  JournalEntrySyncStatus,
  LedgerEntryType,
  type LinkIDSchema,
  useGetAccountingAccessSuspense,
  useGetJournalEntrySuspense,
} from '_autogenerated'
import { usePostJournalEntry } from 'apis/accounting'
import {
  KuiDataList,
  KuiFlex,
  KuiLink,
  KuiPageHeader,
  KuiStack,
  KuiTable,
  KuiTableCellLink,
  type KuiTableColumn,
  KuiText,
  useKuiTablePagination,
} from 'components/kui'
import KyusuCard from 'components/Kyusu/KyusuCard/KyusuCard'
import { accountingUrls } from 'routes/urls'
import { useRouteParams } from 'routing'
import { isoToLocaleStringDateOnly } from 'utils/datetime'
import { renderEnumValue } from 'utils/enums'
import { renderMoney } from 'utils/number'

export default function JournalEntryDetailPage() {
  const { id } = useRouteParams(accountingUrls.detail)

  const { data: access } = useGetAccountingAccessSuspense()

  const { data: journalEntry } = useGetJournalEntrySuspense(id)

  const postJournalEntry = usePostJournalEntry()

  const canEdit =
    journalEntry.status === JournalEntrySyncStatus.pending ||
    journalEntry.status === JournalEntrySyncStatus.failed

  return (
    <>
      <KuiPageHeader
        title='Journal entry'
        backButton={{
          label: 'Journal entries',
          to: accountingUrls.buildUrl(),
        }}
        primaryAction={
          journalEntry.status === JournalEntrySyncStatus.succeeded
            ? {
                label: `View in ${renderEnumValue(access?.access?.provider)}`,
                onClick: () => {
                  if (journalEntry.erp_link !== null) {
                    window.open(journalEntry.erp_link, '_blank')
                  }
                },
              }
            : {
                label: 'Post',
                disabled: !canEdit,
                tooltipContent: canEdit ? undefined : 'Entry posting…',
                onClick: () => {
                  postJournalEntry({ ids_to_sync: [id] })
                },
              }
        }
        secondaryActions={
          journalEntry.status !== JournalEntrySyncStatus.succeeded
            ? [
                {
                  label: 'Edit',
                  disabled: !canEdit,
                  tooltipContent: canEdit ? undefined : 'Entry posting…',
                  to: accountingUrls.edit.buildUrl({ id }),
                },
              ]
            : []
        }
      />
      <KuiStack gapSize='md'>
        <DetailsCard entry={journalEntry} />
        <JournalEntryListCard entry={journalEntry} />

        <RelatedObjectsCard entry={journalEntry} />
      </KuiStack>
    </>
  )
}

function JournalEntryListCard({
  entry,
}: {
  entry: JournalEntryHydratedDumpSchema
}) {
  const { rows, paginationProps } = useKuiTablePagination({
    rows: entry.line_items,
    pageSize: 10,
  })

  const journalEntryColumns: KuiTableColumn<JournalEntryLineItemDumpSchema>[] =
    [
      {
        label: 'Account',
        width: 'l',
        renderCell: (entry) => entry.remote_account.name,
      },
      {
        label: 'Credit',
        width: 's',
        grow: false,
        numeric: true,
        align: 'right',
        renderCell: (entry) =>
          entry.line_item_type === LedgerEntryType.credit
            ? renderMoney(entry.amount)
            : null,
      },
      {
        label: 'Debit',
        width: 's',
        grow: false,
        numeric: true,
        align: 'right',
        renderCell: (entry) =>
          entry.line_item_type === LedgerEntryType.debit
            ? renderMoney(entry.amount)
            : null,
      },
      {
        label: 'Customer',
        interactive: true,
        renderCell: (entry) => entry.customer?.name,
      },
      {
        label: 'Vendor',
        interactive: true,
        renderCell: (entry) => entry.vendor?.name,
      },
      {
        label: 'Location',
        renderCell: (entry) => entry.location?.name,
      },
      {
        label: 'Description',
        width: 'l',
        renderCell: (entry) => entry.description,
      },
    ]

  return (
    <KyusuCard title='Journal entries'>
      <KuiTable
        gutterSize='md'
        rows={rows}
        pagination={paginationProps}
        variant='surface'
        columns={journalEntryColumns}
        getRowKey={(lineItem) => lineItem.id}
      />
    </KyusuCard>
  )
}

function DetailsCard({ entry }: { entry: JournalEntryHydratedDumpSchema }) {
  return (
    <KyusuCard title='Details'>
      <KuiDataList
        orientation='vertical'
        items={[
          {
            label: 'Status',
            value: (
              <Fragment>
                {renderEnumValue(entry.status)}
                {entry.status === JournalEntrySyncStatus.failed && (
                  <KuiFlex direction='column' gapSize='xs'>
                    <KuiText.div color='destructive'>
                      {entry.sync_error}
                    </KuiText.div>
                  </KuiFlex>
                )}
              </Fragment>
            ),
          },
          {
            label: 'Date',
            value: isoToLocaleStringDateOnly(entry.date),
          },
          {
            label: 'Memo',
            value: (
              <KuiFlex direction='column' gapSize='md'>
                <div>{entry.description ?? 'Memo notes here'}</div>
                <div>
                  Imported from Moab:{' '}
                  <KuiLink
                    to={accountingUrls.detail.buildUrl({ id: entry.id })}
                  >
                    {`${
                      window.location.origin
                    }${accountingUrls.detail.buildUrl({ id: entry.id })}`}
                  </KuiLink>
                </div>
              </KuiFlex>
            ),
          },
        ]}
      />
    </KyusuCard>
  )
}

type RelatedObject = LinkIDSchema & { type: string }

function RelatedObjectsCard({
  entry,
}: {
  entry: JournalEntryHydratedDumpSchema
}) {
  const navigate = useNavigate()

  const relatedObjects: RelatedObject[] = [
    ...(entry.payments?.map((payment) => ({ type: 'Payment', ...payment })) ??
      []),
    ...(entry.invoices?.map((invoice) => ({ type: 'Invoice', ...invoice })) ??
      []),
    ...(entry.deposits?.map((deposit) => ({ type: 'Deposit', ...deposit })) ??
      []),
  ]

  const { rows, paginationProps } = useKuiTablePagination({
    rows: relatedObjects,
    pageSize: 10,
  })

  const relatedObjectColumns: KuiTableColumn<RelatedObject>[] = [
    {
      label: 'Type',
      renderCell: (object) => object.type,
    },
    {
      label: 'Identifier',
      renderCell: (object) => object.identifier,
    },
    {
      label: 'Link',
      renderCell: (object) => (
        <KuiTableCellLink
          to={`/billing/${object.type.toLowerCase()}s/${object.id}`}
        >
          View {object.type}
        </KuiTableCellLink>
      ),
    },
  ]

  const handleRowClick = (object: RelatedObject) => {
    navigate(`/billing/${object.type.toLowerCase()}s/${object.id}`)
  }

  return (
    <KyusuCard title='Related Objects'>
      <KuiTable
        gutterSize='md'
        rows={rows}
        pagination={paginationProps}
        variant='surface'
        columns={relatedObjectColumns}
        getRowKey={(object) => `${object.type}-${object.id}`}
        onRowClick={handleRowClick}
      />
    </KyusuCard>
  )
}
