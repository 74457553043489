/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Moab Core API
 * OpenAPI spec version: 0.0.1
 */
import {
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query'
import type {
  AddressCreateSchema,
  AddressDumpSchema,
  CustomerCreateSchema,
  CustomerGetAddressesParams,
  CustomerHydratedDumpSchema,
  CustomerListParams,
  CustomerUpdateSchema,
  CustomerWithContactListParams,
  DeliveryAddressCreateSchema,
  DeliveryAddressDumpSchema,
  PaginatedCustomerSchema,
  PaginatedCustomerWithContactSchema,
  PaginatedDeliveryAddressesSchema,
  SuccessResponse
} from '../moabCoreAPI.schemas'
import { kyusuAxiosMutator } from '../../../orval/mutator/axios-mutator';
import type { ErrorType, BodyType } from '../../../orval/mutator/axios-mutator';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const customerList = (
    params?: CustomerListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedCustomerSchema>(
      {url: `/customer/customers`, method: 'GET',
        params, signal
    },
      options);
    }


export const getCustomerListQueryKey = (params?: CustomerListParams,) => {
    return [`/customer/customers`, ...(params ? [params]: [])] as const;
    }


export const getCustomerListQueryOptions = <TData = Awaited<ReturnType<typeof customerList>>, TError = ErrorType<unknown>>(params?: CustomerListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomerListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof customerList>>> = ({ signal }) => customerList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof customerList>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomerListQueryResult = NonNullable<Awaited<ReturnType<typeof customerList>>>
export type CustomerListQueryError = ErrorType<unknown>

export const useCustomerList = <TData = Awaited<ReturnType<typeof customerList>>, TError = ErrorType<unknown>>(
 params?: CustomerListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomerListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCustomerListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof customerList>>, TError = ErrorType<unknown>>(params?: CustomerListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomerListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof customerList>>> = ({ signal }) => customerList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerList>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomerListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof customerList>>>
export type CustomerListSuspenseQueryError = ErrorType<unknown>

export const useCustomerListSuspense = <TData = Awaited<ReturnType<typeof customerList>>, TError = ErrorType<unknown>>(
 params?: CustomerListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomerListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const customerCreate = (
    customerCreateSchema: BodyType<CustomerCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CustomerHydratedDumpSchema>(
      {url: `/customer/customers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: customerCreateSchema
    },
      options);
    }


export const customerWithContactList = (
    params?: CustomerWithContactListParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedCustomerWithContactSchema>(
      {url: `/customer/customers/contact-search`, method: 'GET',
        params, signal
    },
      options);
    }


export const getCustomerWithContactListQueryKey = (params?: CustomerWithContactListParams,) => {
    return [`/customer/customers/contact-search`, ...(params ? [params]: [])] as const;
    }


export const getCustomerWithContactListQueryOptions = <TData = Awaited<ReturnType<typeof customerWithContactList>>, TError = ErrorType<unknown>>(params?: CustomerWithContactListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerWithContactList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomerWithContactListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof customerWithContactList>>> = ({ signal }) => customerWithContactList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof customerWithContactList>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomerWithContactListQueryResult = NonNullable<Awaited<ReturnType<typeof customerWithContactList>>>
export type CustomerWithContactListQueryError = ErrorType<unknown>

export const useCustomerWithContactList = <TData = Awaited<ReturnType<typeof customerWithContactList>>, TError = ErrorType<unknown>>(
 params?: CustomerWithContactListParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerWithContactList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomerWithContactListQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCustomerWithContactListSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof customerWithContactList>>, TError = ErrorType<unknown>>(params?: CustomerWithContactListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerWithContactList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomerWithContactListQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof customerWithContactList>>> = ({ signal }) => customerWithContactList(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerWithContactList>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomerWithContactListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof customerWithContactList>>>
export type CustomerWithContactListSuspenseQueryError = ErrorType<unknown>

export const useCustomerWithContactListSuspense = <TData = Awaited<ReturnType<typeof customerWithContactList>>, TError = ErrorType<unknown>>(
 params?: CustomerWithContactListParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerWithContactList>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomerWithContactListSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const customerGet = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<CustomerHydratedDumpSchema>(
      {url: `/customer/customers/${id}`, method: 'GET', signal
    },
      options);
    }


export const getCustomerGetQueryKey = (id: string,) => {
    return [`/customer/customers/${id}`] as const;
    }


export const getCustomerGetQueryOptions = <TData = Awaited<ReturnType<typeof customerGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomerGetQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof customerGet>>> = ({ signal }) => customerGet(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof customerGet>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomerGetQueryResult = NonNullable<Awaited<ReturnType<typeof customerGet>>>
export type CustomerGetQueryError = ErrorType<unknown>

export const useCustomerGet = <TData = Awaited<ReturnType<typeof customerGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomerGetQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCustomerGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof customerGet>>, TError = ErrorType<unknown>>(id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomerGetQueryKey(id);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof customerGet>>> = ({ signal }) => customerGet(id, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerGet>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomerGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof customerGet>>>
export type CustomerGetSuspenseQueryError = ErrorType<unknown>

export const useCustomerGetSuspense = <TData = Awaited<ReturnType<typeof customerGet>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomerGetSuspenseQueryOptions(id,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const updateCustomer = (
    id: string,
    customerUpdateSchema: BodyType<CustomerUpdateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CustomerHydratedDumpSchema>(
      {url: `/customer/customers/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: customerUpdateSchema
    },
      options);
    }


export const customerDelete = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<SuccessResponse>(
      {url: `/customer/customers/${id}`, method: 'DELETE'
    },
      options);
    }


export const customerGetAddresses = (
    params: CustomerGetAddressesParams,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<PaginatedDeliveryAddressesSchema>(
      {url: `/customer/customers/delivery-addresses`, method: 'GET',
        params, signal
    },
      options);
    }


export const getCustomerGetAddressesQueryKey = (params: CustomerGetAddressesParams,) => {
    return [`/customer/customers/delivery-addresses`, ...(params ? [params]: [])] as const;
    }


export const getCustomerGetAddressesQueryOptions = <TData = Awaited<ReturnType<typeof customerGetAddresses>>, TError = ErrorType<unknown>>(params: CustomerGetAddressesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerGetAddresses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomerGetAddressesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof customerGetAddresses>>> = ({ signal }) => customerGetAddresses(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof customerGetAddresses>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomerGetAddressesQueryResult = NonNullable<Awaited<ReturnType<typeof customerGetAddresses>>>
export type CustomerGetAddressesQueryError = ErrorType<unknown>

export const useCustomerGetAddresses = <TData = Awaited<ReturnType<typeof customerGetAddresses>>, TError = ErrorType<unknown>>(
 params: CustomerGetAddressesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerGetAddresses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomerGetAddressesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCustomerGetAddressesSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof customerGetAddresses>>, TError = ErrorType<unknown>>(params: CustomerGetAddressesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerGetAddresses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomerGetAddressesQueryKey(params);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof customerGetAddresses>>> = ({ signal }) => customerGetAddresses(params, requestOptions, signal);





   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerGetAddresses>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomerGetAddressesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof customerGetAddresses>>>
export type CustomerGetAddressesSuspenseQueryError = ErrorType<unknown>

export const useCustomerGetAddressesSuspense = <TData = Awaited<ReturnType<typeof customerGetAddresses>>, TError = ErrorType<unknown>>(
 params: CustomerGetAddressesParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerGetAddresses>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomerGetAddressesSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const customerCreateAddress = (
    id: string,
    deliveryAddressCreateSchema: BodyType<DeliveryAddressCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DeliveryAddressDumpSchema>(
      {url: `/customer/customers/${id}/delivery-addresses`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: deliveryAddressCreateSchema
    },
      options);
    }


export const customerUpdateAddress = (
    id: string,
    deliveryAddressId: string,
    deliveryAddressCreateSchema: BodyType<DeliveryAddressCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<DeliveryAddressDumpSchema>(
      {url: `/customer/customers/${id}/delivery-addresses/${deliveryAddressId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: deliveryAddressCreateSchema
    },
      options);
    }


export const customerGetAddress = (
    deliveryAddressId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<DeliveryAddressDumpSchema>(
      {url: `/customer/customersdelivery-addresses/${deliveryAddressId}`, method: 'GET', signal
    },
      options);
    }


export const getCustomerGetAddressQueryKey = (deliveryAddressId: string,) => {
    return [`/customer/customersdelivery-addresses/${deliveryAddressId}`] as const;
    }


export const getCustomerGetAddressQueryOptions = <TData = Awaited<ReturnType<typeof customerGetAddress>>, TError = ErrorType<unknown>>(deliveryAddressId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerGetAddress>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomerGetAddressQueryKey(deliveryAddressId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof customerGetAddress>>> = ({ signal }) => customerGetAddress(deliveryAddressId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(deliveryAddressId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof customerGetAddress>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomerGetAddressQueryResult = NonNullable<Awaited<ReturnType<typeof customerGetAddress>>>
export type CustomerGetAddressQueryError = ErrorType<unknown>

export const useCustomerGetAddress = <TData = Awaited<ReturnType<typeof customerGetAddress>>, TError = ErrorType<unknown>>(
 deliveryAddressId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof customerGetAddress>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomerGetAddressQueryOptions(deliveryAddressId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getCustomerGetAddressSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof customerGetAddress>>, TError = ErrorType<unknown>>(deliveryAddressId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerGetAddress>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCustomerGetAddressQueryKey(deliveryAddressId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof customerGetAddress>>> = ({ signal }) => customerGetAddress(deliveryAddressId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(deliveryAddressId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerGetAddress>>, TError, TData> & { queryKey: QueryKey }
}

export type CustomerGetAddressSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof customerGetAddress>>>
export type CustomerGetAddressSuspenseQueryError = ErrorType<unknown>

export const useCustomerGetAddressSuspense = <TData = Awaited<ReturnType<typeof customerGetAddress>>, TError = ErrorType<unknown>>(
 deliveryAddressId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof customerGetAddress>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCustomerGetAddressSuspenseQueryOptions(deliveryAddressId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const addressGet = (
    addressId: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,signal?: AbortSignal
) => {


      return kyusuAxiosMutator<AddressDumpSchema>(
      {url: `/customer/addresses/${addressId}`, method: 'GET', signal
    },
      options);
    }


export const getAddressGetQueryKey = (addressId: string,) => {
    return [`/customer/addresses/${addressId}`] as const;
    }


export const getAddressGetQueryOptions = <TData = Awaited<ReturnType<typeof addressGet>>, TError = ErrorType<unknown>>(addressId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof addressGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAddressGetQueryKey(addressId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof addressGet>>> = ({ signal }) => addressGet(addressId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(addressId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof addressGet>>, TError, TData> & { queryKey: QueryKey }
}

export type AddressGetQueryResult = NonNullable<Awaited<ReturnType<typeof addressGet>>>
export type AddressGetQueryError = ErrorType<unknown>

export const useAddressGet = <TData = Awaited<ReturnType<typeof addressGet>>, TError = ErrorType<unknown>>(
 addressId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof addressGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAddressGetQueryOptions(addressId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getAddressGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof addressGet>>, TError = ErrorType<unknown>>(addressId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof addressGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAddressGetQueryKey(addressId);



    const queryFn: QueryFunction<Awaited<ReturnType<typeof addressGet>>> = ({ signal }) => addressGet(addressId, requestOptions, signal);





   return  { queryKey, queryFn, enabled: !!(addressId), ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof addressGet>>, TError, TData> & { queryKey: QueryKey }
}

export type AddressGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof addressGet>>>
export type AddressGetSuspenseQueryError = ErrorType<unknown>

export const useAddressGetSuspense = <TData = Awaited<ReturnType<typeof addressGet>>, TError = ErrorType<unknown>>(
 addressId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof addressGet>>, TError, TData>>, request?: SecondParameter<typeof kyusuAxiosMutator>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getAddressGetSuspenseQueryOptions(addressId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const addressCreate = (
    addressCreateSchema: BodyType<AddressCreateSchema>,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<AddressDumpSchema>(
      {url: `/customer/addresses`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addressCreateSchema
    },
      options);
    }


export const customerRefresh = (
    id: string,
 options?: SecondParameter<typeof kyusuAxiosMutator>,) => {


      return kyusuAxiosMutator<CustomerHydratedDumpSchema>(
      {url: `/customer/${id}/refresh`, method: 'POST'
    },
      options);
    }


