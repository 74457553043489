import { useNavigate } from 'react-router-dom'

import {
  type JournalEntryUpdateSchema,
  useGetJournalEntrySuspense,
} from '_autogenerated'
import { useUpdateJournalEntry } from 'apis/accounting'
import { accountingUrls } from 'routes/urls'
import { useRouteParams } from 'routing'
import { assertNonNullKeysList } from 'utils/types'

import { JournalEntryForm } from './JournalEntryForm'
import { type JournalEntryFormValues } from './types'

export default function JournalEntryEditPage() {
  const { id } = useRouteParams(accountingUrls.edit)
  const { data: journalEntry } = useGetJournalEntrySuspense(id)
  const updateJournalEntry = useUpdateJournalEntry()
  const navigate = useNavigate()

  const defaultValues = {
    date: new Date(journalEntry.date),
    description: journalEntry.description ?? null,
    line_items:
      journalEntry?.line_items.map((lineItem) => ({
        id: lineItem.id,
        remote_account: lineItem.remote_account,
        customer: lineItem.customer ?? null,
        vendor: lineItem.vendor ?? null,
        location: lineItem.location ?? null,
        amount: lineItem.amount,
        description: lineItem.description ?? null,
        line_item_type: lineItem.line_item_type,
      })) ?? [],
  }

  return <JournalEntryForm defaultValues={defaultValues} onSubmit={onSubmit} />

  async function onSubmit(values: JournalEntryFormValues) {
    const updatedJournalEntry = await updateJournalEntry(
      id!,
      mapPayloadToRequestSchema(values)
    )
    navigate(accountingUrls.detail.buildUrl({ id: updatedJournalEntry.id }))
  }

  function mapPayloadToRequestSchema(
    values: JournalEntryFormValues
  ): JournalEntryUpdateSchema {
    assertNonNullKeysList(values.line_items, ['remote_account'])

    return {
      date: values.date.toISOString(),
      description: values.description,
      line_items: values.line_items.map((lineItem) => ({
        id: lineItem.id,
        remote_account_id: lineItem.remote_account.id,
        remote_object_ids: [
          lineItem.customer?.id,
          lineItem.vendor?.id,
          lineItem.location?.id,
        ].filter((id): id is string => id !== null && id !== undefined),
        amount: lineItem.amount,
        description: lineItem.description,
        line_item_type: lineItem.line_item_type,
      })),
    }
  }
}
