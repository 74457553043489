import { Fragment } from 'react'
import { useNavigate, useOutlet } from 'react-router-dom'

import { type JournalEntryBaseDumpSchema } from '_autogenerated'
import { usePaginatedJournalEntries } from 'apis/accounting'
import { KuiPageHeader, KuiTable, type KuiTableColumn } from 'components/kui'
import { isoToLocaleStringDateOnly } from 'utils/datetime'
import { renderEnumValue } from 'utils/enums'
import { renderMoney } from 'utils/number'

const journalEntryTableColumns: KuiTableColumn<JournalEntryBaseDumpSchema>[] = [
  {
    label: 'Transaction date',
    renderCell: (entry) => isoToLocaleStringDateOnly(entry.date),
  },
  {
    label: 'Status',
    renderCell: (entry) => renderEnumValue(entry.status),
  },
  {
    label: 'Total amount',
    renderCell: (entry) => renderMoney(entry.total_amount),
  },
  {
    label: 'Description',
    renderCell: (entry) => renderEnumValue(entry.description),
  },
]

export default function JournalEntryListPage() {
  const outlet = useOutlet()
  const navigate = useNavigate()

  const paginatedJournalEntries = usePaginatedJournalEntries({})

  if (outlet) {
    return outlet
  }

  return (
    <Fragment>
      <KuiPageHeader
        title='Journal entries'
        primaryAction={{
          position: 'bottom-end',
          label: 'Post all',
        }}
        menuItems={[
          {
            label: 'Export',
          },
        ]}
      />

      <KuiTable
        {...paginatedJournalEntries.tableProps}
        rowHeight='s'
        columns={journalEntryTableColumns}
        getRowKey={(entry) => entry.id}
        onRowClick={(entry) => navigate(`/accounting/${entry.id}`)}
      />
    </Fragment>
  )
}
