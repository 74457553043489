import { getGetJournalEntryQueryOptions } from '_autogenerated'
import JournalEntryEditPage from 'routes/Accounting/JournalEntryEditPage'
import { accountingUrls } from 'routes/urls'
import { createRoute } from 'routing'

import JournalEntryDetailPage from './JournalEntryDetailPage'
import JournalEntryListPage from './JournalEntryListPage'

export const accountingRoutes = [
  createRoute({
    route: accountingUrls,
    Component: JournalEntryListPage,
  }),
  createRoute({
    route: accountingUrls.detail,
    Component: JournalEntryDetailPage,
    prefetch: ({ id }) => [getGetJournalEntryQueryOptions(id)],
  }),
  createRoute({
    route: accountingUrls.edit,
    Component: JournalEntryEditPage,
    prefetch: ({ id }) => [getGetJournalEntryQueryOptions(id)],
  }),
]
