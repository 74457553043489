import {
  useController,
  useFormContext,
  type ControllerRenderProps,
  type FieldPath,
  type FieldValues,
  type UseControllerProps,
} from 'react-hook-form'

import { KuiTextInput } from 'components/kui/inputs'
import { type KuiTextInputProps } from 'components/kui/inputs/KuiTextInput'
import { useIsReadonlyKyusuForm } from 'hooks/useKyusuFormContext'
import { type RequireGeneric } from 'utils/types'

import { getKuiFormFieldError, getMaybeReadonlyFormFieldProps } from './utils'
import { kuiRequiredValidator } from './validators'

type KuiFormTextInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = Pick<
  UseControllerProps<RequireGeneric<TFieldValues>, TName>,
  'name' | 'rules' | 'disabled'
> &
  Omit<KuiTextInputProps, keyof ControllerRenderProps<TFieldValues, TName>> & {
    required?: boolean
    onChange?: (value: string) => void
  }

export function KuiFormTextInput<
  TFieldValues extends FieldValues = never,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  rules,
  required = false,
  withAsterisk,
  onChange: consumerOnChange,
  ...restProps
}: KuiFormTextInputProps<TFieldValues, TName>) {
  const readOnly = useIsReadonlyKyusuForm()
  const formContext = useFormContext<TFieldValues>()

  const {
    field: { value, onChange, ...field },
    fieldState,
  } = useController<TFieldValues>({
    name,
    // @ts-ignore
    rules: { ...rules, required: kuiRequiredValidator(required) },
  })

  return (
    <KuiTextInput
      {...field}
      {...restProps}
      {...getMaybeReadonlyFormFieldProps({
        readOnly,
        ...restProps,
        withAsterisk: withAsterisk ?? required,
      })}
      value={value ?? ''}
      error={getKuiFormFieldError({ fieldState })}
      onChange={(nextValue) => {
        formContext.clearErrors(name)

        onChange(nextValue)

        consumerOnChange?.(nextValue)
      }}
    />
  )
}
