import classnames from 'classnames'
import styled from 'styled-components'

import { kuiThemeVars, type KuiThemeSpacingSize } from './_internal/theme'

type Props = {
  /**
   * @default 'md'
   */
  size?: KuiThemeSpacingSize

  /**
   * @default 'div'
   */
  as?: 'div' | 'hr'
}

type KuiBreakRootProps = {
  $size: KuiThemeSpacingSize
}

const KuiBreakRoot = styled.div<KuiBreakRootProps>`
  height: ${(p) => kuiThemeVars.spacingSizes[p.$size]};

  &.KuiBreakRoot--asHr {
    border: none;
    width: 100%;
    min-height: 1px;
    max-height: 1px;
    background-color: var(--mantine-color-gray-3);
    margin: ${(p) => kuiThemeVars.spacingSizes[p.$size]} 0;
  }
`

export function KuiBreak({ size = 'md', as = 'div' }: Props) {
  return (
    <KuiBreakRoot
      $size={size}
      as={as}
      className={classnames({
        'KuiBreakRoot--asHr': as === 'hr',
      })}
    />
  )
}
