import { useForm, useWatch } from 'react-hook-form'

import { LedgerEntryType, RemoteObjectTypeEnum } from '_autogenerated'
import { RemoteAccountInputKui } from 'components/Inputs/RemoteAccountInput/RemoteAccountInputKui'
import { RemoteObjectInputKui } from 'components/Inputs/RemoteObjectInput/RemoteObjectInputKui'
import {
  KuiButton,
  type KuiFieldArrayTableRow,
  KuiFlex,
  KuiForm,
  KuiFormDateInput,
  KuiFormNumberInput,
  type KuiFormProps,
  KuiFormTextarea,
  KuiFormTextInput,
  KuiPageHeader,
  KuiStack,
  KuiTable,
  type KuiTableColumn,
  useKuiFormFieldArray,
} from 'components/kui'
import KyusuCard from 'components/Kyusu/KyusuCard/KyusuCard'

import { type JournalEntryFormValues } from './types'

type JournalEntryFormProps = Pick<
  KuiFormProps<JournalEntryFormValues>,
  'onSubmit'
> & {
  defaultValues: JournalEntryFormValues
}

export function JournalEntryForm({
  defaultValues,
  ...formProps
}: JournalEntryFormProps) {
  const form = useForm<JournalEntryFormValues>({
    defaultValues,
  })

  return (
    <KuiForm
      {...formProps}
      form={form}
      render={({ formProps, getKuiFormSubmitAction }) => (
        <form {...formProps}>
          <KuiPageHeader
            title='Edit journal entry'
            primaryAction={getKuiFormSubmitAction({ label: 'Save' })}
          />
          <KuiStack gapSize='md'>
            <KyusuCard title='Journal entry details'>
              <KuiFormDateInput<JournalEntryFormValues>
                name='date'
                label='Date'
                required={true}
              />
              <KuiFormTextarea<JournalEntryFormValues>
                name='description'
                label='Memo'
              />
            </KyusuCard>
            <JournalEntryLineItemCard />
          </KuiStack>
        </form>
      )}
    />
  )
}

export function JournalEntryLineItemCard() {
  const journalEntryFieldArray = useKuiFormFieldArray<
    JournalEntryFormValues,
    'line_items'
  >({
    name: 'line_items',
  })

  return (
    <KyusuCard title='Line items'>
      <KuiTable
        columns={[
          {
            label: 'Account',
            width: 'l',
            sticky: true,
            renderCell: (lineItem) => (
              <RemoteAccountInputKui<JournalEntryFormValues>
                name={`line_items.${lineItem.index}.remote_account`}
                required={true}
              />
            ),
          },
          {
            label: 'Customer',
            width: 'l',
            renderCell: (lineItem) => (
              <ConditionalRemoteObjectInput
                index={lineItem.index}
                type={RemoteObjectTypeEnum.customer}
                disableWhen='vendor'
              />
            ),
          },
          {
            label: 'Vendor',
            width: 'l',
            renderCell: (lineItem) => (
              <ConditionalRemoteObjectInput
                index={lineItem.index}
                type={RemoteObjectTypeEnum.vendor}
                disableWhen='customer'
              />
            ),
          },
          {
            label: 'Location',
            width: 'l',
            renderCell: (lineItem) => (
              <RemoteObjectInputKui<JournalEntryFormValues>
                name={`line_items.${lineItem.index}.location`}
                type={RemoteObjectTypeEnum.location}
              />
            ),
          },
          {
            label: 'Description',
            width: 'l',
            renderCell: (lineItem) => (
              <KuiFormTextInput<JournalEntryFormValues>
                name={`line_items.${lineItem.index}.description`}
              />
            ),
          },
          {
            label: 'Credit',
            sticky: true,
            renderCell: ({ defaultValue, index }) =>
              defaultValue.line_item_type === LedgerEntryType.credit ? (
                <KuiFormNumberInput<
                  JournalEntryFormValues,
                  'line_items.0.amount'
                >
                  type='money'
                  name={`line_items.${index}.amount`}
                  required={true}
                />
              ) : (
                '-'
              ),
          },
          {
            label: 'Debit',
            sticky: true,
            renderCell: ({ defaultValue, index }) =>
              defaultValue.line_item_type === LedgerEntryType.debit ? (
                <KuiFormNumberInput<
                  JournalEntryFormValues,
                  'line_items.0.amount'
                >
                  type='money'
                  name={`line_items.${index}.amount`}
                  required={true}
                />
              ) : (
                '-'
              ),
          },
          ...(journalEntryFieldArray.rows.length > 2
            ? [
                {
                  key: 'actions',
                  label: '',
                  width: 50,
                  sticky: true,
                  renderCell: ({ index }) => {
                    return (
                      <KuiButton
                        iconType='x'
                        size='xs'
                        onClick={() => journalEntryFieldArray.remove(index)}
                      />
                    )
                  },
                } satisfies KuiTableColumn<
                  KuiFieldArrayTableRow<JournalEntryFormValues, 'line_items'>
                >,
              ]
            : []),
        ]}
        rows={journalEntryFieldArray.rows}
        getRowKey={(row) => row.key}
      />
      <div>
        <KuiFlex gapSize='md'>
          <KuiButton
            variant='transparent'
            color='accent'
            iconType='plus'
            onClick={() =>
              journalEntryFieldArray.append({
                id: null,
                remote_account: null,
                description: null,
                amount: '',
                customer: null,
                vendor: null,
                location: null,
                line_item_type: LedgerEntryType.credit,
              })
            }
          >
            Add credit row
          </KuiButton>
          <KuiButton
            variant='transparent'
            color='accent'
            iconType='plus'
            onClick={() =>
              journalEntryFieldArray.append({
                id: null,
                remote_account: null,
                description: null,
                amount: '',
                customer: null,
                vendor: null,
                location: null,
                line_item_type: LedgerEntryType.debit,
              })
            }
          >
            Add debit row
          </KuiButton>
        </KuiFlex>
      </div>
    </KyusuCard>
  )
}

interface ConditionalRemoteObjectInputProps {
  index: number
  type: RemoteObjectTypeEnum
  disableWhen: 'customer' | 'vendor'
}

function ConditionalRemoteObjectInput({
  index,
  type,
  disableWhen,
}: ConditionalRemoteObjectInputProps) {
  // const { control } = useForm<JournalEntryFormValues>()
  const watchField = useWatch<JournalEntryFormValues, 'line_items'>({
    name: 'line_items',
  })

  const isDisabled = !!watchField?.[index]?.[disableWhen]

  const otherType = disableWhen === 'vendor' ? 'vendor' : 'customer'

  return (
    <RemoteObjectInputKui<JournalEntryFormValues>
      name={`line_items.${index}.${type}`}
      type={type}
      disabled={isDisabled}
      tooltipContent={
        isDisabled
          ? `Cannot select a ${type} when a ${otherType} is selected.`
          : ''
      }
    />
  )
}
