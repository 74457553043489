import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import {
  type InventoryProfileListParams,
  type JournalEntriesSyncSchema,
  createInventoryProfile,
  deleteInventoryProfile,
  depreciationSettings,
  getAccountMappingsQueryKey,
  getAccountMappingsQueryOptions,
  getDepreciationSettingsQueryKey,
  getGetInventoryProfileQueryOptions,
  getGetJournalEntryQueryKey,
  getGetJournalEntryQueryOptions,
  getInventoryProfileListQueryKey,
  getInventoryProfileListQueryOptions,
  getJournalEntryListQueryKey,
  getRemoteAccountsQueryKey,
  getRemoteObjectQueryOptions,
  getRemoteObjectsQueryKey,
  inventoryProfileList,
  journalEntryList,
  remoteAccounts,
  remoteObjectCreate,
  remoteObjects,
  syncJournalEntries,
  updateAccountMappings,
  updateInventoryProfile,
  updateJournalEntry,
  useGetAccountingAccess,
} from '_autogenerated'

import { type KuiQueryOptions } from './types'
import {
  makeCreateOrUpdateQueryHook,
  makeDeleteQueryHook,
  makePaginatedQueryHook,
  makeSearchableSelectPropsHook,
} from './utils'

export const useUpdateAccountMappings = makeCreateOrUpdateQueryHook({
  fn: updateAccountMappings,
  detailQueryOptions: getAccountMappingsQueryOptions,
  invalidate: [getAccountMappingsQueryKey()],
})

export const useCreateInventoryProfile = makeCreateOrUpdateQueryHook({
  fn: createInventoryProfile,
  detailQueryOptions: getInventoryProfileListQueryOptions,
  invalidate: [getInventoryProfileListQueryKey()],
})

export const useUpdateInventoryProfile = makeCreateOrUpdateQueryHook({
  fn: updateInventoryProfile,
  detailQueryOptions: getGetInventoryProfileQueryOptions,
  invalidate: [getInventoryProfileListQueryKey()],
})

export const useDeleteInventoryProfile = makeDeleteQueryHook({
  fn: deleteInventoryProfile,
  invalidate: [getInventoryProfileListQueryKey()],
})

export const usePaginatedInventoryProfiles = makePaginatedQueryHook({
  queryKey: getInventoryProfileListQueryKey,
  queryFn: inventoryProfileList,
})

export const useRemoteObjectCreate = makeCreateOrUpdateQueryHook({
  fn: remoteObjectCreate,
  detailQueryOptions: getRemoteObjectQueryOptions,
  invalidate: [getInventoryProfileListQueryKey()],
})

export const useRemoteObjectSelectProps = makeSearchableSelectPropsHook({
  queryKey: getRemoteObjectsQueryKey,
  queryFn: remoteObjects,
})

export const useRemoteAccountSelectProps = makeSearchableSelectPropsHook({
  queryKey: getRemoteAccountsQueryKey,
  queryFn: remoteAccounts,
})

export const useUpdateJournalEntry = makeCreateOrUpdateQueryHook({
  fn: updateJournalEntry,
  detailQueryOptions: getGetJournalEntryQueryOptions,
  invalidate: [getJournalEntryListQueryKey()],
})

export const usePaginatedJournalEntries = makePaginatedQueryHook({
  queryKey: getJournalEntryListQueryKey,
  queryFn: journalEntryList,
})

export const useDepreciationSettingSelectProps = makeSearchableSelectPropsHook({
  queryKey: getDepreciationSettingsQueryKey,
  queryFn: depreciationSettings,
})

export const usePaginatedDepreciationSettings = makePaginatedQueryHook({
  queryKey: getDepreciationSettingsQueryKey,
  queryFn: depreciationSettings,
})

const useInventoryProfileSelectPropsImpl = makeSearchableSelectPropsHook({
  queryKey: getInventoryProfileListQueryKey,
  queryFn: inventoryProfileList,
})

export function useInventoryProfileSelectProps(
  params?: InventoryProfileListParams,
  options?: KuiQueryOptions
) {
  const { data: accountingAccess } = useGetAccountingAccess()

  return useInventoryProfileSelectPropsImpl(params, {
    ...options,
    enabled: !accountingAccess?.access ? false : options?.enabled,
  })
}

export function usePostJournalEntry(): typeof syncJournalEntries {
  const queryClient = useQueryClient()

  return useCallback(
    async (params: JournalEntriesSyncSchema) => {
      const response = await syncJournalEntries(params)

      queryClient.invalidateQueries({ queryKey: getJournalEntryListQueryKey() })
      if (params.ids_to_sync) {
        params.ids_to_sync.forEach((id) => {
          queryClient.invalidateQueries({
            queryKey: getGetJournalEntryQueryKey(id),
          })
        })
      }

      return response
    },
    [queryClient]
  )
}
